import React, { ComponentType, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { V3ProjectSdk } from '@cango-app/sdk'

import { Box, ContactAndAssignment, FormCard, Text } from 'src/components'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { getActionLabel } from 'src/helpers/labels'
import { CreateContact } from 'src/modules/my-tasks-v3/components/actions/create-contact'
import { FileUpload } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-upload'
import { FileView } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-view'
import { FileTemplate } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-template'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { TaskContext } from 'src/providers/task-provider'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'
import { Software } from 'src/modules/my-tasks-v3/components/actions/software'
import { LinkView } from 'src/modules/my-tasks-v3/components/actions/link-view'
import { TaskReference } from 'src/modules/my-tasks-v3/components/actions/task-reference'
import { TemplateModal } from 'src/modules/my-tasks-v3/components/actions/template-modal'

import { SetResourcesModal } from '../../../../my-tasks-v3/components/actions/set-resources'

export const ActionFields: ComponentType = () => {
	const dispatch = useDispatch()
	const project = useSelector(projectSelectors.getSelectedProject)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { task, updateTask } = useContext(TaskContext)
	const taskRoles = useSelector(projectSelectors.getTaskRoles)
	const taskExternals = useSelector(projectSelectors.getTaskExternals)

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignUserToRole({
				userId,
				roleId,
				projectId: task.project_id,
			}),
		)
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignContactToExternal({
				projectId: task.project_id,
				assignments: [{ roleId, contactId }],
			}),
		)
	}

	const handleAddFileToTask = async (data: {
		projectId: string
		fileIds: string[]
		taskId: string
		dependencyId?: string
		actionIndex: number
	}) => {
		try {
			const response = await V3ProjectSdk.addFileIdsToTask(
				import.meta.env.VITE_API as string,
				authHeaders,
				data,
			)
			updateTask(`actions.${data.actionIndex}.file_ids`, data.fileIds, {
				localStateKey: `actions[${data.actionIndex}].file_ids`,
			})
			updateTask(`actions.${data.actionIndex}.file_history`, response.file_history ?? [], {
				localStateKey: `actions[${data.actionIndex}].file_history`,
			})
		} catch (error) {
			showSnackbar('Error adding file(s)', { variant: 'error' })
		}
	}

	const createContact = (contactId: string) => {
		if (!task) return
		updateTask('lifecycle.resource', contactId, { updateDb: true })
	}

	const renderAction = (
		actionType: V3BlueprintTypes.ActionEnum,
		action: V3ClientTypes.Project.TaskAction,
	) => {
		if (!task || !project) return null
		switch (actionType) {
			case V3BlueprintTypes.ActionEnum.FileUpload:
				return (
					<FileUpload
						project={project}
						task={task}
						onAddFileIdsToTask={handleAddFileToTask}
						action={action}
					/>
				)
			case V3BlueprintTypes.ActionEnum.FileView:
				return <FileView task={task} project={project} onAddFilesToTask={handleAddFileToTask} />
			case V3BlueprintTypes.ActionEnum.FileTemplate:
				return (
					<FileTemplate
						task={task}
						project={project}
						onAddFilesToTask={handleAddFileToTask}
						action={action}
					/>
				)
			case V3BlueprintTypes.ActionEnum.Call:
			case V3BlueprintTypes.ActionEnum.Email:
			case V3BlueprintTypes.ActionEnum.Invite:
			case V3BlueprintTypes.ActionEnum.Meeting:
				return (
					<Box>
						<ContactAndAssignment
							taskExternals={taskExternals}
							taskRoles={taskRoles}
							onAssignExternal={handleExternalAssignChange}
							onAssignRole={handleRoleAssignChange}
						/>
					</Box>
				)
			case V3BlueprintTypes.ActionEnum.Software:
				return <Software links={action.links} />
			case V3BlueprintTypes.ActionEnum.LinkView:
				return <LinkView task={task} />
			case V3BlueprintTypes.ActionEnum.Video: {
				return <VideoEmbed urls={action.links} />
			}
			case V3BlueprintTypes.ActionEnum.TaskReference:
				return <TaskReference taskReferences={action.task_references} project={project} />
			case V3BlueprintTypes.ActionEnum.Note:
				return (
					<Box sx={{ overflowWrap: 'break-word' }}>
						<Text fontSize={14}>{action.note}</Text>
					</Box>
				)
			case V3BlueprintTypes.ActionEnum.Contact:
				return <CreateContact task={task} useDrawer onCreateContact={createContact} />
			case V3BlueprintTypes.ActionEnum.SetResources:
				return <SetResourcesModal action={action} />
			case V3BlueprintTypes.ActionEnum.Template:
				return <TemplateModal action={action} />
		}
	}

	if (!task || !task.actions.length) {
		return null
	}

	return (
		<>
			{task.actions.map((_action) => {
				if (_action.type === V3BlueprintTypes.ActionEnum.None) {
					return null
				}
				return (
					<FormCard
						key={_action._id}
						title={`Action - ${getActionLabel(_action.type)}`}
						mt={2}
						mb={2}
					>
						{renderAction(_action.type, _action)}
					</FormCard>
				)
			})}
		</>
	)
}

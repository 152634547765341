import { Collapse, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import dayjs from 'dayjs'
import { ComponentType, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CommsSdk } from '@cango-app/sdk'
import { PulseLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'

import { EditPenIcon } from 'src/assets/icons'
import { Box, Button, Chip, Divider, Text, UserAvatar } from 'src/components'
import { colors } from 'src/theme/colors'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as projectsSelectors } from 'src/store/modules/projects-v3'
import { getNameInitials } from 'src/routing/navigation/utils'
import { RouteId } from 'src/constants/routes'
import { MentionedText } from 'src/components/mentions/mentioned-text'

import { ComposeMessage } from './compose-message'

const InboxTaskListItem: ComponentType<CommsSdk.InboxTaskListItem> = ({
	created_by,
	timestamp,
	task,
	text,
	project,
}) => {
	const navigate = useNavigate()
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	const user = mappedUsers.get(created_by)
	const selectedTaskId = useSelector(notesSelectors.getSelectedInboxTaskId)
	const inboxNavState = useSelector(notesSelectors.getInboxNavState)
	const unreadTaskIds = useSelector(notesSelectors.getTasksWithUnreadNotes)

	const isTaskUnread = useMemo(() => {
		return !!unreadTaskIds.find(({ taskId }) => taskId === task._id)
	}, [unreadTaskIds])

	const taskChip = useMemo(() => {
		let label = 'In progress'
		let color: 'error' | 'info' | 'success' = 'info'

		if (task.lifecycle.complete) {
			label = 'Task Complete'
			color = 'success'
		}

		if (task.isFlagged) {
			label = 'Task Blocked'
			color = 'error'
		}

		return (
			<Box>
				<Chip label={label} size="small" sx={{ fontSize: 10 }} color={color} />
			</Box>
		)
	}, [isTaskUnread, task.isFlagged, task.lifecycle.complete])

	const backgroundColor = useMemo(() => {
		if (isTaskUnread)
			return {
				standard: colors.sunglow['20'],
				hover: colors.sunglow['40'],
			}
		if (task.isFlagged)
			return {
				standard: colors.error.light['20'],
				hover: 'rgba(239, 83, 80, 0.3)',
			}
		return {
			standard: 'transparent',
			hover: undefined,
		}
	}, [isTaskUnread, task.isFlagged, task.lifecycle.complete])

	const handleClick = () => {
		if (selectedTaskId === task._id) return
		navigate(`/${RouteId.Inbox}/${inboxNavState}/${task._id}`)
	}

	return (
		<ListItemButton
			selected={selectedTaskId === task._id}
			sx={{
				bgcolor: backgroundColor.standard,

				'&:hover': {
					bgcolor: backgroundColor.hover,
				},
				'&.Mui-selected': {
					borderLeft: `3px solid`,
					borderColor: colors.feldgrau['40'],
					bgcolor: backgroundColor.standard,

					'&:hover': {
						bgcolor: backgroundColor.hover,
					},
				},
			}}
			onClick={handleClick}
		>
			<ListItemAvatar sx={{ display: 'flex' }}>
				<UserAvatar
					userInitials={getNameInitials(user?.name, user?.surname)}
					picture={user?.picture}
				/>
			</ListItemAvatar>
			<ListItemText>
				<Box display="flex" flexDirection="column">
					<Box color={colors.neutral['60']} display="flex" justifyContent="space-between">
						<Box display="flex" flexDirection="column">
							<Text fontSize={12}>
								{user?.name} {user?.surname}
							</Text>
							<Text fontSize={10}>{project.name}</Text>
						</Box>
						<Text fontSize={12}>{dayjs.unix(timestamp).format('MMM D, YYYY')}</Text>
					</Box>
					<Text
						fontSize={14}
						sx={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							width: '100%',
							maxWidth: 400,
							fontWeight: isTaskUnread ? 'bold' : 'normal',
						}}
					>
						{task.name}
					</Text>
					<Text
						fontSize={14}
						sx={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							width: '100%',
							maxWidth: 400,
							color: colors.neutral['60'],
							fontSize: 12,
						}}
					>
						<MentionedText raw={text} />
					</Text>
					{taskChip}
				</Box>
			</ListItemText>
		</ListItemButton>
	)
}

export const InboxTaskList: ComponentType = () => {
	const inboxNotes = useSelector(notesSelectors.getInboxList)
	const isLoading = useSelector(notesSelectors.isLoadingInbox)
	const [isComposingMessage, setIsComposingMessage] = useState(false)
	const inboxNavState = useSelector(notesSelectors.getInboxNavState)
	const projectList = useSelector(projectsSelectors.getProjectsListForSelect)

	const viewTitle = useMemo(() => {
		if (
			![CommsSdk.InboxNavState.MyMessages, CommsSdk.InboxNavState.AllMessages].includes(
				inboxNavState as CommsSdk.InboxNavState,
			)
		) {
			return projectList.find(({ _id }) => _id === inboxNavState)?.label ?? 'Inbox'
		}

		return inboxNavState.replace('-', ' ')
	}, [inboxNavState, projectList])

	return (
		<>
			<ComposeMessage show={isComposingMessage} onClose={() => setIsComposingMessage(false)} />
			<Box flex={1} pt={1} display="flex" flexDirection="column">
				<Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
					<Box mt={2}>
						<Box>
							<Text variant="overline" fontSize={18} fontWeight="bold" lineHeight={1}>
								{viewTitle}
							</Text>
						</Box>
						<Box>
							<Text variant="overline">{`${inboxNotes.length} message${
								inboxNotes.length > 1 ? 's' : ''
							}`}</Text>
						</Box>
					</Box>
					<Button
						size="small"
						startIcon={<EditPenIcon width={16} />}
						onClick={() => setIsComposingMessage(true)}
					>
						Compose message
					</Button>
				</Box>
				<Box overflow="scroll" flex={1}>
					<List>
						{isLoading ? (
							<Box display="flex" justifyContent="center">
								<PulseLoader size={6} />
							</Box>
						) : (
							<TransitionGroup>
								{inboxNotes.map((note, index) => (
									<Collapse key={note._id}>
										<>
											<InboxTaskListItem {...note} />
											{index !== inboxNotes.length - 1 && <Divider sx={{ borderBottomWidth: 2 }} />}
										</>
									</Collapse>
								))}
							</TransitionGroup>
						)}
					</List>
				</Box>
			</Box>
		</>
	)
}

import React, { ComponentType, useState, useContext, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

import { Box, Drawer, Text } from 'src/components'

import { DeleteStep } from './delete-step'
import StepForm from './step-form'
import { StepContext, StepProvider } from './step-provider'

type StepDrawerProps = {
	stepId?: string
	onClose: () => void
	onDeleteStep: (stepId: string) => void
}

const DrawerContent: ComponentType<{
	shouldPromptDelete: boolean
	onClose: () => void
	onDeleteClick: () => void
	onDirtiedFields: (hasDirtied: boolean) => void
}> = ({ shouldPromptDelete, onClose, onDeleteClick, onDirtiedFields }) => {
	const { isLoadingStep, step } = useContext(StepContext)

	if (isLoadingStep) {
		return (
			<Box display="flex" justifyContent="center" mt={10}>
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (!step) {
		return (
			<Box mt={10}>
				<Text variant="h5" textAlign="center">
					Step not found
				</Text>
			</Box>
		)
	}

	if (shouldPromptDelete) {
		return <DeleteStep onCloseDrawer={onClose} />
	}

	return (
		<StepForm
			onDelete={onDeleteClick}
			onCloseDrawer={onClose}
			setDirtiedFields={onDirtiedFields}
			step={step}
		/>
	)
}

const StepDrawer: ComponentType<StepDrawerProps> = ({ stepId, onClose, onDeleteStep }) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const [dirtiedFields, setDirtiedFields] = useState(false)

	const manualCloseDrawer = () => {
		if (!dirtiedFields) {
			handleCloseDrawer()
			return
		}

		if (confirm('Step drawer contains unsaved changes, are you sure you wish to discard them?')) {
			handleCloseDrawer()
		}
	}

	const handleCloseDrawer = () => {
		setShouldPromptDelete(false)
		onClose()
	}

	const stepContent = () => {
		if (!stepId) {
			return null
		}

		return (
			<StepProvider stepId={stepId} onDeleteStep={onDeleteStep}>
				<DrawerContent
					shouldPromptDelete={shouldPromptDelete}
					onDeleteClick={() => setShouldPromptDelete(true)}
					onClose={handleCloseDrawer}
					onDirtiedFields={setDirtiedFields}
				/>
			</StepProvider>
		)
	}

	useEffect(() => {
		if (!stepId && shouldPromptDelete) {
			setShouldPromptDelete(false)
		}
	}, [stepId, shouldPromptDelete])

	return (
		<Drawer open={!!stepId} onClose={manualCloseDrawer}>
			<Box minWidth={550}>{stepContent()}</Box>
		</Drawer>
	)
}

export default StepDrawer

import { ComponentType } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Divider } from 'src/components'

import { ChainsNavigation } from './chains-navigation'
import { ChainProvider } from './chain-provider'
import { ChainFlow } from './chain-flow'

const Chains: ComponentType = () => {
	const { chainId } = useParams<{
		chainId?: string
	}>()

	return (
		<ChainProvider chainId={chainId}>
			<Box display="flex" flex={1} overflow="hidden">
				<ChainsNavigation selectedChainId={chainId} />
				<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
				<ChainFlow />
			</Box>
		</ChainProvider>
	)
}

export default Chains

import { FormControlLabel, Radio } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import React, { ComponentType, useContext } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'

import { ChainChip } from '../../../../components/section-tasks-v3/chains-list'
import { Box, GroupedSelect, Select, Text, TextField } from '../../../../components'
import { useListOptions } from '../../../../hooks/use-list-options'
import { ChainContext } from '../../chain-provider'

import { DependencyForm, DependencyType } from './types'

type ChainForEveryOptionSelectedProps = {
	dependencyType: DependencyType
	disabled: boolean
	source: string
}

export const ChainForEveryOptionSelected: ComponentType<ChainForEveryOptionSelectedProps> = ({
	dependencyType,
	disabled,
	source,
}) => {
	const { control, watch, setValue } = useFormContext<DependencyForm>()
	const { groupListedNodes, nodeMap } = useContext(ChainContext)
	const fromNodeId = watch('option_condition.from') ?? source
	const fromNode = nodeMap.get(fromNodeId)
	const listOptions = useListOptions(fromNode?.data.options)
	const hasDatabaseLogicSelected = !!watch('databaseLogic')
	const isAnySelected =
		watch('option_condition.operator') === V3BlueprintTypes.ChildConditionOperator.Any
	const isMenu = !!fromNode?.data.isMenu

	return (
		<Box>
			<FormControlLabel
				value={DependencyType.CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED}
				control={<Radio />}
				disabled={disabled}
				label={<Text fontSize={14}>{'and duplicate for every selection, when my selection'}</Text>}
				sx={{ fontSize: 14 }}
			/>
			{dependencyType === DependencyType.CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED && (
				<Box ml={3} maxWidth={400}>
					<Controller
						control={control}
						name="option_condition.from"
						render={({ field: { value, onChange } }) => {
							return (
								<GroupedSelect
									label="from"
									value={value ?? source}
									defaultValue={source}
									onChange={(e) => {
										onChange(e.target.value)
									}}
									options={groupListedNodes}
								/>
							)
						}}
					/>
					<Controller
						control={control}
						name="option_condition.operator"
						render={({ field: { value, onChange } }) => {
							const handleChangeValue = (newValue: V3BlueprintTypes.ChildConditionOperator) => {
								if (newValue === V3BlueprintTypes.ChildConditionOperator.Any) {
									setValue('option_condition.values', [])
								}
								onChange(newValue)
							}
							return (
								<Select
									value={value}
									onChange={(e) =>
										handleChangeValue(e.target.value as V3BlueprintTypes.ChildConditionOperator)
									}
									options={[
										{
											_id: V3BlueprintTypes.ChildConditionOperator.Is,
											label: isMenu ? 'includes' : 'is',
										},
										{
											_id: V3BlueprintTypes.ChildConditionOperator.IsNot,
											label: isMenu ? 'does not include' : 'is not',
										},
										{
											_id: V3BlueprintTypes.ChildConditionOperator.Any,
											label: 'is anything',
										},
									]}
								/>
							)
						}}
					/>
					<Controller
						control={control}
						name="option_condition.values"
						render={({ field: { value: selectedOptions, onChange: onSelectedOptionsChange } }) => (
							<>
								{!isAnySelected && (
									<Select
										options={listOptions}
										value={selectedOptions}
										multiple={isMenu}
										onChange={(event) => {
											if (!isMenu) {
												onSelectedOptionsChange([event.target.value as string])
												return
											}
											onSelectedOptionsChange(event.target.value as string[])
										}}
										containerProps={{ maxWidth: 400, mb: 2 }}
										helperText={
											!isMenu
												? 'The parent task only allows one option to be selected'
												: 'Multiple options can be selected'
										}
									/>
								)}

								{!hasDatabaseLogicSelected && (
									<Controller
										control={control}
										name="chainPrefix"
										rules={{ required: true }}
										render={({ field: { value: chainPrefix, onChange: onChainPrefixChange } }) => (
											<TextField
												label="What should the prefix for this chain be?"
												value={chainPrefix}
												disabled={hasDatabaseLogicSelected}
												onChange={(e) => onChainPrefixChange(e.target.value)}
												sx={{ mb: 2 }}
												helperText={
													<Box>
														<Text
															fontSize={12}
														>{`This will appear on all chains for clarity of which task belongs to which chain.`}</Text>
														<Box display="flex" alignItems="center">
															<Text fontSize={12} mr={1}>
																{'e.g.'}
															</Text>
															<ChainChip
																prefix={chainPrefix || '[example]'}
																selected_option={
																	selectedOptions.length
																		? listOptions.find(
																				(_option) => _option._id === selectedOptions[0],
																			)?.label
																		: 'Example option 1'
																}
																isLoadingTable={false}
																color={'#c4def6'}
															/>
														</Box>
													</Box>
												}
											/>
										)}
									/>
								)}
							</>
						)}
					/>
				</Box>
			)}
		</Box>
	)
}

import { ComponentType, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Stack } from '@mui/material'

import { Box, Button, IconButton, Modal, Text } from 'src/components'
import { BackArrowOutlined, HistoryIcon, InboxNavIcon, TagIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { useIsMobile } from 'src/hooks/useMobile'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import TaskHierarchyComponent from 'src/modules/projects-v3/project-detail/task-drawer/configure-task/task-hierarchy'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { TaskContext } from 'src/providers/task-provider'

import { TaskTitle } from './task-title'

export const TopButtonBar: ComponentType = () => {
	const dispatch = useDispatch()
	const { selectedSectionId, goToSection } = useMyTasks()
	const { task: selectedTask } = useContext(TaskContext)
	const unreadTasks = useSelector(notesSelectors.getTasksWithUnreadNotes)
	const [isHistoryOpen, setIsHistoryOpen] = useState(false)
	const [isNotesOpen, setIsNotesOpen] = useState(false)
	const isMobile = useIsMobile()

	const hasUnreadTasks = useMemo(() => {
		if (!selectedTask) return false
		return unreadTasks.find(({ taskId }) => taskId === selectedTask._id)
	}, [unreadTasks, selectedTask])

	const handleCommentsClick = () => {
		dispatch(projectActions.toggleChatModal(true))
	}

	const handleBackClick = () => {
		goToSection(selectedSectionId)
	}

	if (!selectedTask) return null

	return (
		<>
			<Modal open={isHistoryOpen} onClose={() => setIsHistoryOpen(false)}>
				<Box minHeight={400} maxHeight="90vh" minWidth={700}>
					<TaskHierarchyComponent
						_task={selectedTask}
						onResetTaskComplete={(data) => dispatch(projectActions.resetTask(data))}
					/>
				</Box>
			</Modal>
			<Modal
				open={isNotesOpen}
				onClose={() => setIsNotesOpen(false)}
				containerStyle={{
					width: 650,
					maxWidth: '100%',
					height: 500,
					maxHeight: '100%',
					flex: 1,
					display: 'flex',
				}}
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box height={350} maxHeight="70vh" flex={1} p={1}>
					<Text variant="overline">Notes</Text>
					{selectedTask.annotations.map(({ annotation, _id }) => {
						return (
							<Stack key={_id} my={1} px={2} py={2} borderRadius={4} bgcolor={colors.neutral['30']}>
								<Text>{annotation}</Text>
							</Stack>
						)
					})}
				</Box>
			</Modal>
			<Box
				display="flex"
				flex={1}
				borderBottom="1px solid"
				borderColor={colors.neutral['40']}
				py={2}
			>
				<Box display="flex" flex={1}>
					<Box display="flex" alignItems="center">
						{!isMobile && (
							<IconButton onClick={handleBackClick}>
								<BackArrowOutlined />
							</IconButton>
						)}
						<TaskTitle
							task={selectedTask}
							containerProps={{ flex: 1, ml: { laptop: 2 } }}
							fontSize={isMobile ? 14 : 16}
							hideIcon={isMobile}
						/>
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Button
						startIcon={<HistoryIcon width={20} />}
						variant="text"
						onClick={() => setIsHistoryOpen(true)}
					>
						History
					</Button>
					{isMobile ? (
						<IconButton aria-label="open notes" onClick={() => setIsNotesOpen(true)}>
							<Badge color="warning" badgeContent={selectedTask.annotations.length || 0}>
								<TagIcon />
							</Badge>
						</IconButton>
					) : (
						<Button
							disabled={selectedTask.annotations?.length === 0}
							variant="text"
							aria-labelledby="button-notes"
							onClick={() => setIsNotesOpen(true)}
							startIcon={
								<TagIcon
									stroke={
										selectedTask.annotations?.length === 0
											? colors.neutral['30']
											: colors.feldgrau['60']
									}
								/>
							}
						>
							<Badge color="warning" badgeContent={selectedTask.annotations?.length || 0}>
								<Text id="button-notes" fontSize={14} pr={1}>
									Notes
								</Text>
							</Badge>
						</Button>
					)}
					<Badge
						color="error"
						badgeContent={hasUnreadTasks || selectedTask.isFlagged ? '!' : undefined}
					>
						{isMobile ? (
							<IconButton onClick={handleCommentsClick}>
								<InboxNavIcon fill={colors.feldgrau['60']} />
							</IconButton>
						) : (
							<Button
								variant="text"
								onClick={handleCommentsClick}
								startIcon={<InboxNavIcon fill={colors.feldgrau['60']} />}
							>
								Task Inbox
							</Button>
						)}
					</Badge>
				</Box>
			</Box>
		</>
	)
}

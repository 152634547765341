/**
 * Address book view
 */

import capitalize from 'lodash/capitalize'
import AddIcon from '@mui/icons-material/Add'
import React, { useState, useMemo, ComponentType, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { ContactTypes } from '@cango-app/types'

import { Button, Chip, Box, Text } from 'src/components'
import {
	actions as contactActions,
	selectors as contactSelectors,
} from 'src/store/modules/contacts'
import { actions as roleActions, selectors as rolesSelectors } from 'src/store/modules/roles'
import type { RootState } from 'src/store/types'
import { readableList } from 'src/helpers/labels'
import { dataGridStandard } from 'src/helpers/ui'

import ContactDrawer from './contact-drawer'

enum ColumnId {
	type = 'type',
	name = 'name',
	surname = 'surname',
	roles = 'roles',
	assignments = 'assignments',
	email = 'email',
	phone = 'phone',
}

const contactTypeIcon = (type: ContactTypes.ContactType) => {
	return <Chip label={capitalize(type)} />
}

export const columns: GridColDef[] = Object.values(ColumnId).map((id) => ({
	field: id,
	headerName: capitalize(id),
	...(id === ColumnId.type
		? {
				renderCell: (params) => contactTypeIcon(params.row.type),
				maxWidth: 140,
			}
		: {}),
}))

const Contacts: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [isLoading, setIsLoading] = useState(false)

	const contacts = useSelector(contactSelectors.getContacts)
	const mappedRoles = useSelector(rolesSelectors.getMappedRoles)

	const [selectedContact, setSelectedContact] = useState<string | undefined>()
	const [showingContractDrawer, setShowingContactDrawer] = useState<boolean>(false)

	const rows = useMemo(
		() =>
			contacts.map((row) => ({
				id: row._id,
				type: row.type,
				name: row.name,
				surname: row.surname,
				roles: readableList(row.roles.map((roleId) => mappedRoles.get(roleId)?.label ?? '')),
				email: row.email,
				phone: row.phone,
				assignments: row.assignments,
				__reorder__: row.name,
			})),
		[contacts],
	)

	const columnsWithSharedFields = columns.map((column) => ({
		...column,
		flex: 1,
	}))

	const handleFetchContacts = async () => {
		setIsLoading(true)
		await dispatch(contactActions.getContacts())
		await dispatch(roleActions.getRoles())
		setIsLoading(false)
	}

	useEffect(() => {
		if (!contacts.length) {
			handleFetchContacts()
		}
	}, [])

	return (
		<>
			<ContactDrawer
				open={showingContractDrawer}
				contactId={selectedContact}
				setShowContactDrawer={setShowingContactDrawer}
				setSelectedContact={setSelectedContact}
			/>
			<Box pt={4} px={4} display="flex" justifyContent="space-between">
				<Text variant="h4">Contacts</Text>
				<Button
					onClick={() => setShowingContactDrawer(true)}
					variant="contained"
					startIcon={<AddIcon fill={'#fff'} />}
				>
					New Contact
				</Button>
			</Box>
			<DataGridPremium
				loading={isLoading}
				rows={rows}
				columns={columnsWithSharedFields}
				onRowClick={({ id }) => {
					setShowingContactDrawer(true)
					setSelectedContact(String(id))
				}}
				sx={{ ...dataGridStandard, m: 4 }}
				getRowHeight={() => 'auto'}
				hideFooter
			/>
		</>
	)
}

export default Contacts

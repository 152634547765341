import { Box, Text } from 'src/components'
import { colors } from 'src/theme/colors'

type Props = {
	annotation: string
}

export const AnnotationCard = ({ annotation }: Props) => {
	return (
		<Box my={1} px={2} py={2} borderRadius={4} bgcolor={colors.neutral['30']}>
			<Text>{annotation}</Text>
		</Box>
	)
}

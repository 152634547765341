import { V3BlueprintSdk } from '@cango-app/sdk'
import { isEmpty, map } from 'lodash'
import React, { ComponentType } from 'react'
import { FieldErrors } from 'react-hook-form'

import { Box, Button, Text } from 'src/components'

type SubmitButtonsProps = {
	onSubmit: () => void
	onDelete: () => void
	isLoading?: boolean
	stepId?: string
	canSubmit: boolean
	errors: FieldErrors<V3BlueprintSdk.UnpopulatedStep>
}

export const SubmitButtons: ComponentType<SubmitButtonsProps> = ({
	onSubmit,
	onDelete,
	isLoading,
	stepId,
	canSubmit,
	errors,
}) => (
	<Box mt={3}>
		<Box display="flex">
			<Button
				variant="contained"
				onClick={onSubmit}
				isLoading={isLoading}
				disabled={!canSubmit}
				sx={{ mr: 1 }}
			>
				Save
			</Button>
			{!!stepId && (
				<Button color="error" onClick={onDelete} disabled={isLoading} variant="outlined">
					Delete
				</Button>
			)}
		</Box>
		{/* {!isEmpty(errors) && (
			<Box mt={1}>
				{map(errors, (error) => (
					<Text color="error" fontSize={12}>
						{error?.message}
					</Text>
				))}
			</Box>
		)} */}
	</Box>
)

import { V3ClientTypes } from '@cango-app/types'
import { ComponentType, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { Box, Button, Select } from 'src/components'
import { TaskContext } from 'src/providers/task-provider'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { selectors as userSelectors } from 'src/store/modules/user'
import { AsyncDispatchType } from 'src/store/types'

export const TaskSettingsPanel: ComponentType<{ onDelete: () => void }> = ({ onDelete }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isSavingAssignee, setIsSavingAssignee] = useState(false)
	const { task, updateTask } = useContext(TaskContext)
	const users = useSelector(userSelectors.getAllUsersForSelect)

	const handleAssignTask = async (userIds: string[]) => {
		if (!task) return
		setIsSavingAssignee(true)
		const filteredUserIds = userIds.filter((id) => !!id)
		await updateTask(
			'assignees',
			filteredUserIds.map(
				(id): V3ClientTypes.Project.Assignee => ({
					_id: v4(),
					user: id,
					is_override: true,
				}),
			),
			{ updateDb: true },
		)
		dispatch(
			projectActions.updateStoreTask({
				taskId: task._id,
				key: 'assignee',
				value: userIds,
			}),
		)
		setIsSavingAssignee(false)
	}

	return (
		<Box>
			<Select
				label="Which user is owning this task"
				multiple
				onChange={(event) => handleAssignTask(event.target.value as string[])}
				value={task?.assignees ? task?.assignees?.map((_assignee) => _assignee.user) || [] : []}
				options={users || []}
				containerProps={{
					mt: 1,
				}}
				disabled={isSavingAssignee}
				isLoading={isSavingAssignee}
			/>
			<Box mt={2}>
				<Button color="error" onClick={onDelete} variant="outlined">
					Delete
				</Button>
			</Box>
		</Box>
	)
}

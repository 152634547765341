import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts'

import { selectors as userSelectors, actions as userActions } from 'src/store/modules/user'

export const useUsers = () => {
	const dispatch = useDispatch()
	const users = useSelector(userSelectors.getAllUsers)

	useEffectOnce(() => {
		if (!users.length) {
			dispatch(userActions.fetchAllUsers())
		}
	})
}

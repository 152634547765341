import { Stack, StackProps } from '@mui/system'

import { AllMyTasksDoneIllustration } from 'src/assets/images/illustrations'
import { Text } from 'src/components'

export const AllMyTasksDone = (props: StackProps) => {
	return (
		<Stack display="flex" alignItems="center" direction="column" spacing={2} {...props}>
			<Text variant="h4" fontWeight={600}>
				You have completed all your tasks. Great job!
			</Text>
			<AllMyTasksDoneIllustration width={400} />
		</Stack>
	)
}

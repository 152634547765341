import React, { ComponentType, useState } from 'react'
import { V3ClientTypes } from '@cango-app/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { DatePicker } from 'src/components'
import { showSnackbar } from 'src/helpers/snackbarManager'

dayjs.extend(utc)

export type AssignDateProps = { projectId: string; taskId: string; date: number }

type AssignDateFieldProps = {
	task: V3ClientTypes.Project.Task
	onAssignDate: (data: AssignDateProps) => Promise<void>
}

export const AssignDateField: ComponentType<AssignDateFieldProps> = ({ task, onAssignDate }) => {
	const [isDateSaving, setIsDateSaving] = useState(false)
	const { project_id: projectId, _id: taskId } = task

	const assignDate = async (date: Date | null) => {
		if (!date) return
		try {
			setIsDateSaving(true)
			const unixDate = dayjs(date).startOf('date').unix()
			await onAssignDate({ projectId, taskId, date: unixDate })
			showSnackbar('Date saved', { variant: 'success' })
		} catch (error) {
			showSnackbar('Failed to save date', { variant: 'error' })
		} finally {
			setIsDateSaving(false)
		}
	}

	return (
		<DatePicker
			containerProps={{ mt: 1, width: '100%' }}
			value={task.when.date ? dayjs.unix(task.when.date).toDate() : null}
			onChange={assignDate}
			disabled={isDateSaving}
		/>
	)
}

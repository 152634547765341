import { TableTypes, TemplateTypes, V3ClientTypes } from '@cango-app/types'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
	generateTreeData,
	getFormatedTable,
	TreeData,
} from 'src/components/display-view/presentation-view-utils'
import { useColumnFormatter } from 'src/modules/tables/use-column-formatter'
import { applyFilterModelToRow, resolveAnyRowCalculations } from 'src/modules/tables/utils'
import { TableContext } from 'src/providers/table-provider'
import { TaskContext } from 'src/providers/task-provider'
import { selectors as contactSelectors } from 'src/store/modules/contacts'

type UseResolveTemplate = {
	template: V3ClientTypes.Project.TaskAction['template'] | undefined
}

const getFormattedView = (treeData: TreeData[]) => {
	return treeData
		.map((nodeChild) => {
			const { title, tables } = getFormatedTable(nodeChild)
			return tables
				.map((table, index) => {
					return `<h3>${title[index]}</h3>${table}<br/><br/>`
				})
				.join('')
		})
		.join('')
}

const getFormattedChainOptions = (
	mappedColumns: Map<string, TableTypes.Field>,
	chain: V3ClientTypes.Project.Task['chain'],
) => {
	if (!chain) return ''
	const { prefix, selected_option, columnType } = chain.label
	if (!columnType || !selected_option || !prefix) return ''
	if (
		[
			TableTypes.FieldType.CONTACT,
			TableTypes.FieldType.SINGLE_SELECT,
			TableTypes.FieldType.TABLE_SELECT,
			TableTypes.FieldType.REFERENCE,
		].includes(columnType)
	) {
		const column = mappedColumns.get(prefix)
		return column?.valueOptions.find(({ _id }) => selected_option === _id)?.label ?? ''
	}
	return selected_option
}

export const useResolveTemplate = ({ template }: UseResolveTemplate) => {
	const contacts = useSelector(contactSelectors.getContactsForSelect)
	const { task } = useContext(TaskContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const { table, mappedColumns, apiRef } = useContext(TableContext)

	const { columns: columnDef } = useColumnFormatter({
		apiRef,
		isBulkEditEnabled: false,
		isTableLocked: false,
		sortingModel: [],
	})

	const rows = useMemo(() => {
		if (!table) return []
		const filteredRecords = table.records.filter(({ data }) => {
			return applyFilterModelToRow(
				columnDef.map((_col) => ({
					_id: _col.field,
					type: _col.type,
				})),
				data,
				task?.chain?.database_chain_logic.filters as GridFilterModel,
			)
		})
		return filteredRecords.map((_record) => {
			const resolvedRow = resolveAnyRowCalculations({
				fields: table.fields,
				row: _record,
				contacts: mappedContacts,
			})
			table.fields.forEach(({ type, _id, valueOptions }) => {
				if (
					[
						TableTypes.FieldType.TABLE_SELECT,
						TableTypes.FieldType.SINGLE_SELECT,
						TableTypes.FieldType.REFERENCE,
						TableTypes.FieldType.CONTACT,
					].includes(type)
				) {
					resolvedRow[_id] =
						valueOptions.find((valueOption) => valueOption._id === resolvedRow[_id])?.label ?? ''
				}
			})
			return resolvedRow
		})
	}, [table, mappedContacts, task?.chain?.database_chain_logic, columnDef])

	const views = useMemo(() => {
		if (!table) return []
		return table.views
	}, [table])

	const replaceVariables = useCallback(
		(
			template: TemplateTypes.Templates | undefined,
			taskActionTemplateVariables: TemplateTypes.Variable[] | undefined,
		): string => {
			if (!template || !taskActionTemplateVariables || !views) return ''
			return template.content.replace(/\{\{([^}]+)\}\}/g, (_, variableName: string): string => {
				const templateVariable = template.variables.find(({ name }) => name === variableName)
				const foundVariable = taskActionTemplateVariables.find(
					(v) => v._id === templateVariable?._id,
				)
				const originalTemplateVariables = template.variables.find(
					(variable) => variable._id === foundVariable?._id,
				)
				if (!foundVariable || !originalTemplateVariables) return `{{${variableName}}}`

				switch (originalTemplateVariables.type) {
					case TemplateTypes.VariableType.text:
						return foundVariable.value ?? `{{${variableName}}}`
					case TemplateTypes.VariableType.contacts: {
						const foundContact = contacts.find(({ _id }) => _id === foundVariable.value)
						return `${foundContact?.name} ${foundContact?.surname}`
					}
					case TemplateTypes.VariableType.view: {
						const foundView = views.find(({ _id }) => foundVariable.value === _id)
						if (!foundView) return `${variableName}`
						const columns = [...mappedColumns.values()]
						const treeData = Object.keys(foundView.groupedFields).length
							? generateTreeData(rows, columns, foundView?.groupedFields)
							: ([
									{
										_id: table?._id,
										children: rows,
										label: table?.name,
										records: columns,
									},
								] as TreeData[])
						return getFormattedView(treeData)
					}
					case TemplateTypes.VariableType.chain:
						return getFormattedChainOptions(mappedColumns, task?.chain)
					default:
						return `{{${variableName}}}`
				}
			})
		},
		[views, mappedColumns, task?.chain, table, rows],
	)

	const content = useMemo(
		() => replaceVariables(template?.template, template?.variables),
		[replaceVariables, template],
	)

	return {
		content,
	}
}

import React, { ComponentType, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PermissionsTypes } from '@cango-app/types'

import { Drawer } from 'src/components'
import { selectors as userSelectors } from 'src/store/modules/user'

import { DeleteUser } from './delete-user'
import { UserForm } from './user-form'

type UserDrawerProps = {
	open: boolean
	userId?: string
	setShowUserDrawer: (open: boolean) => void
	setSelectedUser: (userId: string | undefined) => void
	permissions: PermissionsTypes.Permissions[] | undefined
}

const UserDrawer: ComponentType<UserDrawerProps> = ({
	open,
	userId,
	setShowUserDrawer,
	setSelectedUser,
	permissions,
}) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	const user = useMemo(() => {
		return userId ? mappedUsers.get(userId) : undefined
	}, [mappedUsers, userId])

	const handleCloseDrawer = () => {
		setSelectedUser(undefined)
		setShouldPromptDelete(false)
		setShowUserDrawer(false)
	}

	return (
		<Drawer open={open} variant="temporary" anchor="right" onClose={handleCloseDrawer}>
			{shouldPromptDelete && user ? (
				<DeleteUser user={user} closeDrawer={handleCloseDrawer} />
			) : (
				<UserForm
					user={user}
					closeDrawer={handleCloseDrawer}
					setShouldPromptDelete={setShouldPromptDelete}
					permissions={permissions}
				/>
			)}
		</Drawer>
	)
}

export default UserDrawer

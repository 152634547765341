import { useCallback, useEffect, useState } from 'react'
import { InputAdornment, Stack } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import Error from '@mui/icons-material/Error'
import { Controller, useForm } from 'react-hook-form'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { OrganisationSdk, AuthSdk, AxiosError, getGoogleDriveFolderId } from '@cango-app/sdk'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Box, Button, IconButton, Select, Text, TextField } from 'src/components'
import { CreateOrganisationIlustration } from 'src/assets/images/illustrations'
import { colors } from 'src/theme/colors'
import { CangoLogo, CangoLogoText } from 'src/assets/icons'
import { STAGING_ALERT_BANNER_HEIGHT } from 'src/routing/navigation/staging-alert'
import { selectors as authSelectors, actions as authActions } from 'src/store/modules/auth'
import { actions as sessionActions } from 'src/store/modules/session-config'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { GoogleDriveHelpModal } from './google-drive-help-modal'

const createOrganisationSchema = z.object({
	organisationName: z.string().min(1, { message: 'Organisation name cannot be empty' }),
	organisationId: z.string().length(3, { message: 'Organisation Id must be 3 characters long' }),
	googleDriveUrl: z.string(),
	timezone: z.nativeEnum(AuthSdk.Timezone),
})

type CreateOrganisationForm = z.infer<typeof createOrganisationSchema>

export const CreateOrganisation = () => {
	const dispatch = useDispatch()
	const loginToken = useSelector(authSelectors.getLoginToken)

	const navigate = useNavigate()
	const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
	const [hasValidated, setHasValidated] = useState(false)
	const [isCreatingOrg, setIsCreatingOrg] = useState(false)
	const {
		control,
		handleSubmit,
		watch,
		setError,
		clearErrors,

		formState: { errors },
	} = useForm<CreateOrganisationForm>({
		defaultValues: {
			organisationName: '',
			organisationId: '',
			timezone: AuthSdk.Timezone.Eastern,
			googleDriveUrl: '',
		},
		resolver: zodResolver(createOrganisationSchema),
	})

	const organisationid = watch('organisationId')

	const isValid = useCallback(
		async (organisationId: string) => {
			try {
				await OrganisationSdk.isValidOrganisationId(
					import.meta.env.VITE_API as string,
					{
						Authorization: `Bearer ${loginToken}`,
					},
					organisationId,
				)
				if (errors && errors.organisationId) {
					clearErrors('organisationId')
				}
			} catch (err) {
				setError('organisationId', { message: 'Id is already taken. Please, choose another one' })
			} finally {
				setHasValidated(true)
			}
		},
		[loginToken, errors],
	)

	const isValidGoogleDrive = useCallback(
		async (googleDriveUrl: string) => {
			try {
				await OrganisationSdk.isValidGoogleDriveFolder(
					import.meta.env.VITE_API as string,
					{
						Authorization: `Bearer ${loginToken}`,
					},
					googleDriveUrl,
				)
				if (errors && errors.googleDriveUrl) {
					clearErrors('googleDriveUrl')
				}
			} catch (error) {
				const axiosMessage = (error as AxiosError<{ message: string }>).response?.data?.message
				const errorText = axiosMessage ?? 'Error validating google drive url'
				setError('googleDriveUrl', { message: errorText })
			}
		},
		[loginToken, errors],
	)

	useEffect(() => {
		if (!organisationid) return
		if (organisationid.length === 3 && !hasValidated) {
			isValid(organisationid)
		}
	}, [organisationid, isValid])

	useEffect(() => {
		if (!organisationid) return
		if (organisationid.length < 3 && hasValidated) {
			setHasValidated(false)
		}
	}, [organisationid, hasValidated])

	const onCreate = async (data: CreateOrganisationForm) => {
		if (!loginToken) return
		setIsCreatingOrg(true)
		try {
			const { organisationId, loginToken: newLoginToken } =
				await OrganisationSdk.createOrganisation(
					import.meta.env.VITE_API as string,
					{
						Authorization: `Bearer ${loginToken}`,
					},
					data,
				)
			// wait to be fullfilled
			await dispatch(
				authActions.ssoLogin({
					organisationId,
					loginToken: newLoginToken,
					isManualLogin: false,
				}),
			)
			dispatch(sessionActions.setSelectedOrganisationId(organisationId))
			dispatch(authActions.setLastVisitedOrganisationId(organisationId))
		} catch (error) {
			const axiosMessage = (error as AxiosError<{ message: string }>).response?.data?.message
			const errorText = axiosMessage ?? 'Error creating organisation'
			showSnackbar(errorText, { variant: 'error' })
		} finally {
			setIsCreatingOrg(false)
		}
	}

	const hasOrganisationIdErrors = errors && errors.organisationId
	return (
		<>
			<GoogleDriveHelpModal open={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} />
			<Stack direction="row" height={`calc(100dvh - ${STAGING_ALERT_BANNER_HEIGHT}px)`}>
				<Stack
					direction="column"
					spacing={2}
					width="30%"
					padding={4}
					color={colors.neutral['10']}
					bgcolor={colors.feldgrau['100']}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						pt={{ laptop: '60px' }}
						mb={6}
					>
						<Box mb={1}>
							<CangoLogo width={75} />
						</Box>
						<CangoLogoText />
					</Box>
					<Text variant="h5" textAlign="center">
						Create an organisation
					</Text>
					<Controller
						control={control}
						name="organisationName"
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								fullWidth
								colorLabel={colors.neutral['10']}
								label="Name"
								required
								value={value}
								onChange={onChange}
								error={Boolean(error?.message)}
								helperText={error?.message}
							/>
						)}
					/>
					<Stack
						direction="row"
						alignItems={hasOrganisationIdErrors ? 'center' : 'flex-end'}
						spacing={2}
					>
						<Controller
							control={control}
							name="organisationId"
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<TextField
									label="ID"
									colorLabel={colors.neutral['10']}
									required
									value={value}
									onChange={onChange}
									inputProps={{
										maxLength: 3,
									}}
									error={Boolean(error?.message)}
									helperText={error?.message}
								/>
							)}
						/>
						{hasValidated && (
							<Box mt={1}>
								{hasOrganisationIdErrors ? (
									<Error color="error" fontSize="large" />
								) : (
									<CheckIcon color="primary" fontSize="large" />
								)}
							</Box>
						)}
					</Stack>
					<Controller
						control={control}
						name="timezone"
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Select
								label="Timezone"
								labelColor={colors.neutral['10']}
								value={value}
								onChange={onChange}
								options={Object.values(AuthSdk.Timezone).map((timezone) => ({
									_id: timezone,
									label: timezone,
								}))}
								error={Boolean(error)}
								helperText={error?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="googleDriveUrl"
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								fullWidth
								label="Google Drive Folder URL"
								colorLabel={colors.neutral['10']}
								required
								value={value}
								onChange={(event) => {
									if (getGoogleDriveFolderId(event.target.value)) {
										isValidGoogleDrive(event.target.value)
									}
									onChange(event)
								}}
								InputProps={{
									sx: {
										bgcolor: '#fff',
									},
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={() => setIsHelpModalOpen(true)}>
												<HelpOutlineOutlinedIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
								error={Boolean(error?.message)}
								helperText={error?.message}
							/>
						)}
					/>
					<Button onClick={handleSubmit(onCreate)} isLoading={isCreatingOrg}>
						Create
					</Button>
					<Text
						textAlign="center"
						fontSize={14}
						component="a"
						onClick={() => {
							navigate(`/login`)
						}}
						color={colors.neutral['10']}
						sx={{ cursor: 'pointer' }}
					>
						Cancel
					</Text>
				</Stack>
				<Box flex={2} display="flex" flexDirection="column" alignItems="center" pt="90px">
					<CreateOrganisationIlustration />
				</Box>
			</Stack>
		</>
	)
}

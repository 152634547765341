import React, { ComponentType, useContext } from 'react'

import { AttachedFile, Box, Text } from 'src/components'
import { DriveFilesContext } from 'src/providers'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

type TaskFileHistoryProps = {
	file_history: string[]
}

export const TaskFileHistory: ComponentType<TaskFileHistoryProps> = ({ file_history }) => {
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const { allFiles } = useParentFolderFiles(parentFolderId as string, parentFolderName as string)

	return (
		<Box sx={{ mt: 1 }}>
			<Text variant="overline" fontWeight="bold">
				File History
			</Text>
			<Box display="flex" flexWrap="wrap">
				{file_history.map((fileId) => {
					const file = allFiles.find((file) => file.id === fileId)
					return (
						<AttachedFile
							containerStyles={{ mb: 1 }}
							key={`historical-${fileId}`}
							fileId={fileId}
							_file={file}
							parentFolderId={parentFolderId}
							parentFolderName={parentFolderName}
						/>
					)
				})}
			</Box>
		</Box>
	)
}

import React, { ComponentType, useState, useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseOutlined from '@mui/icons-material/CloseOutlined'

import { Box, Button, Text } from 'src/components'

import { StepContext } from './step-provider'

type DeleteStepProps = {
	onCloseDrawer: () => void
	sectionId?: string
}

export const DeleteStep: ComponentType<DeleteStepProps> = ({ onCloseDrawer }) => {
	const { step: selectedStep, onDelete } = useContext(StepContext)
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteStep = async () => {
		if (!selectedStep) return
		setIsDeleting(true)
		await onDelete(selectedStep._id)
		setIsDeleting(false)
	}

	return (
		<>
			<Box m={1}>
				<IconButton onClick={onCloseDrawer}>
					<CloseOutlined />
				</IconButton>
			</Box>
			<Box width={400} p={3}>
				<Text variant="h6" mb={3}>
					Are you sure you want to delete: <b>{selectedStep?.name}</b>
				</Text>
				<Box mb={2}>
					<Button
						color="error"
						variant="contained"
						onClick={() => handleDeleteStep()}
						isLoading={isDeleting}
					>
						{'Delete'}
					</Button>
				</Box>
			</Box>
		</>
	)
}

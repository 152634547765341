import React, { ComponentType } from 'react'

import { NewIdeaIllustration } from 'src/assets/images/illustrations'
import { Box, Grid, Text } from 'src/components'

import { FeedbackForm } from './feedback-form'

export const Feedback: ComponentType = () => {
	return (
		<>
			<Text
				variant="h4"
				fontWeight="bold"
				textAlign="center"
				mt={2}>{`"Success is best when it's shared"`}</Text>
			<Text variant="overline" textAlign="center" display="block">
				Howard Schultz
			</Text>
			<Grid container mt={3}>
				<Grid
					xs={6}
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection="column">
					<Box width={400}>
						<Text variant="h6">We want to hear from you, the good and the bad!</Text>
						<FeedbackForm />
					</Box>
				</Grid>
				<Grid xs={6} display="flex" justifyContent="center" alignItems="center">
					<Box width="300px">
						<NewIdeaIllustration />
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

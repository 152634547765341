/**
 * Login component
 */

import React, { ComponentType, useState } from 'react'
import { GoogleLogin as GoogleLoginButton, CredentialResponse } from '@react-oauth/google'

import { Box, Text } from 'src/components'
import { colors } from 'src/theme/colors'

type LoginComponentProps = {
	onGoogleLoginSuccess: (credentials: CredentialResponse) => Promise<void>
	onGoogleLoginError: () => void
	onCreateAccount: () => void
}

export const GoogleLogin: ComponentType<LoginComponentProps> = ({
	onGoogleLoginSuccess,
	onGoogleLoginError,
	onCreateAccount,
}) => {
	const [loadingOrganisations, setLoadingOrganisations] = useState(false)

	const handleGoogleLoginSuccess = async (credentials: CredentialResponse) => {
		setLoadingOrganisations(true)
		await onGoogleLoginSuccess(credentials)
		setLoadingOrganisations(false)
	}

	return (
		<Box textAlign="center">
			<Text color={colors.neutral['10']} mb={4}>
				Log into your Cango account
			</Text>
			<GoogleLoginButton
				onSuccess={handleGoogleLoginSuccess}
				onError={onGoogleLoginError}
				width="300"
				useOneTap={true}
				itp_support={true}
				locale="en"
			/>
			{!!loadingOrganisations && (
				<Text color={colors.neutral['10']} fontSize={12} mt={0.5}>
					loading...
				</Text>
			)}
			<Text fontSize={12} mt={4} color={colors.neutral['10']}>
				{"Don't have an account?"}
			</Text>
			<Text
				fontSize={12}
				fontWeight={600}
				sx={{ textDecoration: 'underline', cursor: 'pointer' }}
				color={colors.neutral['10']}
				onClick={onCreateAccount}>
				Contact us and start
			</Text>
		</Box>
	)
}

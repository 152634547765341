// Regular expression to match any character that is not a letter or number
const LAST_CHARACTER_RULE = /[a-zA-Z0-9](?!.*[a-zA-Z0-9])/

export const removeLastNonAlphanumeric = (string: string) => {
	const match = string.match(LAST_CHARACTER_RULE)

	if (match && match.index !== undefined) {
		return string.slice(0, match.index + 1)
	}

	return 'Section'
}

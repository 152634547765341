import React, { ComponentType, ReactNode } from 'react'

import { Box, Text } from '../../../../components'

export const OptionListItem: ComponentType<{
	icon: ReactNode
	label: string
	secondaryLabel?: string
}> = ({ icon, label, secondaryLabel }) => {
	return (
		<Box display="flex" alignItems="center">
			<Box mr={1} display="flex" justifyContent="center" alignItems="center">
				{icon}
			</Box>
			<Box>
				<Text fontSize={10}>{label}</Text>
				{secondaryLabel && (
					<Text fontSize={10} fontWeight="bold">
						{secondaryLabel}
					</Text>
				)}
			</Box>
		</Box>
	)
}

import { Alert } from '@mui/material'

const SHOW_STAGING_ALERT = import.meta.env.VITE_ENVIRONMENT === 'staging'

export const STAGING_ALERT_BANNER_HEIGHT = SHOW_STAGING_ALERT ? 48 : 0

export const StagingAlert = () => {
	return SHOW_STAGING_ALERT ? (
		<Alert
			severity="warning"
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '36px',
				flex: 1,
			}}
		>
			This is the development version of this site
		</Alert>
	) : null
}

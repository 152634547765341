import React, { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { V3BlueprintTypes, ClientTypes } from '@cango-app/types'
import { SelectChangeEvent } from '@mui/material'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import _isString from 'lodash/isString'

import {
	Box,
	Chip,
	TextField,
	Select,
	IconButton,
	Button,
	Divider,
	Text,
	GroupedSelectOption,
	GroupedSelect,
} from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { usePrevious } from 'src/hooks/usePrevious'
import { EditPenIcon, PlusIcon, TrashIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { ChainContext } from 'src/modules/chains/chain-provider'
import { selectors as ContactSelectors } from 'src/store/modules/contacts'
import { TableFilters } from 'src/components/table-filters'

import { StepContext } from '../step-provider'
import { ViewsButton } from '../../../../tables/views-button'
import { TableContext } from '../../../../../providers/table-provider'
import { getListFromTable } from '../../../../../hooks/use-list-options'

import { StepFormControl, StepFormType } from './step-form-container'
import { TemplateUploadFields } from './template-upload-fields'
import { TemplateButton } from './template-button'

export const ACTIONS_REQUIRING_ROLES = [
	V3BlueprintTypes.ActionEnum.Call,
	V3BlueprintTypes.ActionEnum.Email,
	V3BlueprintTypes.ActionEnum.Invite,
	V3BlueprintTypes.ActionEnum.Meeting,
]

export const ACTIONS_REQUIRING_FILE_SELECT = [V3BlueprintTypes.ActionEnum.FileView]

export const ACTIONS_REQUIRING_LINK = [
	V3BlueprintTypes.ActionEnum.Software,
	V3BlueprintTypes.ActionEnum.Video,
]

const SoftwareInputs: React.FC<{ actionIndex: number; chainIndex: number }> = ({
	actionIndex,
	chainIndex,
}) => {
	const { control } = useFormContext<StepFormType>() // Make sure to have the correct generic <StepFormType>
	const { fields, append, remove } = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.actions.${actionIndex}.links`,
	})

	return (
		<Box>
			{fields.map((field, fieldIndex) => (
				<Controller
					key={field._id} // It's important to use a unique key for list items
					control={control}
					name={`chain_actions.${chainIndex}.actions.${actionIndex}.links.${fieldIndex}.link`}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Box display="flex">
							<TextField
								error={!!error}
								label="Link"
								value={value}
								fullWidth
								onChange={onChange}
								containerProps={{ flex: 1, mb: 2 }}
							/>
							<Box>
								<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
									<TrashIcon width={14} />
								</IconButton>
							</Box>
						</Box>
					)}
				/>
			))}
			<Button
				variant="outlined"
				size="small"
				onClick={() => {
					append({
						_id: v4(),
						link: '',
					})
				}}
			>
				Add new link
			</Button>
		</Box>
	)
}

const TaskReferences: React.FC<{ actionIndex: number; chainIndex: number }> = ({
	actionIndex,
	chainIndex,
}) => {
	const { control, watch } = useFormContext<StepFormType>()
	const stepId = watch('_id')
	const { nodes } = useContext(ChainContext)
	const { fields, append, remove } = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.actions.${actionIndex}.task_references`,
	})

	const taskOptions = useMemo(() => {
		const filteredNodes = nodes.filter((_node) => _node.id !== stepId && !_node.data.isSection)
		return filteredNodes.map((_node) => ({
			_id: _node.id,
			label: _node.data.name,
		}))
	}, [nodes])

	return (
		<Box>
			{fields.map((field, fieldIndex) => (
				<>
					<Box key={field.task}>
						<Controller
							control={control}
							name={`chain_actions.${chainIndex}.actions.${actionIndex}.task_references.${fieldIndex}.task`}
							render={({ field: { value, onChange } }) => (
								<Box display="flex">
									<Select
										value={value}
										onChange={onChange}
										label="Select a task"
										options={taskOptions}
										containerProps={{ width: 375 }}
									/>
									<Box>
										<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
											<TrashIcon width={14} />
										</IconButton>
									</Box>
								</Box>
							)}
						/>
						<Controller
							control={control}
							name={`chain_actions.${chainIndex}.actions.${actionIndex}.task_references.${fieldIndex}.fields`}
							render={({ field: { value, onChange } }) => (
								<Select
									multiple
									label="Fields"
									value={value}
									onChange={onChange}
									options={Object.values(V3BlueprintTypes.TaskReferenceEnum).map((type) => ({
										_id: type,
										label: getActionLabel(type),
									}))}
									containerProps={{ maxWidth: 375, mb: 1 }}
								/>
							)}
						/>
					</Box>
					<Divider sx={{ mb: 2 }} />
				</>
			))}
			<Button
				variant="outlined"
				size="small"
				onClick={() => {
					append({
						task: '',
						fields: [],
					})
				}}
			>
				Add a task reference
			</Button>
		</Box>
	)
}

const UndecoratedActionFields: ComponentType<{
	control: StepFormControl
	chainIndex: number
	actionIndex: number
	onRemove: () => void
}> = ({ control, actionIndex, chainIndex, onRemove }) => {
	const { setValue } = useFormContext<StepFormType>()
	const { table } = useContext(TableContext)
	const action = useWatch({ control, name: `chain_actions.${chainIndex}.actions.${actionIndex}` })
	const actions = useWatch({ control, name: `chain_actions.${chainIndex}.actions` })
	const previousActionType = usePrevious(action.type)
	const roles = useSelector(roleSelectors.getRoles)
	const usedActions = actions.map((_action) => _action.type)
	const [shouldOpenViewForm, setShouldOpenViewForm] = useState(false)

	const viewSelected = useMemo(() => {
		if (!table?.views || !action.view) {
			return null
		}

		return table.views.find((_view) => _view._id === action.view)
	}, [action.view, table?.views])

	const actionTypeOptions = useMemo(() => {
		const options = Object.values(V3BlueprintTypes.ActionEnum).map((type) => ({
			_id: type,
			label: getActionLabel(type),
		}))

		return options.filter((_option) => {
			if (
				[V3BlueprintTypes.ActionEnum.LinkView, V3BlueprintTypes.ActionEnum.FileView].includes(
					_option._id,
				)
			) {
				return false
			}
			if (_option._id === action.type) {
				return true
			}
			return !usedActions.includes(_option._id)
		})
	}, [usedActions, action.type])

	useEffect(() => {
		if (
			previousActionType &&
			!ACTIONS_REQUIRING_FILE_SELECT.includes(action.type) &&
			previousActionType !== action.type &&
			!!action.files_from_tasks?.length
		) {
			setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.files_from_tasks`, [], {
				shouldDirty: true,
			})
		}

		if (
			previousActionType &&
			action.type !== V3BlueprintTypes.ActionEnum.LinkView &&
			previousActionType !== action.type &&
			!!action.links_from_tasks?.length
		) {
			setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.links_from_tasks`, [], {
				shouldDirty: true,
			})
		}

		if (
			previousActionType &&
			!ACTIONS_REQUIRING_LINK.includes(action.type) &&
			previousActionType !== action.type &&
			action.links.length
		) {
			setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.links`, [], {
				shouldDirty: true,
			})
		}

		// if (
		// 	previousActionType &&
		// 	previousActionType !== action.type &&
		// 	action.type === V3BlueprintTypes.ActionEnum.Archive
		// ) {
		// 	setValue(
		// 		'descendants.children',
		// 		children.filter((_child) =>
		// 			[V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
		// 				_child._id as V3BlueprintTypes.TaskPhase,
		// 			),
		// 		),
		// 		{ shouldDirty: true },
		// 	)
		// }
	}, [action.type, action.files_from_tasks, action.link, action.links_from_tasks])

	return (
		<Box
			sx={{
				mb: 2,
				display: 'flex',
			}}
		>
			<Box flex={1}>
				<Controller
					control={control}
					name={`chain_actions.${chainIndex}.actions.${actionIndex}.type`}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Select
							error={!!error}
							disabled={
								action.type === V3BlueprintTypes.ActionEnum.FileTemplate &&
								!!action.file_ids?.length
							}
							label="What needs to be done?"
							onChange={(event) => onChange(event.target.value as V3BlueprintTypes.ActionEnum)}
							value={value}
							options={actionTypeOptions}
							containerProps={{ my: 2, maxWidth: 375 }}
							helperText={error?.message}
						/>
					)}
				/>

				{action.type && ACTIONS_REQUIRING_ROLES.includes(action.type) && (
					<Controller
						control={control}
						name={`chain_actions.${chainIndex}.actions.${actionIndex}.roles`}
						render={({ field: { value, onChange }, fieldState: { error } }) => {
							const handleChange = (event: SelectChangeEvent<unknown>) => {
								const { value } = event.target
								onChange(typeof value === 'string' ? value.split(',') : value)
							}
							return (
								<Select
									error={!!error}
									label={`${action.type} who?`}
									onChange={handleChange}
									value={value || []}
									multiple
									multiline
									options={roles}
									containerProps={{ mb: 2, maxWidth: 375 }}
									renderValue={(selected: any) => {
										const roleNames = selected.map((id: string) =>
											roles.find((role) => role._id === id),
										)
										return (
											<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
												{roleNames.map((role: ClientTypes.Role) => {
													return role ? <Chip key={role._id} label={role.label} /> : ''
												})}
											</Box>
										)
									}}
								/>
							)
						}}
					/>
				)}

				{action.type === V3BlueprintTypes.ActionEnum.FileTemplate && (
					<TemplateUploadFields chainIndex={chainIndex} actionIndex={actionIndex} />
				)}

				{action.type === V3BlueprintTypes.ActionEnum.TaskReference && (
					<TaskReferences actionIndex={actionIndex} chainIndex={chainIndex} />
				)}

				{!!viewSelected && (
					<Box display="flex" justifyContent="space-between" mb={2}>
						<Box
							display="flex"
							justifyContent="space-between"
							border="1px solid"
							borderColor={colors.neutral['40']}
							borderRadius={2}
							px={2}
							py={1}
							minWidth={175}
							bgcolor="#fff"
						>
							<Box>
								<Text variant="overline">View</Text>
								<Text>{viewSelected.name}</Text>
							</Box>
							<Box sx={{ ml: 2 }} display="flex" alignItems="center">
								<IconButton onClick={() => setShouldOpenViewForm(true)}>
									<EditPenIcon stroke={colors.neutral['60']} />
								</IconButton>
								<IconButton
									onClick={() =>
										setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.view`, '', {
											shouldDirty: true,
										})
									}
								>
									<TrashIcon stroke={colors.neutral['60']} />
								</IconButton>
							</Box>
						</Box>
					</Box>
				)}

				{action.type === V3BlueprintTypes.ActionEnum.SetResources && (
					<ViewsButton
						openViewParams={{
							open: shouldOpenViewForm,
							viewId: action.view,
						}}
						onViewSelect={(viewId) => {
							setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.view`, viewId, {
								shouldDirty: true,
							})
							if (!viewId) {
								setShouldOpenViewForm(true)
							}
						}}
						onClose={() => setShouldOpenViewForm(false)}
						label={viewSelected ? 'Change view' : 'Select view'}
						buttonVariant="outlined"
						size="small"
						onViewSaved={(viewId) =>
							setValue(`chain_actions.${chainIndex}.actions.${actionIndex}.view`, viewId, {
								shouldDirty: true,
							})
						}
					/>
				)}

				{action.type === V3BlueprintTypes.ActionEnum.Template && (
					<TemplateButton chainActionId={`chain_actions.${chainIndex}.actions.${actionIndex}`} />
				)}

				{action.type && action.type === V3BlueprintTypes.ActionEnum.Note && (
					<Controller
						control={control}
						name={`chain_actions.${chainIndex}.actions.${actionIndex}.note`}
						render={({ field: { value, onChange } }) => (
							<TextField
								label="Describe the action"
								value={value}
								fullWidth
								multiline
								onChange={(event) => onChange(event.target.value)}
								sx={{ mb: 2 }}
							/>
						)}
					/>
				)}

				{action.type && action.type === V3BlueprintTypes.ActionEnum.Questionaire && (
					<>
						<Text variant="caption">Questionaire table filters:</Text>
						<Controller
							name={`chain_actions.${chainIndex}.actions.${actionIndex}.questionaire.filters`}
							render={({ field: { onChange, value } }) => {
								return (
									<TableFilters
										initialState={{
											filter: {
												filterModel: value,
											},
										}}
										onChange={onChange}
									/>
								)
							}}
						/>
					</>
				)}

				{action.type && ACTIONS_REQUIRING_LINK.includes(action.type) && (
					<SoftwareInputs chainIndex={chainIndex} actionIndex={actionIndex} />
				)}
			</Box>
			<Box>
				<IconButton sx={{ mt: 5, ml: 1 }} onClick={onRemove}>
					<TrashIcon />
				</IconButton>
			</Box>
		</Box>
	)
}

const OptionActions: ComponentType<{
	onRemove: (index: number) => void
	optionList: GroupedSelectOption[]
	chainIndex: number
}> = ({ onRemove, optionList, chainIndex }) => {
	const { control, watch } = useFormContext<StepFormType>()
	const {
		fields: actionFields,
		append,
		remove,
	} = useFieldArray({
		control,
		name: `chain_actions.${chainIndex}.actions`,
	})
	const allChainActions = watch('chain_actions')
	const currentItem = watch(`chain_actions.${chainIndex}`)

	const hasNoExtraParents = useMemo(() => {
		return !optionList.length || (optionList.length === 1 && optionList[0].groupName === 'Default')
	}, [optionList])

	const filteredParentsList = useMemo(() => {
		return optionList.reduce((_acc: GroupedSelectOption[], _group) => {
			return [
				..._acc,
				{
					..._group,
					_options: _group.options.filter((_option) => {
						if (_option._id === currentItem.option_id) {
							return true
						}
						return !allChainActions.find((_action) => _action.option_id === _option._id)
					}),
				},
			]
		}, [])
	}, [optionList])

	return (
		<Box
			sx={{
				backgroundColor: 'rgba(164, 198, 188, 0.1)',
				p: 2,
				borderRadius: '8px',
				mb: 2,
			}}
		>
			{!hasNoExtraParents && (
				<Controller
					control={control}
					name={`chain_actions.${chainIndex}.option_id`}
					render={({ field: { value, onChange } }) => (
						<GroupedSelect
							label="Which chain is this for?"
							options={filteredParentsList}
							value={value}
							onChange={onChange}
							containerProps={{ mb: 1 }}
						/>
					)}
				/>
			)}

			{actionFields.map((_item, index) => (
				<>
					<UndecoratedActionFields
						key={_item._id}
						control={control}
						actionIndex={index}
						chainIndex={chainIndex}
						onRemove={() => remove(index)}
					/>
					{index !== actionFields.length - 1 && <Divider />}
				</>
			))}
			<Box display="flex" justifyContent="space-between">
				<Button
					size="small"
					variant="outlined"
					onClick={() =>
						append({
							_id: v4(),
							type: V3BlueprintTypes.ActionEnum.None,
							roles: [],
							files_from_tasks: [],
							note: '',
							links: [],
							file_ids: [],
							links_from_tasks: [],
							task_references: [],
						})
					}
					startIcon={<PlusIcon width={16} stroke={colors.feldgrau['60']} />}
				>
					{`Add an action${!hasNoExtraParents ? ' for this chain' : ''}`}
				</Button>
				<Button
					size="small"
					variant="outlined"
					color="error"
					onClick={() => onRemove(chainIndex)}
					startIcon={<TrashIcon stroke={colors.error.main} width={12} />}
				>
					{`Remove all actions${!hasNoExtraParents ? ' for chain' : ''}`}
				</Button>
			</Box>
		</Box>
	)
}

export const MultipleStepActions: ComponentType = () => {
	const { control } = useFormContext<StepFormType>()
	const { hierarchy } = useContext(StepContext)
	const { mappedColumns, table } = useContext(TableContext)
	const mappedContacts = useSelector(ContactSelectors.mappedContacts)
	const actions = useWatch({ control, name: 'chain_actions' })
	const { fields, append, remove } = useFieldArray({ control, name: 'chain_actions' })

	const optionList = useMemo((): GroupedSelectOption[] => {
		const tasksWithOptions = [...hierarchy.values()].filter(
			(_task) => _task.options?.length && _task.depth !== 0,
		)

		if (!tasksWithOptions.length) return []

		return tasksWithOptions.reduce(
			(_acc: GroupedSelectOption[], _task) => {
				const parentName = _task.name
				let options = _task.options ?? []
				if (_isString(options) && table) {
					options = getListFromTable(options, table, mappedColumns, mappedContacts)
				}

				if (_isString(options)) {
					return _acc
				}

				return [
					..._acc,
					{
						groupName: parentName,
						options: options,
					},
				]
			},
			[
				{
					groupName: 'Default',
					options: [
						{
							_id: V3BlueprintTypes.Task_Action_All,
							label: 'All',
						},
					],
				},
			],
		)
	}, [hierarchy, mappedColumns])

	const handleAddChain = () => {
		if (!optionList.length) {
			append({
				option_id: V3BlueprintTypes.Task_Action_All,
				actions: [],
			})
			return
		}

		if (!optionList[0]?.options.length) {
			return
		}

		append({
			option_id: optionList[0].options[0]._id,
			actions: [],
		})
	}

	return (
		<Box mt={2}>
			<Box mb={2}>
				<Button
					size="small"
					variant="outlined"
					onClick={handleAddChain}
					disabled={optionList.length > 1 && optionList.length === Object.keys(actions).length}
				>
					Add
				</Button>
			</Box>
			{fields.map((_field, index) => (
				<OptionActions
					key={_field.option_id}
					onRemove={remove}
					optionList={optionList}
					chainIndex={index}
				/>
			))}
		</Box>
	)
}

import capitalize from 'lodash/capitalize'
import React, { useMemo, ComponentType, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { ClientTypes } from '@cango-app/types'
import { useEffectOnce } from 'usehooks-ts'
import _orderBy from 'lodash/orderBy'

import type { RootState } from 'src/store/types'
import { Button, Text, Chip, Box } from 'src/components'
import { actions as userActions, selectors as userSelectors } from 'src/store/modules/user'
import {
	actions as contactActions,
	selectors as contactSelectors,
} from 'src/store/modules/contacts'
import { selectors as roleSelectors, actions as roleActions } from 'src/store/modules/roles'
import { dataGridStandard } from 'src/helpers/ui'
import { HardHat } from 'src/assets/icons'

import RoleDrawer from './role-drawer'

enum ColumnId {
	internal = 'type',
	label = 'name',
	usage = 'usage',
	default = 'default',
}

const roleTypeIcon = (internal: boolean) => {
	return (
		<Chip color={internal ? 'primary' : 'default'} label={internal ? 'Internal' : 'External'} />
	)
}

export const columns: GridColDef[] = Object.values(ColumnId).map((id) => ({
	field: id,
	headerName: capitalize(id),
	...(id === ColumnId.internal
		? {
				renderCell: (params) => roleTypeIcon(params.row.internal),
				maxWidth: 100,
			}
		: {}),
}))

const getDefault = ({
	isInternal,
	defaultContact,
	defaultUser,
}: {
	isInternal: boolean
	defaultUser?: ClientTypes.User
	defaultContact?: ClientTypes.Contact
}): string => {
	if (isInternal && defaultUser) {
		return defaultUser.name + ' ' + defaultUser.surname
	}
	if (!isInternal && defaultContact) {
		return defaultContact.name + ' ' + defaultContact.surname
	}
	return ''
}

const Roles: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()

	const [isLoading, setIsLoading] = useState(true)
	const [showRoleDrawer, setShowRoleDrawer] = useState(false)
	const [selectedRole, setSelectedRole] = useState<string | undefined>()
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const roles = useSelector(roleSelectors.getRoles)

	useEffect(() => {
		Promise.all([
			dispatch(userActions.fetchAllUsers()),
			dispatch(contactActions.getContacts()),
			dispatch(roleActions.getRoles()),
		]).then(() => {
			setIsLoading(false)
		})
	}, [])

	const rows = useMemo(
		() =>
			roles.map((_role) => ({
				..._role,
				id: _role._id,
				name: _role.label,
				usage: typeof _role.usage === 'number' && _role.usage > 0 ? `${_role.usage} uses` : 'None',
				default: getDefault({
					isInternal: _role.internal,
					defaultUser: _role.defaultUser ? mappedUsers.get(_role.defaultUser) : undefined,
					defaultContact: _role.defaultContact
						? mappedContacts.get(_role.defaultContact)
						: undefined,
				}),
			})),
		[roles],
	)

	const columnsWithSharedFields = columns.map((column) => ({
		...column,
		flex: 1,
	}))

	useEffectOnce(() => {
		dispatch(roleActions.getRolesWithUsage())
	})

	return (
		<>
			<RoleDrawer
				open={showRoleDrawer}
				setShowRoleDrawer={setShowRoleDrawer}
				roleId={selectedRole}
				setSelectedRole={setSelectedRole}
			/>
			<Box pt={4} px={4} display="flex" justifyContent="space-between">
				<Text variant="h4">Roles</Text>
				<Button
					onClick={() => setShowRoleDrawer(true)}
					variant="contained"
					startIcon={<HardHat fill="#fff" />}
				>
					New Role
				</Button>
			</Box>
			<DataGridPremium
				loading={isLoading}
				rows={_orderBy(rows, 'label')}
				onRowClick={({ id }) => {
					setShowRoleDrawer(true)
					setSelectedRole(String(id))
				}}
				columns={columnsWithSharedFields}
				sx={{ ...dataGridStandard, m: 4 }}
				getRowHeight={() => 'auto'}
				hideFooter
			/>
		</>
	)
}

export default Roles

import React, { ComponentType } from 'react'

import { Box, Text } from 'src/components'
import { ConfusedUserIllustration } from 'src/assets/images/illustrations'

export const ErrorMyTasks: ComponentType = () => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
			<Box width="406px">
				<ConfusedUserIllustration />
			</Box>
			<Text fontWeight="bold" mb={2}>
				Oops! There seems to be an error with your tasks. Please try again later.
			</Text>
		</Box>
	)
}

import { ComponentType, ReactNode } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import { Button } from 'src/components/button'
import { RouteId } from 'src/constants/routes'
import { colors } from 'src/theme/colors'
import theme from 'src/theme'

type MenuItemProps = {
	endIcon?: ReactNode
	startIcon?: ReactNode
	onMenuItemClick: (event: React.MouseEvent<HTMLButtonElement>, routeId: RouteId) => void
	routeId: RouteId
	routeUrl: string
	routeName: string
}

const getRouteIsActive = (id: string, routeUrl: string) => {
	if (routeUrl === location.pathname) return true

	const blueprintUrl = `/blueprint/`
	if (id === 'blueprints' && location.pathname.includes(blueprintUrl)) return true

	const projectUrl = `/project/`
	if (id === 'projects' && location.pathname.includes(projectUrl)) return true

	return false
}

export const MenuItem: ComponentType<MenuItemProps> = ({
	endIcon,
	startIcon,
	onMenuItemClick,
	routeId,
	routeUrl,
	routeName,
}) => {
	const isSmallDesktop = useMediaQuery(theme.breakpoints.down(1200))

	if (isSmallDesktop)
		return (
			<Tooltip title={routeName}>
				<IconButton onClick={(event) => onMenuItemClick(event, routeId)} sx={{ ml: 3 }}>
					{startIcon}
				</IconButton>
			</Tooltip>
		)

	return (
		<Button
			onClick={(event) => onMenuItemClick(event, routeId)}
			startIcon={startIcon}
			endIcon={endIcon}
			variant="text"
			sx={{
				bgcolor: getRouteIsActive(routeId, routeUrl) ? colors.feldgrau['80'] : 'transparent',
				'&:hover': {
					bgcolor: colors.feldgrau['80'],
				},
				textTransform: 'none',
				color: 'white',
				p: '10px',
				ml: 2,
				minWidth: 154,
				justifyContent: 'flex-start',
				fontWeight: 'normal',
			}}>
			{routeName}
		</Button>
	)
}

import { Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { TemplateTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import { useCallback } from 'react'

import { Box, Button, Grid, IconButton, Select, Text, TextField } from 'src/components'
import { TrashIcon } from 'src/assets/icons'

import { VARIABLES_TYPES_OPTIONS } from './utils'
import { useRichEditor } from './useRichEditor'

export const VariablesManager = () => {
	const { control, watch } = useFormContext<TemplateTypes.Templates>()
	const { fields, append, remove } = useFieldArray({ control, name: 'variables' })
	const { editor } = useRichEditor()
	const variables = watch('variables')

	const onRemoveVariable = useCallback(
		(variable: TemplateTypes.Variable, index: number) => {
			const variableWithBrackets = `{{${variable.name}}}`
			if (!editor) return null
			const html = editor.getHTML()
			const editedHtml = html.replaceAll(variableWithBrackets, '')
			editor.chain().focus().clearContent().setContent(editedHtml).run()
			remove(index)
		},
		[editor, remove],
	)

	const onChangeVariableName = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, previusValue: string) => {
			if (!editor) return null
			const htmlText = editor.getHTML()
			const previousFormattedValue = `{{${previusValue}}}`
			const newFormattedVariable = `{{${e.target.value}}}`
			if (htmlText.includes(previousFormattedValue)) {
				const editedHtml = htmlText.replaceAll(previousFormattedValue, newFormattedVariable)
				editor.chain().clearContent().setContent(editedHtml).run()
			}
		},
		[editor],
	)
	return (
		<Grid container columnSpacing={4} rowSpacing={2}>
			{variables.map((variable, index) => {
				return (
					<Grid display="flex" item key={variable._id} xs={6} md={3} lg={3}>
						<Stack width="100%" direction="column" spacing={1}>
							<Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
								<Text variant="caption">{`Variable ${index + 1}`}</Text>
								<IconButton
									tooltip="Remove variable"
									size="small"
									onClick={() => onRemoveVariable(variable, index)}
								>
									<TrashIcon width={12} />
								</IconButton>
							</Box>
							<Controller
								control={control}
								name={`variables.${index}.name`}
								render={({ field: { value, onChange } }) => (
									<TextField
										fullWidth
										label="Name"
										value={value}
										onChange={(e) => {
											onChangeVariableName(e, value)
											onChange(e)
										}}
									/>
								)}
							/>
							<Controller
								control={control}
								name={`variables.${index}.type`}
								render={({ field: { value, onChange } }) => (
									<Select
										fullWidth
										label="Type"
										value={value}
										onChange={onChange}
										options={VARIABLES_TYPES_OPTIONS}
									/>
								)}
							/>
						</Stack>
					</Grid>
				)
			})}
			<Grid item xs={12}>
				<Stack direction="row" spacing={1}>
					<Button
						startIcon={<AddIcon />}
						onClick={() =>
							append({
								_id: v4(),
								name: `Variable${fields.length + 1}`,
								type: TemplateTypes.VariableType.text,
							})
						}
					>
						Add Variable
					</Button>
					<Button variant="outlined" startIcon={<TrashIcon />} onClick={() => remove()}>
						Remove All
					</Button>
				</Stack>
			</Grid>
		</Grid>
	)
}

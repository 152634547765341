import { ComponentType } from 'react'
import { V3ClientTypes } from '@cango-app/types'

import { ChevronRight } from 'src/assets/icons'

import { MultiUseCta } from './multi-use-cta'

type TaskListItemCtaProps = {
	task: V3ClientTypes.Project.Task
}

export const TaskListItemCta: ComponentType<TaskListItemCtaProps> = ({ task }) => {
	if (task.isMultiUse) {
		return <MultiUseCta task={task} />
	}

	return <ChevronRight style={{ marginRight: 8 }} />
}

import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { Box, Drawer, Text } from 'src/components'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import type { RootState } from 'src/store/types'

import { ContactForm } from './contact-form'

type ContactFormProps = {
	open: boolean
	contactId?: string
	setShowContactDrawer: (open: boolean) => void
	setSelectedContact: (contactId: string | undefined) => void
}

const ContactDrawer: ComponentType<ContactFormProps> = ({
	open,
	contactId,
	setShowContactDrawer,
	setSelectedContact,
}) => {
	const contact = useSelector((state: RootState) =>
		contactSelectors.getContactById(state, contactId ? contactId : ''),
	)

	const handleCloseDrawer = () => {
		setSelectedContact(undefined)
		setShowContactDrawer(false)
	}

	return (
		<Drawer open={open} onClose={handleCloseDrawer}>
			<Box width={400} p={2} role="presentation">
				<Text sx={{ mb: 2 }} variant="h4">
					{contact ? contact.name + ' ' + contact.surname : 'New Contact'}
				</Text>
				<ContactForm
					contact={contact}
					closeDrawer={handleCloseDrawer}
					setNewContactId={() => null}
				/>
			</Box>
		</Drawer>
	)
}

export default ContactDrawer

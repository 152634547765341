import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined'
import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined'
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined'
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined'

import { IconButton, Text } from 'src/components'
import { colors } from 'src/theme/colors'

import { useRichEditor } from './useRichEditor'

const ACTIVE_COLOR = colors.feldgrau['80']
const INACTIVE_COLOR = colors.disabled

export const EditorOptions = () => {
	const { editor } = useRichEditor()

	if (!editor) return null
	const editorFocus = () => editor?.chain().focus()
	return (
		<>
			<IconButton onClick={() => editorFocus().toggleHeading({ level: 1 }).run()}>
				<Text
					fontWeight={800}
					color={editor.isActive('heading', { level: 1 }) ? ACTIVE_COLOR : INACTIVE_COLOR}
				>
					H1
				</Text>
			</IconButton>
			<IconButton onClick={() => editorFocus().toggleHeading({ level: 2 }).run()}>
				<Text
					fontWeight={800}
					color={editor.isActive('heading', { level: 2 }) ? ACTIVE_COLOR : INACTIVE_COLOR}
				>
					H2
				</Text>
			</IconButton>
			<IconButton onClick={() => editorFocus().toggleHeading({ level: 3 }).run()}>
				<Text
					fontWeight={800}
					color={editor.isActive('heading', { level: 3 }) ? ACTIVE_COLOR : INACTIVE_COLOR}
				>
					H3
				</Text>
			</IconButton>
			<IconButton onClick={() => editorFocus().toggleHeading({ level: 4 }).run()}>
				<Text
					fontWeight={800}
					color={editor.isActive('heading', { level: 4 }) ? ACTIVE_COLOR : INACTIVE_COLOR}
				>
					H4
				</Text>
			</IconButton>
			<IconButton onClick={() => editorFocus().toggleBold().run()}>
				<FormatBoldIcon color={editor.isActive('bold') ? 'primary' : 'disabled'} />
			</IconButton>
			<IconButton onClick={() => editorFocus().toggleUnderline().run()}>
				<FormatUnderlinedOutlinedIcon
					color={editor.isActive('underline') ? 'primary' : 'disabled'}
				/>
			</IconButton>
			<IconButton onClick={() => editorFocus().toggleHighlight().run()}>
				<StarOutlinedIcon color={editor.isActive('highlight') ? 'primary' : 'disabled'} />
			</IconButton>
			<IconButton onClick={() => editorFocus().setTextAlign('center').run()}>
				<FormatAlignCenterOutlinedIcon
					color={editor.isActive({ textAlign: 'center' }) ? 'primary' : 'disabled'}
				/>
			</IconButton>
			<IconButton onClick={() => editorFocus().setTextAlign('justify').run()}>
				<FormatAlignJustifyOutlinedIcon
					color={editor.isActive({ textAlign: 'justify' }) ? 'primary' : 'disabled'}
				/>
			</IconButton>
			<IconButton onClick={() => editorFocus().setTextAlign('left').run()}>
				<FormatAlignLeftOutlinedIcon
					color={editor.isActive({ textAlign: 'left' }) ? 'primary' : 'disabled'}
				/>
			</IconButton>
			<IconButton onClick={() => editor.chain().focus().setTextAlign('right').run()}>
				<FormatAlignRightOutlinedIcon
					color={editor.isActive({ textAlign: 'right' }) ? 'primary' : 'disabled'}
				/>
			</IconButton>
		</>
	)
}

import React, { ComponentType, useEffect } from 'react'
import values from 'lodash/values'
import keys from 'lodash/keys'
import { useNavigate, useLocation } from 'react-router-dom'

import { Box, Button } from 'src/components'

import { Feedback } from './feedback'
import FAQs from './faqs'

export enum HelpTab {
	Feedback = 'feedback',
	FAQs = 'faqs',
}

export const useQuery = (parameter: string) => {
	const { search } = useLocation()
	const params = React.useMemo(() => new URLSearchParams(search), [search])
	return params.get(parameter) || undefined
}

const getActiveComponent = (tab: HelpTab | null) => {
	switch (tab) {
		case HelpTab.FAQs:
			return <FAQs />
		default:
			return <Feedback />
	}
}

const HelpCenter: ComponentType = () => {
	const navigate = useNavigate()
	const sectionQuery = useQuery('section') as HelpTab | null

	const handleGoToTab = (tab: string) => {
		navigate(`/help?section=${tab}`)
	}

	useEffect(() => {
		if (sectionQuery && !values(HelpTab).includes(sectionQuery)) {
			handleGoToTab(HelpTab.Feedback)
		}
	}, [sectionQuery])

	return (
		<>
			<Box px={3} py={3} pb={1} display="flex" justifyContent="center">
				{keys(HelpTab).map((key) => (
					<Button
						key={key}
						onClick={() => handleGoToTab(HelpTab[key as keyof typeof HelpTab])}
						variant={
							sectionQuery === HelpTab[key as keyof typeof HelpTab] ? 'contained' : 'outlined'
						}
						color="success"
						size="small"
						sx={{ mx: 1 }}>
						{key}
					</Button>
				))}
			</Box>
			<Box mx={3}>{getActiveComponent(sectionQuery)}</Box>
		</>
	)
}

export default HelpCenter

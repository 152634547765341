import { combineReducers } from 'redux'
import persistReducer from 'redux-persist/es/persistReducer'
import session from 'redux-persist/lib/storage/session'
import { createMigrate } from 'redux-persist'

import { migrations } from './migrations'
import { reducer as configReducer } from './modules/config'
import { reducer as notesReducer } from './modules/notes'
import { reducer as authReducer } from './modules/auth'
import { reducer as userReducer } from './modules/user'
import { reducer as rolesReducer } from './modules/roles'
import { reducer as contactReducer } from './modules/contacts'
import { reducer as projectsReducerV3 } from './modules/projects-v3'
import { reducer as persistedConfigReducer } from './modules/persisted-config'
import { reducer as remoteConfigReducer } from './modules/remote-config'
import { reducer as SessionConfigReducer } from './modules/session-config'
import { reducer as persistedFilesReducer } from './modules/persisted-files'
import { CangoReduxModuleName } from './types'

const combinedReducer = {
	[CangoReduxModuleName.CangoConfig]: configReducer,
	[CangoReduxModuleName.CangoUser]: userReducer,
	[CangoReduxModuleName.CangoRoles]: rolesReducer,
	[CangoReduxModuleName.CangoContacts]: contactReducer,
	[CangoReduxModuleName.CangoProjectsV3]: projectsReducerV3,
	[CangoReduxModuleName.CangoNotes]: notesReducer,
	[CangoReduxModuleName.CangoPersistedConfig]: persistedConfigReducer,
	[CangoReduxModuleName.CangoRemoteConfig]: remoteConfigReducer,
	[CangoReduxModuleName.CangoAuth]: authReducer,
	[CangoReduxModuleName.CangoPersistedFiles]: persistedFilesReducer,
}

const persitedCangoSession = persistReducer(
	{
		// need this here because it works before combining reducers
		key: 'session',
		storage: session,
		blacklist: Object.keys(combinedReducer).map((reducerKey) => reducerKey),
		version: 15,
		migrate: createMigrate(migrations, { debug: false }),
	},
	SessionConfigReducer,
)

const rootReducer = combineReducers({
	...combinedReducer,
	cangoSession: persitedCangoSession,
})

export default rootReducer

import { TemplateTypes, TableTypes } from '@cango-app/types'
import { Stack, TextField, Tooltip } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import { Select, Text, Grid, Box } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { TemplatesContext } from 'src/providers/templates-provider'
import { selectors as contactsSelectors } from 'src/store/modules/contacts'
import { ChainContext } from 'src/modules/chains/chain-provider'

import { StepContext } from '../step-provider'
import { getThreads } from '../../utils'

type Props = {
	chainActionId: string
}

export const TemplateButton = ({ chainActionId }: Props) => {
	const { templates, selectedTemplate, setSelectedTemplate } = useContext(TemplatesContext)
	const { nodeMap } = useContext(ChainContext)
	const { table, mappedColumns } = useContext(TableContext)
	const { step } = useContext(StepContext)
	const { control, setValue, watch } = useFormContext()
	const template = watch(`${chainActionId}.template.template`)
	const contactsForSelect = useSelector(contactsSelectors.getContactsForSelect)

	const viewOptions = useMemo(() => {
		if (!table) return []
		return table.views.reduce<{ _id: string; label: string }[]>(
			(acc, { displayMode, _id, name }) => {
				if (displayMode === TableTypes.ViewStyleMode.presentation) {
					return [...acc, { _id, label: name }]
				}
				return acc
			},
			[],
		)
	}, [table])

	useEffect(() => {
		if (template && !selectedTemplate) {
			const foundTemplate = templates.find(({ _id }) => template === _id)
			setSelectedTemplate(foundTemplate)
		}
	}, [template, selectedTemplate, templates])

	const getTemplateVariableField = ({
		name,
		type,
		index,
	}: TemplateTypes.Variable & { index: number }) => {
		switch (type) {
			case TemplateTypes.VariableType.text:
				return (
					<Controller
						control={control}
						name={`${chainActionId}.template.variables.${index}.value`}
						render={({ field: { value, onChange } }) => (
							<>
								<Text fontSize={14} fontWeight={500}>
									{name}
								</Text>
								<TextField
									sx={{
										backgroundColor: 'white',
									}}
									fullWidth
									value={value}
									onChange={onChange}
								/>
							</>
						)}
					/>
				)
			case TemplateTypes.VariableType.view:
				return (
					<Controller
						control={control}
						name={`${chainActionId}.template.variables.${index}.value` as any}
						render={({ field: { value, onChange } }) => (
							<Box position="relative">
								<Select label={name} options={viewOptions} value={value} onChange={onChange} />
								<Box
									sx={{
										position: 'absolute',
										top: 2,
										right: 1,
										padding: 0,
									}}
								>
									<Tooltip title="Only views on presentation mode are choosable">
										<HelpOutlineOutlinedIcon
											sx={{
												fontSize: '14px',
											}}
										/>
									</Tooltip>
								</Box>
							</Box>
						)}
					/>
				)
			case TemplateTypes.VariableType.contacts:
				return (
					<Controller
						control={control}
						name={`${chainActionId}.template.variables.${index}.value` as any}
						render={({ field: { value, onChange } }) => (
							<Select label={name} options={contactsForSelect} value={value} onChange={onChange} />
						)}
					/>
				)
			case TemplateTypes.VariableType.chain: {
				const threads = getThreads({
					nodeId: step?._id ?? '',
					nodeMap,
				})
				const chainOptions = threads.map(({ prefix, _id }) => {
					const column = mappedColumns.get(prefix)
					return {
						_id,
						label: column?.name ?? '',
					}
				})
				return (
					<Controller
						control={control}
						name={`${chainActionId}.template.variables.${index}.value` as any}
						render={({ field: { value, onChange } }) => (
							<Select label={name} options={chainOptions} value={value} onChange={onChange} />
						)}
					/>
				)
			}
		}
	}

	return (
		<>
			<Stack direction="column" spacing={1} flex={1}>
				<Controller
					control={control}
					name={`${chainActionId}.template.template`}
					render={({ field: { value, onChange } }) => (
						<Select
							withNoneOption
							label="Template"
							options={templates.map(({ _id, name }) => ({
								_id,
								label: name,
							}))}
							containerProps={{
								width: '60%',
							}}
							value={value}
							onChange={(e) => {
								const newSelectedTemplate = templates.find(({ _id }) => e.target.value === _id)
								if (newSelectedTemplate) {
									setSelectedTemplate(newSelectedTemplate)
									const variables = newSelectedTemplate.variables.map(({ _id }) => {
										return {
											_id,
											value: '',
										}
									})
									setValue(`${chainActionId}.template.variables`, variables)
									onChange(e)
								}
							}}
						/>
					)}
				/>
				{selectedTemplate && (
					<>
						<Text fontSize={14} fontWeight={500}>
							Variables
						</Text>
						<Grid container alignItems="center" rowGap={2}>
							{selectedTemplate.variables.map((variable, index) => {
								return (
									<Grid item key={variable._id} xs={6} sx={{ paddingRight: 2 }}>
										{getTemplateVariableField({ ...variable, index })}
									</Grid>
								)
							})}
						</Grid>
					</>
				)}
			</Stack>
		</>
	)
}

/* eslint-disable @typescript-eslint/no-empty-function */
import {
	useState,
	useCallback,
	ComponentType,
	createContext,
	useEffect,
	PropsWithChildren,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChainsSdk, StepSdk } from '@cango-app/sdk'
import { V3ClientTypes } from '@cango-app/types'
import { createSelector } from 'reselect'
import { V3Step } from '@cango-app/sdk/lib/blueprints/v3'
import _isString from 'lodash/isString'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { usePrevious } from 'src/hooks/usePrevious'
import { V3BlueprintStep } from 'src/modules/blueprints-v3/types'
import { showSnackbar } from 'src/helpers/snackbarManager'

type HierarchyMap = Map<string, StepSdk.StepWithDepth>

export type StepChildProps = {
	step: V3ClientTypes.Blueprint.Step | undefined
	isLoading: boolean
	isLoadingStep: boolean
	onDelete: (stepId: string) => Promise<void>
	updateStep: (data: StepSdk.UpdateStepRequest) => Promise<V3Step | void>
	hierarchy: HierarchyMap
	parents: StepSdk.StepWithDepth[]
	parentOptions: { _id: string; label: string }[]
	fetchAvailableActionChains: () => Promise<{ _id: string; label: string }[]>
}

export const StepContext = createContext<StepChildProps>({
	step: undefined,
	isLoading: false,
	isLoadingStep: false,
	onDelete: async () => {},
	updateStep: async () => {},
	hierarchy: new Map(),
	parents: [],
	parentOptions: [],
	fetchAvailableActionChains: async () => [],
})
// Memoized selector to calculate the total price
const getParents = createSelector(
	(hierarchy: HierarchyMap) => hierarchy,
	(hierarchy) => [...hierarchy.values()].filter((_step) => _step.depth === 1),
)

const getParentOptions = createSelector(getParents, (parents) =>
	parents.reduce((_acc: { _id: string; label: string }[], _parent) => {
		const options = _parent.options
		if (!options || _isString(options)) {
			return _acc
		}
		return [..._acc, ...options]
	}, []),
)

export const StepProvider: ComponentType<
	PropsWithChildren<{
		stepId: string
		onDeleteStep: (stepId: string) => void
	}>
> = (props) => {
	const dispatch = useDispatch()
	const [isLoadingStep, setIsLoadingStep] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [hierarchy, setHierarchy] = useState<HierarchyMap>(new Map())
	const [step, setStep] = useState<V3BlueprintStep>()
	const previousChainId = usePrevious(props.stepId)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const fetchHierarchy = useCallback(async (stepId: string, chainId?: string) => {
		if (!stepId || !chainId) return
		try {
			setIsLoading(true)
			const response = await StepSdk.getHierarchy(
				import.meta.env.VITE_API as string,
				authHeaders,
				stepId,
				chainId,
			)
			setHierarchy(new Map(response.map(([_id, step]) => [_id, step])))
		} catch (error) {
			errorHandler({ error, message: 'Failed to fetch hierarchy', dispatch })
		} finally {
			setIsLoading(false)
		}
	}, [])

	const fetchAvailableActionChains = useCallback(async () => {
		try {
			return await ChainsSdk.getAvailableChains(
				import.meta.env.VITE_API as string,
				authHeaders,
				props.stepId,
			)
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Failed to fetch available chains' })
			return []
		}
	}, [step?._id])

	const fetchStep = useCallback(async () => {
		try {
			setIsLoadingStep(true)
			const response = await StepSdk.get(
				import.meta.env.VITE_API as string,
				authHeaders,
				props.stepId,
			)
			setStep(response)
			fetchHierarchy(response._id, response.chain_id)
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Chain does not exist' })
		} finally {
			setIsLoadingStep(false)
		}
	}, [props.stepId, authHeaders])

	const handleDeleteStep = useCallback(
		async (stepId: string) => {
			try {
				setStep(undefined)
				await StepSdk.deleteStep(import.meta.env.VITE_API as string, authHeaders, stepId)
				props.onDeleteStep(stepId)
				showSnackbar('Step deleted', { variant: 'success' })
			} catch (error) {
				showSnackbar('Failed to delete step', { variant: 'error' })
			}
		},
		[authHeaders],
	)

	const updateStep = useCallback(
		async (data: StepSdk.UpdateStepRequest) => {
			try {
				setIsLoading(true)
				const step = await StepSdk.update(
					import.meta.env.VITE_API as string,
					authHeaders,
					props.stepId,
					data,
				)
				setStep(step)
				return step
			} catch (error) {
				let messages: string[] = []
				if (
					(error as any).response?.data?.length &&
					(error as any).response.data[0].errors?.issues
				) {
					messages = (error as any).response.data[0].errors?.issues.map((issue: any) => issue.code)
				}
				if (messages.length) {
					messages.forEach((message) => {
						errorHandler({ error, dispatch, message: `Could not update step: ${message}` })
					})
				} else {
					errorHandler({ error, dispatch, message: 'Could not update step' })
				}
			} finally {
				setIsLoading(false)
			}
		},
		[props.stepId, authHeaders],
	)

	useEffect(() => {
		if (props.stepId !== previousChainId) {
			fetchStep()
		}
	}, [props.stepId])

	return (
		<StepContext.Provider
			value={{
				step,
				isLoading,
				isLoadingStep,
				onDelete: handleDeleteStep,
				updateStep,
				hierarchy,
				parents: getParents(hierarchy),
				parentOptions: getParentOptions(hierarchy),
				fetchAvailableActionChains,
			}}
		>
			{props.children}
		</StepContext.Provider>
	)
}

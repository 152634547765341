import { useState } from 'react'
import { CommsSdk } from '@cango-app/sdk'
import { useDispatch, useSelector } from 'react-redux'
import _diff from 'lodash/difference'
import { useIdleTimer } from 'react-idle-timer'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as remoteConfigSelectors } from 'src/store/modules/remote-config'
import { actions as notesActions } from 'src/store/modules/notes'
import { showSnackbar } from 'src/helpers/snackbarManager'

export const useInbox = (): void => {
	const dispatch = useDispatch()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const isInboxEnabled = useSelector(remoteConfigSelectors.isInboxEnabled)
	const [unreadTasks, setUnreadTasks] = useState<string[]>([])

	const fetchUnreadNotes = async () => {
		try {
			const response = await CommsSdk.fetchUnreadTasks({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
			})
			setUnreadTasks((prevUnreadNotes) => {
				const responseTaskIds = response.map(({ taskId }) => taskId)
				const difference = _diff(responseTaskIds, prevUnreadNotes)
				if (difference.length > 0) {
					showSnackbar(`You have ${difference.length} tasks with unread notes`, { variant: 'info' })
				}
				if (difference.length > 0) {
					dispatch(notesActions.setUnreadTasks(response))
				}
				return responseTaskIds
			})
		} catch (error) {
			showSnackbar('Error reading notes', { variant: 'error' })
		}
	}

	useIdleTimer({
		timeout: 10000,
		throttle: 10000,
		onActive: () => fetchUnreadNotes(),
		onAction: () => fetchUnreadNotes(),
		disabled: !isInboxEnabled,
	})
}

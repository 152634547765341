import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts'

import { selectors as remoteConfigSelectors } from 'src/store/modules/remote-config'

export const PrivacyPolicy: ComponentType = () => {
	const privacyPolicy = useSelector(remoteConfigSelectors.getPrivacyPolicy)

	useEffectOnce(() => {
		document.title = `Privacy Policy - Cango`
	})

	if (!privacyPolicy) {
		return null
	}

	return (
		<iframe
			src={`data:application/pdf;base64,${privacyPolicy}`}
			style={{ width: '100%', height: '100vh' }}
		/>
	)
}

import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import {
	selectors as contactsSelectors,
	actions as contactsActions,
} from 'src/store/modules/contacts'

export const useContacts = () => {
	const dispatch = useDispatch()
	const contacts = useSelector(contactsSelectors.getContacts)

	useEffect(() => {
		if (!contacts.length) {
			dispatch(contactsActions.getContacts())
		}
	}, [])

	return contacts
}

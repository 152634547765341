import { TypographyProps } from '@mui/material/Typography'
import React, { ComponentType } from 'react'

import { Text } from 'src/components'

type NameLabelProps = TypographyProps & {
	name: string
	groupingName?: string
	isTitle?: boolean
}

export const NameLabel: ComponentType<NameLabelProps> = ({
	groupingName,
	isTitle,
	name,
	...props
}) => {
	return (
		<Text variant={isTitle ? 'h4' : 'body1'} fontSize={!isTitle ? 14 : undefined} {...props}>
			{groupingName ? (
				<>
					<b>{`[${groupingName}]`}</b>
					{` ${name}`}
				</>
			) : (
				name
			)}
		</Text>
	)
}

import { CredentialResponse } from '@react-oauth/google'
import { AuthSdk, AxiosError } from '@cango-app/sdk'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts'

import { AsyncDispatchType } from 'src/store/types'
import { actions as authActions, selectors as authSelectors } from 'src/store/modules/auth'
import { actions as sessionActions } from 'src/store/modules/session-config'

import { LoginComponent } from './login-component'

enum LoginError {
	General = 1,
	NoAccount = 2,
	GoogleError = 3,
}

export const LoginContainer = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const organisations = useSelector(authSelectors.getOrganisations)
	const loginToken = useSelector(authSelectors.getLoginToken)
	const [error, setError] = useState<LoginError>()
	const [canCreateOrg, setCanCreateOrg] = useState(false)
	const handleGoogleLoginSuccess = async (credentials: CredentialResponse) => {
		if (!credentials.credential) return
		try {
			const response = await AuthSdk.getOrganisations(import.meta.env.VITE_API as string, {
				authToken: credentials.credential,
			})
			dispatch(authActions.setOrganisations(response.organisations))
			dispatch(authActions.setLoginToken(response.loginToken))
			setCanCreateOrg(response.canCreateOrg)
		} catch (error) {
			const axiosMessage = (error as AxiosError<{ message: string }>).response?.data?.message
			if (axiosMessage) {
				if (axiosMessage === AuthSdk.LoginErrors.adminUserNotFound) {
					setError(LoginError.NoAccount)
					return
				} else if (axiosMessage === AuthSdk.LoginErrors.googleLoginFailed) {
					setError(LoginError.GoogleError)
					return
				}
			}
			setError(LoginError.General)
		}
	}

	const handleSelectOrganisation = async (organisationId: string, isManualLogin?: boolean) => {
		if (!loginToken) return
		await dispatch(
			authActions.ssoLogin({
				organisationId,
				loginToken,
				isManualLogin: !!isManualLogin,
			}),
		)
		dispatch(sessionActions.setSelectedOrganisationId(organisationId))
		dispatch(authActions.setLastVisitedOrganisationId(organisationId))
	}

	useEffectOnce(() => {
		document.title = `Welcome to Cango`
		if (organisations.length) {
			dispatch(authActions.endSession())
		}
	})

	return (
		<LoginComponent
			onGoogleLoginSuccess={handleGoogleLoginSuccess}
			onGoogleLoginError={() => setError(LoginError.GoogleError)}
			organisations={organisations}
			onSelectOrganisation={handleSelectOrganisation}
			canCreateOrg={canCreateOrg}
		/>
	)
}

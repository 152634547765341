import { PermissionsTypes } from '@cango-app/types'
import { Stack, Paper } from '@mui/material'
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined'
import capitalize from 'lodash/capitalize'

import { TrashIcon } from 'src/assets/icons'
import { Button, Select, Text, Grid, Box, IconButton } from 'src/components'

const ROUTE_OPTIONS = PermissionsTypes.PERMISSIONS_ROUTES.map((route) => ({
	_id: route,
	label: capitalize(route),
}))

type Props = {
	permissions: PermissionsTypes.Permissions[]
	updatePermission: (
		permissionChanges: Pick<PermissionsTypes.Permissions, '_id' | 'routes'>,
	) => Promise<void>
	deletePermission: (permissionId: string) => Promise<void>
}

export const PermissionsManager = ({ permissions, updatePermission, deletePermission }: Props) => {
	return (
		<Grid container spacing={2}>
			{permissions.map(({ _id, name, routes }) => (
				<Grid item xs={6} md={3} key={_id}>
					<Paper elevation={2}>
						<Stack direction="column" spacing={2} padding={2} borderRadius={2}>
							<Stack direction="row" justifyContent="space-between">
								<Text variant="h5">Level: {name}</Text>
								{permissions.length > 1 && (
									<IconButton onClick={() => deletePermission(_id)}>
										<TrashIcon />
									</IconButton>
								)}
							</Stack>
							<Stack direction="column" spacing={2} alignItems="center">
								<Select
									containerProps={{
										width: '100%',
									}}
									multiple
									label="Routes visibles:"
									options={ROUTE_OPTIONS}
									value={routes}
									onChange={(e) => {
										updatePermission({
											_id,
											routes: e.target.value as string[],
										})
									}}
								/>
								<Box flex={1} display="flex" width="100%" justifyContent="space-between">
									<Button
										sx={{
											minWidth: '45%',
											maxWidth: '170px',
										}}
										startIcon={<ChecklistOutlinedIcon />}
										onClick={() => {
											updatePermission({
												_id,
												routes: PermissionsTypes.PERMISSIONS_ROUTES,
											})
										}}
									>
										Select All
									</Button>
									<Button
										variant="text"
										sx={{
											minWidth: '45%',
											maxWidth: '170px',
										}}
										onClick={() => {
											updatePermission({
												_id,
												routes: [],
											})
										}}
									>
										Clear all
									</Button>
								</Box>
							</Stack>
						</Stack>
					</Paper>
				</Grid>
			))}
		</Grid>
	)
}

/**
 * Cango enumerations library
 */

export enum Context {
	Configure = 'configure',
	Overview = 'overview',
	Workflow = 'workflow',
}

export enum Completion {
	ASAP = 'asap',
	TaskDependant = 'taskdependant',
	MilestoneDependant = 'milestonedependant',
	Date = 'date',
	Recurring = 'recurring',
}

export enum How {
	Call = 'call',
	Email = 'email',
	Invite = 'invite',
	Meeting = 'meeting',
	FileTemplate = 'filetemplate',
	FileUpload = 'fileupload',
	FileView = 'fileview',
	FileEdit = 'fileedit',
	Template = 'template',
	Software = 'software',
	Video = 'video',
	Note = 'note',
}

export enum State {
	Upcoming = 'pending',
	Active = 'active',
	Complete = 'complete',
}

export enum ContactType {
	Individual = 'individual',
	Business = 'business',
}

export enum TrainingPhase {
	Uninitiated = 'uninitiated',
	Initiated = 'initiated',
	Complete = 'complete',
	Verified = 'signedoff',
	Redoing = 'redoing',
}

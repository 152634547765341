import { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { Box } from 'src/components'
import { selectors as notesSelectors } from 'src/store/modules/notes'

import { Note } from './task-chat-note'

export const NotesList: ComponentType = () => {
	const notes = useSelector(notesSelectors.getTaskNotes)

	if (!notes) {
		return null
	}

	return (
		<Box pt={1}>
			{notes.map((note) => {
				return <Note key={note._id} note={note} />
			})}
		</Box>
	)
}

import React, { ComponentType, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import map from 'lodash/map'

import { Box, Select, Text } from 'src/components'

import { HelpTab, useQuery } from '../index'

import { ImportBlueprintFAQ } from './import-blueprints'

enum FaqContentType {
	ImportBlueprint = 'import_blueprint',
}

const faqOptions = [
	{
		_id: FaqContentType.ImportBlueprint,
		label: 'Importing Blueprints',
	},
]

const getFaqContent = (contentName?: FaqContentType | null) => {
	switch (contentName) {
		case FaqContentType.ImportBlueprint:
			return <ImportBlueprintFAQ />
		default:
			return null
	}
}

const FAQsContainer: ComponentType = () => {
	const navigate = useNavigate()
	const faqQuery = useQuery('faq') as FaqContentType

	const handleChangeFaq = (faq: string) => {
		navigate(`/help?section=${HelpTab.FAQs}&faq=${faq}`)
	}

	useEffect(() => {
		if (faqQuery && !map(faqOptions, '_id').includes(faqQuery)) {
			handleChangeFaq('')
		}
	}, [faqQuery])

	return (
		<>
			{!faqQuery && (
				<>
					<Text
						variant="h4"
						fontWeight="bold"
						textAlign="center"
						mt={2}>{`"Asking questions is the first way to begin change."`}</Text>
					<Text variant="overline" textAlign="center" display="block">
						Kubra Sait
					</Text>
				</>
			)}
			<Box display="flex" justifyContent="center" my={2}>
				<Select
					options={faqOptions}
					label="Subject"
					containerProps={{ width: 400 }}
					value={faqQuery}
					onChange={(e) => handleChangeFaq(e.target.value as string)}
				/>
			</Box>
			{getFaqContent(faqQuery)}
		</>
	)
}

export default FAQsContainer

import { useEffect, useState } from 'react'
import { ListItem, Stack, List, ListItemText, Tooltip, ClickAwayListener } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import clipboard from 'clipboardy'

import { Modal, Text } from 'src/components'

type Props = {
	open: boolean
	onClose: () => void
}

export const GoogleDriveHelpModal = ({ onClose, open }: Props) => {
	const [showTooltip, setShowTooltip] = useState(false)

	useEffect(() => {
		if (showTooltip) {
			setTimeout(() => {
				setShowTooltip(false)
			}, 2500)
		}
	}, [showTooltip])

	return (
		<Modal open={open} onClose={onClose}>
			<Stack direction="column" spacing={1} width={800}>
				<Text variant="h5">Why do we need a Google Drive Folder URL?</Text>
				<Text>We require a Google Drive folder to save information about the projects.</Text>
				<Text variant="h5">How to create it and grant access to CANGO?</Text>
				<List sx={{ listStyle: 'decimal', pl: 2 }} disablePadding>
					<ListItem sx={{ display: 'list-item' }} disableGutters>
						<ListItemText
							primary={<Text>Create a folder with your organisation&apos;s name</Text>}
						/>
					</ListItem>
					<ListItem sx={{ display: 'list-item' }} disableGutters>
						<ClickAwayListener onClickAway={() => setShowTooltip(false)}>
							<div>
								<Tooltip
									title="copied!"
									placement="top"
									PopperProps={{
										disablePortal: true,
									}}
									onClose={() => setShowTooltip(false)}
									open={showTooltip}
									disableFocusListener
									disableHoverListener
									disableTouchListener
								>
									<ListItemText
										primary={
											<Text>
												Share access as editor to{'  '}
												<Text
													component="span"
													fontWeight={800}
													onClick={() => {
														clipboard.write('cango-drive@cango-prod-374315.iam.gserviceaccount.com')
														setShowTooltip(true)
													}}
												>
													cango-drive@cango-prod-374315.iam.gserviceaccount.com{' '}
													<Tooltip title="copy to clipboard" placement="top">
														<ContentCopyOutlinedIcon fontSize="inherit" />
													</Tooltip>
												</Text>
											</Text>
										}
									/>
								</Tooltip>
							</div>
						</ClickAwayListener>
					</ListItem>
					<ListItem sx={{ display: 'list-item' }} disableGutters>
						<ListItemText primary={<Text>Copy the url to Google Drive Folder URL input</Text>} />
					</ListItem>
				</List>
			</Stack>
		</Modal>
	)
}

import React, { ComponentType, useCallback, useContext, useState, useMemo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { TablesSdk } from '@cango-app/sdk'
import PulseLoader from 'react-spinners/PulseLoader'
import Popover from '@mui/material/Popover'
import { FormControlLabel, Switch } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { Box, Button, IconButton, Modal, Text, TextField } from 'src/components'
import '../../assets/css/Databases.scss'
import { RootState } from 'src/store/types'
import {
	CircledCheckIcon,
	DatabaseIcon,
	EditPenIcon,
	SettingsCogIcon,
	TrashIcon,
} from 'src/assets/icons'
import { RouteId } from 'src/constants/routes'
import { colors } from 'src/theme/colors'
import { APP_BAR_HEIGHT } from 'src/routing/navigation/desktop-navigation'
import { useKeypress } from 'src/hooks/useKeyPress'
import { TableContext } from 'src/providers/table-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'

import {
	selectors as persistedConfigSelectors,
	actions as persistedConfigActions,
} from '../../store/modules/persisted-config'

import { DeleteTableModal } from './delete-table-modal'

type BrowseListItemProps = {
	table: { _id: string; label: string }
}

const BrowseListItem: ComponentType<BrowseListItemProps> = ({ table: { label, _id: tableId } }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const isDatabaseSelected = location.pathname.includes(tableId)
	const [isEditingTable, setIsEditingTable] = useState(false)
	const [isUpdatingTable, setIsUpdatingTable] = useState(false)
	const [deleteTableId, setDeleteTableId] = useState<string>('')
	const [tableName, setTableName] = useState(label)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { setTableList } = useContext(TableContext)
	const handleDatabaseClick = () => {
		navigate(`/${RouteId.Tables}/${tableId}`)
	}

	useKeypress('Escape', () => setIsEditingTable(false))

	const onUpdateTableName = useCallback(
		async ({ tableName, tableId }: { tableName: string; tableId: string }) => {
			try {
				setIsUpdatingTable(true)
				await TablesSdk.updateTableConfig(
					import.meta.env.VITE_API as string,
					authHeaders,
					tableId,
					{
						name: tableName,
					},
				)
				setTableList((currentTableList) =>
					currentTableList.map((table) => {
						if (table._id === tableId) {
							return {
								...table,
								name: tableName,
							}
						}
						return table
					}),
				)
			} catch (error) {
				showSnackbar('Error updating table name', { variant: 'error' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[authHeaders],
	)

	const onDuplicateTable = useCallback(
		async (tableId: string) => {
			try {
				const {
					_id: duplicatedTableId,
					name,
					type,
				} = await TablesSdk.duplicateTable(import.meta.env.VITE_API as string, authHeaders, tableId)
				setTableList((currentTableList) => [
					...currentTableList,
					{
						_id: duplicatedTableId,
						label: name,
						type,
					},
				])
				navigate(`/${RouteId.Tables}/${duplicatedTableId}`)
			} catch (error) {
				showSnackbar('Error duplicating table', { variant: 'error' })
			}
		},
		[authHeaders, navigate],
	)

	if (isEditingTable) {
		return (
			<>
				<DeleteTableModal
					tableId={deleteTableId}
					onClose={() => {
						setDeleteTableId('')
						setIsEditingTable(false)
					}}
				/>
				<Box mx={2} display="flex" mb={1}>
					<TextField
						value={tableName}
						onChange={(e) => setTableName(e.target.value)}
						fullWidth
						containerProps={{ flex: 1 }}
					/>
					<IconButton
						onClick={(event) => {
							onUpdateTableName({ tableId, tableName })
							setIsEditingTable(false)
							event.stopPropagation()
						}}
						isLoading={isUpdatingTable}
					>
						<CircledCheckIcon />
					</IconButton>
					<IconButton
						disabled={isUpdatingTable}
						onClick={(event) => {
							onDuplicateTable(tableId)
							setIsEditingTable(false)
							event.stopPropagation()
						}}
					>
						<ContentCopyOutlinedIcon fontSize="small" stroke={colors.feldgrau['80']} />
					</IconButton>
					<IconButton
						disabled={isUpdatingTable}
						onClick={(event) => {
							setDeleteTableId(tableId)
							event.stopPropagation()
						}}
					>
						<TrashIcon stroke={colors.feldgrau['80']} />
					</IconButton>
				</Box>
			</>
		)
	}

	return (
		<ListItem sx={{ pt: 0, pb: 1 }}>
			<ListItemButton
				selected={isDatabaseSelected}
				onClick={handleDatabaseClick}
				sx={{ borderRadius: 2 }}
			>
				<ListItemIcon sx={{ minWidth: 40 }}>
					<DatabaseIcon />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ fontSize: 14 }} primary={tableName} />
				<ListItemIcon>
					{isUpdatingTable ? (
						<PulseLoader size={4} />
					) : (
						<IconButton
							onClick={(event) => {
								event.stopPropagation()
								setIsEditingTable(true)
							}}
							sx={{ ml: 1 }}
						>
							<EditPenIcon stroke={colors.feldgrau['60']} width={16} />
						</IconButton>
					)}
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	)
}

type Props = {
	columnWidth: number
}

export const TableMenu: ComponentType<Props> = ({ columnWidth }) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [newTableName, setNewTableName] = useState('')
	const [tableSearch, setTableSearch] = useState('')
	const [settingsAnchor, setSettingsAnchor] = useState<HTMLButtonElement | null>(null)
	const settingsId = settingsAnchor ? 'settings-popover' : undefined
	const isBulkEditEnabled = useSelector(persistedConfigSelectors.getIsBulkEditDatabasesEnabled)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [isCreatingTable, setIsCreatingTable] = useState(false)
	const [showCreateModal, setShowCreateModal] = useState(false)
	const navigate = useNavigate()

	const { isFetchingTableList, tableList, setTableList } = useContext(TableContext)

	const filteredTableList = useMemo(() => {
		return tableList.filter((table) =>
			table.label.toLowerCase().includes(tableSearch.toLowerCase()),
		)
	}, [tableList, tableSearch])

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSettingsAnchor(event.currentTarget)
	}

	const handleSettingsClose = () => {
		setSettingsAnchor(null)
	}

	const handleBulkEditChange = (isChecked: boolean) => {
		dispatch(persistedConfigActions.setIsBulkEditDatabasesEnabled(isChecked))
	}

	const onCreateTable = useCallback(
		async (name: string) => {
			try {
				setIsCreatingTable(true)
				const {
					_id: newTableId,
					name: newTableName,
					type,
				} = await TablesSdk.create(import.meta.env.VITE_API as string, authHeaders, {
					name,
				})
				setTableList((currentTableList) => [
					...currentTableList,
					{
						_id: newTableId,
						label: newTableName,
						type,
					},
				])

				navigate(`/${RouteId.Tables}/${newTableId}`)
			} catch (error) {
				showSnackbar('Error creating table', { variant: 'error' })
			} finally {
				setIsCreatingTable(false)
				setShowCreateModal(false)
			}
		},
		[authHeaders],
	)

	return (
		<>
			<Modal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
				<Box display="flex" flexDirection="column">
					<TextField
						onChange={(e) => setNewTableName(e.target.value)}
						value={newTableName}
						placeholder="New table name"
						sx={{ mb: 2 }}
					/>
					<Button
						onClick={() => {
							onCreateTable(newTableName)
							setNewTableName('')
						}}
						isLoading={isCreatingTable}
					>
						Create Table
					</Button>
				</Box>
			</Modal>
			<Box
				sx={{
					minWidth: columnWidth,
					maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
					overflow: 'auto',
				}}
			>
				<List>
					<ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Button
							onClick={() => setShowCreateModal(true)}
							isLoading={isCreatingTable}
							startIcon={<AddIcon />}
							sx={{ mr: 1 }}
							fullWidth
						>
							New Table
						</Button>
						<IconButton id={settingsId} onClick={handleSettingsClick}>
							<SettingsCogIcon stroke={colors.feldgrau['60']} width={18} />
						</IconButton>
						<Popover
							id={settingsId}
							open={!!settingsAnchor}
							anchorEl={settingsAnchor}
							onClose={handleSettingsClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<Box minWidth={200} px={2}>
								<List>
									<Text variant="overline" fontWeight="bold">
										Database settings
									</Text>
									<ListItem>
										<FormControlLabel
											control={
												<Switch
													size="small"
													checked={isBulkEditEnabled}
													onChange={(event, isChecked) => handleBulkEditChange(isChecked)}
												/>
											}
											label="Bulk edit"
										/>
									</ListItem>
								</List>
							</Box>
						</Popover>
					</ListItem>
					{isFetchingTableList ? (
						<Box display="flex" justifyContent="center" mt={10}>
							<PulseLoader size={6} />
						</Box>
					) : (
						<>
							<ListItem>
								<Text variant="overline" fontWeight="bold">
									Browse
								</Text>
							</ListItem>
							<ListItem>
								<TextField
									containerProps={{
										width: '100%',
									}}
									fullWidth
									label="Search:"
									value={tableSearch}
									onChange={(e) => setTableSearch(e.target.value)}
								/>
							</ListItem>
							{filteredTableList.map((table) => (
								<BrowseListItem key={table._id} table={table} />
							))}
						</>
					)}
				</List>
			</Box>
		</>
	)
}

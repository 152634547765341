import { ClientTypes, TableTypes, V3BlueprintTypes } from '@cango-app/types'
import { useContext, useMemo } from 'react'
import _isArray from 'lodash/isArray'
import { useSelector } from 'react-redux'

import { columnValueGetter, resolveAnyRowCalculations } from '../modules/tables/utils'
import { TableContext } from '../providers/table-provider'
import { selectors as contactSelectors } from '../store/modules/contacts'

export const getListFromTable = (
	columnId: string,
	table: TableTypes.CangoTable,
	mappedColumns: Map<string, TableTypes.Field>,
	mappedContacts: Map<string, ClientTypes.Contact>,
): {
	_id: string
	label: string
}[] => {
	const field = mappedColumns.get(columnId)

	if (!field) {
		return []
	}

	const valueOptions = new Map(
		field.valueOptions.map((option) => [
			option._id,
			{
				_id: option._id,
				label: option.label,
			},
		]),
	)

	return (
		table?.records?.reduce((_reducedOptions: { _id: string; label: string }[], _record) => {
			const resolvedRow = resolveAnyRowCalculations({
				row: _record,
				fields: table?.fields ?? [],
				contacts: mappedContacts,
			})

			const value = columnValueGetter(resolvedRow[columnId], field, valueOptions)

			if (
				field.valueOptions?.length &&
				_reducedOptions.some((_reducedOption) => _reducedOption.label === value)
			) {
				return _reducedOptions
			}

			if (!value) {
				return _reducedOptions
			}

			return [
				..._reducedOptions,
				{
					_id: _record._id,
					label: String(value),
				},
			]
		}, []) ?? []
	)
}

export const useListOptions = (
	options: V3BlueprintTypes.StepOptions,
): { _id: string; label: string }[] => {
	const { table, mappedColumns } = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)

	return useMemo(() => {
		if (!options) {
			return []
		}

		if (_isArray(options)) {
			return options
		}

		if (!table) {
			return []
		}

		return getListFromTable(options, table, mappedColumns, mappedContacts)
	}, [options, table?.records, mappedColumns])
}

import { createTheme } from '@mui/material/styles'

import { typography } from './typography'
import { colors, palette } from './colors'

const theme = createTheme({
	typography,
	palette,
	breakpoints: {
		values: {
			xs: 0,
			sm: 481,
			md: 769,
			lg: 1025,
			mobile: 0,
			tablet: 481,
			laptop: 769,
			desktop: 1025,
		},
	},
	components: {
		MuiChip: {
			variants: [
				{
					props: { variant: 'status' },
					style: {
						px: 1,
						py: 0.25,
						borderRadius: 3,
					},
				},
			],
		},
		MuiAppBar: {
			defaultProps: {
				sx: {
					bgcolor: colors.feldgrau[100],
				},
			},
		},
		MuiBottomNavigation: {
			defaultProps: {
				sx: {
					bgcolor: colors.feldgrau[100],
				},
			},
		},
		MuiBottomNavigationAction: {
			defaultProps: {
				sx: {
					color: '#fff',
					'& .Mui-selected': {
						color: '#fff',
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					marginRight: 12,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '6px',
					height: '44px', // This will set the total height of the outlined input including padding and borders
				},
				input: {
					padding: '12px 26px 12px 12px', // Adjust padding if needed to center the text
					fontSize: '14px',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
					borderRadius: '10px',
					minWidth: '170px',
				},
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: colors.neutral['30'],
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
					fontWeight: 500,
				},
			},
		},
	},
})

export default theme

import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts'

import { selectors as rolesSelectors, actions as rolesActions } from 'src/store/modules/roles'

export const useRoles = () => {
	const dispatch = useDispatch()
	const roles = useSelector(rolesSelectors.getRoles)

	useEffectOnce(() => {
		if (!roles.length) {
			dispatch(rolesActions.getRoles())
		}
	})

	return roles
}

import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { RouteProps, useNavigate } from 'react-router-dom'

import { Box, Button, Text } from 'src/components'
import ConfusedUser from 'src/assets/images/illustrations/confused-user'

import { selectors as userSelectors } from '../store/modules/user'

type Props = RouteProps & {
	routeName: string
}

export const PermissionRoute: ComponentType<Props> = ({ children, routeName }) => {
	const permissions = useSelector(userSelectors.getPermissions)
	const navigate = useNavigate()

	// if (!permissions?.routes.includes(routeName)) {
	// 	return (
	// 		<Box
	// 			flex={1}
	// 			display="flex"
	// 			justifyContent="center"
	// 			alignItems="center"
	// 			flexDirection="column">
	// 			<Box width={400} mt={-4}>
	// 				<ConfusedUser />
	// 			</Box>
	// 			<Text variant="h5" fontWeight="bold" mb={2}>
	// 				Oops! Looks like you should not be here
	// 			</Text>
	// 			<Text>
	// 				{
	// 					"...but don't worry! You can always ask someone on your organisation for higher permissions."
	// 				}
	// 			</Text>
	// 			<Button
	// 				variant="contained"
	// 				onClick={() => navigate('/')}
	// 				sx={{
	// 					marginTop: 2,
	// 				}}>
	// 				Go to My Tasks
	// 			</Button>
	// 		</Box>
	// 	)
	// }

	return <>{children}</>
}

import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, RouteProps } from 'react-router-dom'

import { RouteId } from 'src/constants/routes'

import { selectors as configSelectors } from '../store/modules/config'

export const AuthRoute: ComponentType<RouteProps> = ({ children }) => {
	const organisationId = useSelector(configSelectors.getOrganisationId)

	if (organisationId) {
		return <Navigate to={`/${RouteId.MyTasks}`} />
	}

	return <>{children}</>
}

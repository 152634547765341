import { TemplateTypes } from '@cango-app/types'

export const VARIABLES_TYPES_OPTIONS: { _id: TemplateTypes.VariableType; label: string }[] = [
	{
		_id: TemplateTypes.VariableType.text,
		label: 'Text',
	},
	{
		_id: TemplateTypes.VariableType.chain,
		label: 'Chain',
	},
	{
		_id: TemplateTypes.VariableType.contacts,
		label: 'Contacts',
	},
	{
		_id: TemplateTypes.VariableType.view,
		label: 'View',
	},
]

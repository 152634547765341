import { TemplateTypes } from '@cango-app/types'
import { Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from 'src/components'
import { TemplatesContext } from 'src/providers/templates-provider'

import { useRichEditor } from './useRichEditor'

export const TemplatesEditorFooter = () => {
	const { selectedTemplate, onUpdateTemplate, isLoadingTemplates, onDeleteTemplate } =
		useContext(TemplatesContext)
	const { editor } = useRichEditor()
	const { handleSubmit } = useFormContext<{ variables: TemplateTypes.Templates['variables'] }>()
	const onSave = async ({ variables }: { variables: TemplateTypes.Templates['variables'] }) => {
		if (!selectedTemplate) return
		if (!editor) return
		await onUpdateTemplate({
			_id: selectedTemplate._id,
			name: selectedTemplate.name,
			content: editor.getHTML() ?? '',
			variables,
		})
	}
	if (!selectedTemplate) return null
	return (
		<Stack direction="row" spacing={2} paddingTop={2}>
			<Button onClick={handleSubmit(onSave)} isLoading={isLoadingTemplates}>
				Save Changes
			</Button>
			<Button
				variant="outlined"
				color="error"
				onClick={() => onDeleteTemplate(selectedTemplate._id)}
			>
				Delete Template
			</Button>
		</Stack>
	)
}

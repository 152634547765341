import { V3BlueprintTypes, V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import { ComponentType, useCallback, useMemo, useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import _orderBy from 'lodash/orderBy'
import { V3ProjectSdk } from '@cango-app/sdk'
import { PulseLoader } from 'react-spinners'

import { Box, Divider, DriveUploadButton, Select, Text } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { colors } from 'src/theme/colors'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { TaskContext } from 'src/providers/task-provider'

import { TaskFiles } from './task-files'
import { CompletationInfo } from './completation-info'

type FileViewProject = Pick<V3ClientTypes.Project.Project, 'name' | 'google_drive_folder_id'>

type FileEditProps = {
	task: V3ClientTypes.Project.Task
	project?: FileViewProject
	onAddFilesToTask?: (data: {
		fileIds: string[]
		taskId: string
		dependencyId: string
		projectId: string
		actionIndex: number
	}) => Promise<void>
}

export type FileViewItemType = {
	stepId: string
	taskName: string
	chain: V3ProjectTypes.ProjectChain | undefined
	tasks: V3ClientTypes.Project.FileFromTask[]
}

export const FileViewItem: ComponentType<{
	item: FileViewItemType
	project: FileViewProject
	onUpdateFileIds: (taskId: string, fileIds: string[], actionIndex: number) => Promise<void>
	isLastItem: boolean
}> = ({ item, onUpdateFileIds, isLastItem }) => {
	const { isLoadingTask } = useContext(TaskContext)
	const [selectedTaskId, setSelectedTaskId] = useState<string>(item.tasks[0]._id)

	const selectedTask = useMemo(() => {
		return item.tasks.find((_task) => _task._id === selectedTaskId)
	}, [selectedTaskId, item])

	const completedAtTime = selectedTask?.lifecycle?.completed_at?.length
		? selectedTask.lifecycle?.completed_at[selectedTask.lifecycle?.completed_at.length - 1]
		: undefined

	const taskLabel = selectedTask?.chain?._id ? (
		<>
			{item.chain?.label.prefix}: <b>{item.chain?.label.selected_option}</b>
		</>
	) : undefined

	const options = useMemo(() => {
		if (item.tasks.length === 1) return []

		return item.tasks.map((_task) => {
			const _taskCompletedTime = _task.lifecycle?.completed_at?.length
				? _task.lifecycle?.completed_at[_task.lifecycle?.completed_at.length - 1]
				: undefined
			return {
				_id: _task._id,
				label: (
					<Box>
						<Text fontWeight="bold" fontSize={12}>
							Iteration {_task.iteration}
						</Text>
						{!!_taskCompletedTime && (
							<Text fontSize={12}>
								{dayjs.unix(_taskCompletedTime).format('MMM DD, YYYY hh:mma')}
							</Text>
						)}
					</Box>
				),
			}
		})
	}, [])

	if (!selectedTask?._id && isLoadingTask) {
		return (
			<Box>
				<PulseLoader size={6} />
			</Box>
		)
	}

	if (!selectedTask?._id) return null

	if (selectedTask.actions.every((_action) => !_action.file_ids.length)) {
		return null
	}

	return (
		<Box key={`add-files-for-${selectedTask._id}`} mb={1}>
			<Box mb={1}>
				<Text fontSize={14}>
					Task: <b>{item.taskName}</b>
				</Text>
				<Text fontSize={14}>{!!selectedTask.chain?._id && <>{taskLabel}</>}</Text>
				{options.length ? (
					<Select
						options={options}
						value={selectedTask._id}
						onChange={(e) => setSelectedTaskId(e.target.value as string)}
						containerProps={{ sx: { mt: 1 } }}
					/>
				) : (
					<>
						{(selectedTask.iteration ?? 0) > 1 && (
							<Text fontSize={14}>
								Iteration: <b>{selectedTask.iteration}</b>
							</Text>
						)}
						<CompletationInfo completedAtTime={completedAtTime} />
					</>
				)}
			</Box>
			{selectedTask.actions.map((_action, actionIndex) => {
				const actionFileIds = _action.file_ids ?? []
				return (
					<Box key={_action._id} mb={1}>
						<TaskFiles action={_action} />
						<DriveUploadButton
							selectedFilesIds={actionFileIds}
							ctaVariant={actionFileIds.length ? 'replaceIcon' : 'button'}
							onFileIdsChange={(fileIds: string[]) =>
								onUpdateFileIds(selectedTask._id, fileIds, actionIndex)
							}
						/>
					</Box>
				)
			})}
			{!isLastItem && <Divider color={colors.neutral['60']} sx={{ my: 2 }} />}
		</Box>
	)
}

export const FileView: ComponentType<FileEditProps> = ({
	task,
	project: _project,
	onAddFilesToTask,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isProjectLoading, setIsProjectLoading] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [project, setProject] = useState<FileViewProject | undefined>(_project)
	const checkFileIndex = task.actions.findIndex(
		(_task) => _task.type === V3BlueprintTypes.ActionEnum.FileView,
	)

	const fileFromTaskReferences = useMemo(() => {
		if (!task.actions.length) return []
		return task.actions.reduce((fileViewItems: FileViewItemType[], _action) => {
			const actionFilesFromTasks = _action.files_from_tasks.reduce(
				(acc: FileViewItemType[], _task, _index) => {
					if (!_task.step?._id) {
						return [
							...acc,
							{
								stepId: `${_task._id}-${_index}-no-step`,
								taskName: _task.name,
								chain: _task.chain,
								tasks: [_task],
							},
						]
					}

					const accIndex = acc.findIndex(
						(accTask) =>
							accTask.stepId === _task.step?._id &&
							(accTask.chain?._id === _task.chain?._id || !accTask.chain?._id),
					)

					const rootAccIndex = fileViewItems.findIndex(
						(_accTask) =>
							_accTask.stepId === _task.step?._id &&
							(_accTask.chain?._id === _task.chain?._id || !_task.chain?._id),
					)

					if (rootAccIndex >= 0) {
						fileViewItems[rootAccIndex].tasks.push(_task)
						fileViewItems[rootAccIndex].tasks = _orderBy(
							acc[rootAccIndex].tasks,
							'iteration',
							'desc',
						)
						return acc
					}

					if (accIndex >= 0) {
						acc[accIndex].tasks.push(_task)
						acc[accIndex].tasks = _orderBy(acc[accIndex].tasks, 'iteration', 'desc')
						return acc
					}

					return [
						...acc,
						{
							stepId: _task.step?._id,
							taskName: _task.name,
							chain: _task.chain,
							tasks: [_task],
						},
					]
				},
				[],
			)
			return [...fileViewItems, ...actionFilesFromTasks]
		}, [])
	}, [task.actions])

	const handleChangeFileIds = async (referencedTaskId: string, fileIds: string[]) => {
		if (onAddFilesToTask) {
			await onAddFilesToTask({
				fileIds,
				taskId: referencedTaskId,
				dependencyId: task._id,
				projectId: task.project_id,
				actionIndex: checkFileIndex,
			})
			return
		}

		await dispatch(
			projectActions.addFileIdsToTask({
				taskId: task._id,
				fileIds,
				projectId: task.project_id,
				actionIndex: checkFileIndex,
			}),
		)
	}

	const fetchProject = useCallback(async () => {
		try {
			setIsProjectLoading(true)
			const response = await V3ProjectSdk.getProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				projectId: task.project_id,
			})

			setProject({
				name: response.project.name,
				google_drive_folder_id: response.project.google_drive_folder_id,
			})
		} catch (error) {
			showSnackbar('Error fetching project', { variant: 'error' })
		} finally {
			setIsProjectLoading(false)
		}
	}, [task.project_id])

	useEffect(() => {
		if (!_project) {
			fetchProject()
		}
	}, [])

	if (isProjectLoading) {
		return (
			<Box>
				<PulseLoader size={4} />
			</Box>
		)
	}

	if (!project) return null

	return (
		<Box>
			{fileFromTaskReferences.map((_task, index) => (
				<FileViewItem
					key={`${_task.stepId}-${_task.chain?._id}-${index}`}
					item={_task}
					project={project}
					onUpdateFileIds={handleChangeFileIds}
					isLastItem={index === fileFromTaskReferences.length - 1}
				/>
			))}
		</Box>
	)
}

import React, { ComponentType, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'

import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import type { RootState } from 'src/store/types'
import { CardMenu } from 'src/components'

const ProjectMenu: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [isLoading, setIsLoading] = useState(false)
	const projectCards = useSelector(projectSelectors.getCards)

	const fetchAllProjects = async () => {
		setIsLoading(true)
		await dispatch(projectActions.getCards())
		setIsLoading(false)
	}

	const handleCreateNewProject = async (blueprintId: string) => {
		const response = await dispatch(
			projectActions.createProject({ blueprintId, roleAssignments: [], contactAssignments: [] }),
		)
		const { requestStatus } = response.meta
		const projectId = response.payload?.projectId
		if (requestStatus === 'fulfilled' && projectId) {
			return {
				requestStatus,
				_id: projectId,
			}
		}
		return { requestStatus }
	}

	useEffect(() => {
		fetchAllProjects()
	}, [])

	return (
		<CardMenu
			cards={projectCards}
			forwardUrlRef="project"
			onCreateNewDocument={handleCreateNewProject}
			isLoading={isLoading}
		/>
	)
}

export default ProjectMenu

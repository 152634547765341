import { ComponentType, PropsWithChildren } from 'react'

import { Box } from 'src/components'
import { colors } from 'src/theme/colors'

type TaskItemWrapperProps = {
	isMultiUse: boolean
	isFirstTask: boolean
	isLastTask: boolean
	onClick?: () => void
	hasIndent?: boolean
}

export const TaskItemWrapper: ComponentType<PropsWithChildren<TaskItemWrapperProps>> = ({
	isFirstTask,
	isLastTask,
	onClick,
	hasIndent,
	children,
	isMultiUse,
}) => {
	const canClickRow = !isMultiUse

	return (
		<Box
			display="flex"
			flexDirection="column"
			py={2}
			borderBottom={!isLastTask && !hasIndent ? '1px solid' : 'none'}
			borderColor={colors.neutral['40']}
			onClick={canClickRow && onClick ? onClick : undefined}
			sx={{
				cursor: canClickRow ? 'pointer' : 'inherit',
				':hover': canClickRow
					? {
							backgroundColor: colors.sunglow['10'],
					  }
					: undefined,
				borderTopLeftRadius: isFirstTask ? '10px' : '0px',
				borderTopRightRadius: isFirstTask ? '10px' : '0px',
				borderBottomLeftRadius: isLastTask ? '10px' : '0px',
				borderBottomRightRadius: isLastTask ? '10px' : '0px',
				pl: hasIndent ? 6 : 0,
			}}>
			{children}
		</Box>
	)
}

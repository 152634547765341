import { ComponentType, useContext } from 'react'
import { useSelector } from 'react-redux'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'
import { CompleteTaskButton } from 'src/modules/my-tasks-v3/components/complete-task-button'

export const CompleteTaskOps: ComponentType = () => {
	const project = useSelector(projectSelectors.getSelectedProject)
	const { task, onCloseDrawer } = useContext(TaskContext)

	if (!task) {
		return null
	}

	if (project?.archived.state) {
		return null
	}

	return <CompleteTaskButton task={task} onPostComplete={onCloseDrawer} />
}

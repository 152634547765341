import { ComponentType, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Button, Checkbox, TextField } from 'src/components'
import { ChainContext } from 'src/modules/chains/chain-provider'

import { StepContext } from '../step-provider'

import { StepFormType } from './step-form-container'

export const ChainExtractionForm: ComponentType<{
	onClose: () => void
	onCloseDrawer: () => void
}> = ({ onClose, onCloseDrawer }) => {
	const { watch } = useFormContext<StepFormType>()
	const { extractChain, nodeDescendantsMap } = useContext(ChainContext)
	const { step } = useContext(StepContext)
	const defaultName = watch('name')
	const [chainName, setChainName] = useState(defaultName)
	const [isExtractingChain, setIsExtractingChain] = useState(false)

	const handleSubmit = async () => {
		if (!step) {
			return
		}
		const allStepDescendants = nodeDescendantsMap.get(step._id)

		if (!allStepDescendants) {
			return
		}
		setIsExtractingChain(true)
		await extractChain({
			firstStepId: step._id,
			newName: step.name,
			onComplete: () => {
				onCloseDrawer()
			},
		})
		setIsExtractingChain(false)
	}

	return (
		<Box>
			<Checkbox label={"Extract this chain into it's own flow"} checked onChange={onClose} />
			<TextField
				label="What is the name of your chain?"
				value={chainName}
				onChange={(e) => setChainName(e.target.value)}
				fullWidth
			/>
			<Button
				color="sunglow"
				onClick={handleSubmit}
				sx={{ mt: 2 }}
				disabled={!chainName}
				isLoading={isExtractingChain}
			>
				Extract chain
			</Button>
		</Box>
	)
}

import { RouteId } from 'src/constants/routes'

export const getRouteName = (path: string) => {
	const routeName = path.split('/')[1]

	switch (routeName) {
		case RouteId.MyTasks:
			return 'My Tasks'
		case RouteId.Blueprints:
			return 'Blueprints'
		case RouteId.Projects:
			return 'Projects'
		case RouteId.Inbox:
			return 'Inbox'
		case RouteId.Contacts:
			return 'Contacts'
		case RouteId.Roles:
			return 'Roles'
		case RouteId.Users:
			return 'Users'
		case RouteId.Help:
			return 'Help'
		case RouteId.Blueprint:
			return 'Blueprint'
		case RouteId.Project:
			return 'Project'
		default:
			return 'Dashboard'
	}
}

import { CangoReduxModuleName } from './types'
import { initialState as persistedConfigInitialState } from './modules/persisted-config/reducer'
import { initialState as authInitialState } from './modules/auth/reducer'

export const migrations = {
	11: (rootState: any) => ({
		...rootState,
		[CangoReduxModuleName.CangoPersistedConfig]: persistedConfigInitialState,
		[CangoReduxModuleName.CangoAuth]: authInitialState,
	}),
	12: (rootState: any) => ({
		...rootState,
		[CangoReduxModuleName.CangoPersistedConfig]: persistedConfigInitialState,
		[CangoReduxModuleName.CangoAuth]: authInitialState,
	}),
	13: (rootState: any) => ({
		...rootState,
		[CangoReduxModuleName.CangoPersistedFiles]: {},
	}),
	14: (rootState: any) => ({
		...rootState,
		[CangoReduxModuleName.CangoPersistedConfig]: persistedConfigInitialState,
	}),
	15: (rootState: any) => {
		return {
			...rootState,
			[CangoReduxModuleName.CangoPersistedFiles]: Object.entries(
				rootState[CangoReduxModuleName.CangoPersistedFiles] || {},
			)
				.map(([key, value]) => [key, { files: value }])
				.reduce((acc, [key, value]) => ({ ...acc, [key as string]: value }), {}),
		}
	},
	17: (rootState: any) => {
		return {
			...rootState,
			[CangoReduxModuleName.CangoAuth]: authInitialState,
		}
	},
}

import { Stack } from '@mui/material'
import { EditorContent } from '@tiptap/react'

import { Box } from 'src/components'

import { EditorTools } from './editor-tools'
import { BubbleEditorMenu } from './bubble-menu'
import { TemplatesEditorFooter } from './templates-editor-footer'
import { useRichEditor } from './useRichEditor'

export const Tiptap = () => {
	const { editor } = useRichEditor()
	return (
		<Stack direction="column">
			<Box flex={1}>
				<EditorTools />
				<EditorContent editor={editor} />
				<BubbleEditorMenu />
				<TemplatesEditorFooter />
			</Box>
		</Stack>
	)
}

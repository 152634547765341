import React, { ComponentType, useContext } from 'react'
import { V3ClientTypes, V3BlueprintTypes } from '@cango-app/types'
import dayjs from 'dayjs'

import { FormCard } from 'src/components'
import { TabPanel } from 'src/components/tab-panel'
import { TaskContext } from 'src/providers/task-provider'

import { TaskDrawerTab } from './task-form-container'

type TaskLifecycleProps = {
	activeTab: number
}

export const renderWhenType = (task: V3ClientTypes.Project.Task) => {
	switch (task.when.type) {
		case V3BlueprintTypes.WhenEnum.ASAP:
			return 'As soon as section starts'
		case V3BlueprintTypes.WhenEnum.Date:
			if (!task.when.date) {
				return 'On a date'
			} else {
				return `On ${dayjs.unix(task.when.date).format('ddd DD MMM') || 'date'}`
			}
		case V3BlueprintTypes.WhenEnum.Milestone:
			return <></>
		case V3BlueprintTypes.WhenEnum.Recurring:
			return `Recurring every ${
				task.when.recurrence && task.when.recurrence?.frequency > 1
					? `${String(task.when.recurrence.frequency)} ${task.when.recurrence.interval}s`
					: task.when.recurrence?.interval || 1
			}`
	}
}

export const TaskLifecycle: ComponentType<TaskLifecycleProps> = ({ activeTab }) => {
	const { task } = useContext(TaskContext)

	if (!task || task.when.type === V3BlueprintTypes.WhenEnum.ASAP) {
		return null
	}

	return (
		<TabPanel index={TaskDrawerTab.Lifecycle} value={activeTab} containerProps={{ pt: 2 }}>
			<FormCard title="When due" sx={{ mt: 3 }}>
				{renderWhenType(task)}
			</FormCard>
		</TabPanel>
	)
}

import { ClientTypes } from '@cango-app/types'
import { ComponentType, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Button, Text } from 'src/components'
import { actions as userActions } from 'src/store/modules/user'
import { AsyncDispatchType } from 'src/store/types'

type DeleteUserProps = {
	user: ClientTypes.User
	closeDrawer: () => void
}

export const DeleteUser: ComponentType<DeleteUserProps> = ({ user, closeDrawer }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteUser = async () => {
		setIsDeleting(true)
		const response = await dispatch(userActions.deleteUser(user._id))
		setIsDeleting(false)
		if (response.meta.requestStatus === 'fulfilled') {
			closeDrawer()
		}
	}

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{user.name} {user.surname}
			</Text>
			<Button onClick={handleDeleteUser} variant="contained" color="error" isLoading={isDeleting}>
				Delete User
			</Button>
		</Box>
	)
}

import { GridFilterModel } from '@mui/x-data-grid-premium'
import { V3BlueprintTypes } from '@cango-app/types'

export enum DependencyType {
	WHEN_THIS_TASK_COMPLETES = 'WHEN_THIS_TASK_COMPLETES',
	A_UNIQUE_CHAIN_FOR_EACH_OPTION_SELECTED = 'A_UNIQUE_CHAIN_FOR_EACH_OPTION_SELECTED',
	CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED = 'CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED',
}

export type DependencyForm = {
	createForEveryOption: boolean
	chainPrefix: string
	databaseLogic: {
		filters: GridFilterModel
		column: string
	} | null
	option_condition: V3BlueprintTypes.StepChild['option_condition']
}

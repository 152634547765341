import React, { ComponentType, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { FeedbackSdk } from '@cango-app/sdk'

import { Box, Button, Text, TextField } from 'src/components'
import { colors } from 'src/theme/colors'
import { showSnackbar } from 'src/helpers/snackbarManager'

type LoginComponentProps = {
	onGoBack: () => void
}

type FormFields = FeedbackSdk.NewLeadFields

export const NewAccountForm: ComponentType<LoginComponentProps> = ({ onGoBack }) => {
	const { handleSubmit, control } = useForm<FormFields>()
	const [isSendingForm, setIsSendingForm] = useState(false)

	const handleFormSubmit = async (data: FormFields) => {
		try {
			setIsSendingForm(true)
			await FeedbackSdk.newLead(import.meta.env.VITE_API as string, data)
			setIsSendingForm(false)
			showSnackbar('Thanks you! Somebody will contact you soon', { variant: 'success' })
			onGoBack()
		} catch (error) {
			showSnackbar('Oops! Something went wrong.', { variant: 'error' })
		}
	}

	return (
		<Box textAlign="center">
			<Text color={colors.neutral['10']} mb={2} textAlign="left">
				Please fill the information and we will be in contact with you to create your account
			</Text>
			<Controller
				control={control}
				name="companyName"
				rules={{
					required: 'Company name is required',
				}}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<TextField
						fullWidth
						value={value}
						error={!!error}
						helperText={error?.message}
						onChange={onChange}
						placeholder="Company name"
						sx={{ mb: 2 }}
					/>
				)}
			/>
			<Controller
				control={control}
				name="contactName"
				rules={{
					required: 'A contact is required',
				}}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<TextField
						fullWidth
						required
						value={value}
						error={!!error}
						helperText={error?.message}
						onChange={onChange}
						placeholder="Contact name"
						sx={{ mb: 2 }}
					/>
				)}
			/>
			<Controller
				control={control}
				name="email"
				rules={{
					required: 'Email is required',
					pattern: {
						value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
						message: 'Invalid email address',
					},
				}}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<TextField
						fullWidth
						value={value}
						error={!!error}
						onChange={onChange}
						placeholder="Email"
						sx={{ mb: 2 }}
						helperText={error?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="phoneNumber"
				rules={{
					required: 'Phone number is required',
					validate: {
						validPhoneNumber: (value) => {
							if (!value) return
							return isValidPhoneNumber(value) || 'Invalid phone number'
						},
					},
				}}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<TextField
						fullWidth
						error={!!error}
						helperText={error?.message || 'Include country code'}
						value={value}
						onChange={onChange}
						placeholder="Phone number"
						sx={{ mb: 2 }}
						FormHelperTextProps={{ sx: { color: colors.neutral['10'] } }}
					/>
				)}
			/>
			<Box display="flex" justifyContent="space-between">
				<Button onClick={onGoBack} variant="text" color="neutral">
					Go back
				</Button>
				<Button
					color="sunglow"
					sx={{ minWidth: 120 }}
					onClick={handleSubmit(handleFormSubmit)}
					isLoading={isSendingForm}
				>
					Send
				</Button>
			</Box>
		</Box>
	)
}

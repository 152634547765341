import React, { ComponentType } from 'react'
import BlockIcon from '@mui/icons-material/Block'
import DoneIcon from '@mui/icons-material/Done'

import { Box, Grid, Text } from 'src/components'

const RequiredTable: ComponentType<{
	isRequired?: boolean
	requiredReason?: string
	shouldOptions?: { label: string; options?: string[] }[]
	subOptions?: string[]
	shouldNotOptions?: string[]
}> = ({ isRequired, shouldOptions = [], shouldNotOptions = [], requiredReason }) => (
	<Grid
		container
		width={700}
		boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;"
		borderRadius="0 20px 20px 20px">
		<Grid xs={12} borderBottom="1px solid rgba(0, 0, 0, 0.1)" py={1}>
			<Text textAlign="center" fontSize={20} fontWeight="bold">
				{isRequired ? 'Required' : 'Not required'}
			</Text>
			{!!requiredReason && <Text textAlign="center">{requiredReason}</Text>}
		</Grid>
		<Grid xs={6} borderRight="1px solid rgba(0, 0, 0, 0.1)" p={1}>
			<Text fontWeight="bold" display="flex" alignItems="center" justifyContent="center" mb={1}>
				<DoneIcon color="success" fontSize="small" />
				Should be:
			</Text>
			<ul>
				{shouldOptions.map(({ label, options }) => (
					<li key={label}>
						{label}
						{!!options?.length && (
							<ul>
								{options.map((option) => (
									<li key={option}>{option}</li>
								))}
							</ul>
						)}
					</li>
				))}
			</ul>
		</Grid>
		<Grid xs={6} p={1}>
			<Text fontWeight="bold" display="flex" alignItems="center" justifyContent="center" mb={1}>
				<BlockIcon color="error" fontSize="small" />
				Should not be:
			</Text>
			<ul>
				{shouldNotOptions.map((option) => (
					<li key={option}>{option}</li>
				))}
			</ul>
		</Grid>
	</Grid>
)

export const ImportBlueprintRules: ComponentType = () => {
	return (
		<>
			<Box mb={8} mt={4}>
				<Text variant="h6" mb={1}>
					Column 1: ID
				</Text>
				<Text mb={1}>Description: A unique identifier for each and every Step.</Text>

				<RequiredTable
					isRequired
					shouldOptions={[
						{ label: 'Completely unique' },
						{ label: '(can be made of numbers and/or letters)' },
					]}
					shouldNotOptions={['Dublicated in another row']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 2: Section
				</Text>
				<Text mb={1}>Description: The name of the section that the Step belongs</Text>

				<RequiredTable
					isRequired
					shouldOptions={[
						{ label: 'This can be named anything.' },
						{
							label:
								'If you want various Steps to be contained in the same section then you must ensure they share exactly the same section name (even letter casing of the word)',
						},
					]}
					shouldNotOptions={['Empty']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 3: Type
				</Text>
				<Text mb={1}>Description: The type of Step this is</Text>

				<RequiredTable
					isRequired
					shouldOptions={[
						{
							label: 'This must be one of the following 4 values',
							options: ['regular', 'required', 'linked', 'milestone'],
						},
						{
							label: `If the type chosen is "linked", then the following column ("Linked To") must be defined`,
						},
					]}
					shouldNotOptions={['Empty', "Be a different type to it's parent, if it's a substep"]}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 4: Options
				</Text>
				<Text
					mb={
						1
					}>{`Description: List of Options attachable to single_use_as_required type Steps`}</Text>
				<RequiredTable
					shouldOptions={[{ label: 'A && delimited list of plaintext options' }]}
					shouldNotOptions={['An ID']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 5: Linked To
				</Text>
				<Text mb={1}>{`Description: A reference to an "As Required" type Step`}</Text>

				<RequiredTable
					isRequired
					requiredReason={`If the Step type is "linked"`}
					shouldOptions={[
						{ label: 'One of the ids from the ID column' },
						{ label: `The referenced Step must have the Type (Column 3) "required"` },
					]}
					shouldNotOptions={[
						'an ID that is its own Step ID',
						"Linked to a different Step than its parent, if it's a substep",
					]}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 6: Branch
				</Text>
				<Text mb={1}>{`Description: Which option a linked Step relates to`}</Text>
				<RequiredTable
					isRequired
					requiredReason={`If the Step is linked to a single_use_as_required type Step that has options`}
					shouldOptions={[
						{
							label:
								'A single plaintext item exactly matching one of the options of the single use as required the Step is linked to',
						},
					]}
					shouldNotOptions={['An ID']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 7: Name
				</Text>
				<Text mb={1}>Description: Name of the Step</Text>

				<RequiredTable isRequired shouldOptions={[{ label: 'Anything goes!' }]} />
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 8: Parent Step
				</Text>
				<Text mb={1}>
					Description: If this is a substep, this identifies which parent it belongs to
				</Text>

				<RequiredTable
					shouldOptions={[{ label: 'One of the ids from the ID column' }]}
					shouldNotOptions={[
						'An ID for a Step that has a different Section name',
						'An ID that is its own Step ID',
					]}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 9: Role
				</Text>
				<Text mb={1}>Description: Who should do this Step?</Text>

				<RequiredTable
					shouldOptions={[
						{
							label: `After a successful validation, you'll be asked to link this role to one you have previously saved in Cango`,
						},
						{
							label:
								'In order to maintain consistency, when writing a role multiple times, try to keep exactly the same style and letter casings',
						},
					]}
					shouldNotOptions={[
						'An ID for a Step that has a different Section name',
						'An ID that is its own Step ID',
					]}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 10: Action Type
				</Text>
				<Text mb={1}>Description: Refers to the sort of work that needs to be done</Text>

				<RequiredTable
					isRequired
					shouldOptions={[
						{
							label: 'This must be one of the following values:',
							options: [
								'call',
								'email',
								'meeting',
								'invite',
								'filetemplate',
								'fileupload',
								'fileview',
								'fileedit',
								'software',
								'video',
								'note',
							],
						},
						{
							label:
								'Should be "note" if the Step is an As Required. This is because when an Instance of an As Required is created, the button itself is not created, it just initiates all the linked Steps.',
						},
					]}
					shouldNotOptions={['Empty']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 11: File from Step
				</Text>
				<Text mb={1}>{'Description: Refers to the ID that a "fileview" Step needs to check'}</Text>

				<RequiredTable
					shouldOptions={[
						{
							label: 'One of the IDs from the ID column.',
						},
						{
							label:
								'The Step it refers to must be either a filetemplate or a fileupload type Step',
						},
					]}
					shouldNotOptions={['Empty (if the "Action Type" of the file is fileview)']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 12: Action Detail
				</Text>
				<Text
					mb={
						1
					}>{`Description: A small detail to help with the Action Type. e.g. if you chose "note" as the Action Type, then this would be the actual note.`}</Text>
				<RequiredTable shouldOptions={[{ label: 'Anything goes!' }]} />
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 13: Training Type
				</Text>
				<Text mb={1}>{`Description: What kind of training is this?`}</Text>

				<RequiredTable
					shouldOptions={[
						{
							label: 'This must be one of the following values:',
							options: ['none', 'in_person', 'online'],
						},
					]}
					shouldNotOptions={['Empty']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 14: Training Detail
				</Text>
				<Text
					mb={
						1
					}>{`Description: Like Action Detail, this is information to support your Training Type. e.g. if you chose "online", this could be the url to the training`}</Text>
				<RequiredTable shouldOptions={[{ label: 'Anything goes!' }]} />
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 15: When Type
				</Text>
				<Text
					mb={
						1
					}>{`Description: Refers to when Tasks of this Step should be done / shown in My Tasks`}</Text>

				<RequiredTable
					isRequired
					shouldOptions={[
						{
							label: 'This must be one of the following values:',
							options: ['asap', 'taskdependant', 'milestonedependant', 'date', 'recurring'],
						},
						{
							label: `If the type chosen is "taskdependant" or "milestonedependant" then the following column ("When Dependency") should be defined`,
						},
					]}
					shouldNotOptions={['Empty']}
				/>
			</Box>

			<Box mb={8}>
				<Text variant="h6" mb={1}>
					Column 16: Blocked By
				</Text>
				<Text
					mb={
						1
					}>{`Description: Refers to the IDs of the blocking Step(s) if the When type defines that it is dependant on the completion of other Step(s)`}</Text>

				<RequiredTable
					isRequired
					requiredReason={`Only if the When Type chosen is "taskdependant" or "milestonedependant"`}
					shouldOptions={[
						{
							label: 'An ID or && delimited list of IDs of another Step',
						},
						{
							label: `The Step it refers to must have a "regular" Step type`,
						},
						{
							label: `The  Step it refers to must be in the same section`,
						},
						{
							label: `If the When Type chosen is "milestonedependant" then the dependency Step type must be "milestone"`,
						},
					]}
					shouldNotOptions={['Depend on an As Required Step as they cannot be completed']}
				/>
			</Box>
		</>
	)
}

import * as React from 'react'

const NewIdea: React.ComponentType = () => (
	<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
		<defs>
			<style>
				{
					'.cls-4,.cls-7,.cls-9{fill:none}.cls-4,.cls-7{stroke-miterlimit:10}.cls-4,.cls-9{stroke-width:5.5px}.cls-2{fill:#353642}.cls-3{fill:#3fbdf1}.cls-4,.cls-7,.cls-9{stroke:#000}.cls-9{stroke-linejoin:round}.cls-6{fill:#fff}.cls-7{stroke-width:7.34px}.cls-8{fill:#d7e8f0}.cls-9{stroke-linecap:square}'
				}
			</style>
		</defs>
		<path
			d="M303.44 309s94-156.51 281.9-95.26C772.94 275 728.61 507.55 728.49 508.16c.82-4.26 33.32-8.32 36.76-8.82 95.87-14.17 167.18 75 174.25 162.88 8.78 109-75.53 186.2-171.55 219.44"
			style={{
				strokeWidth: '5.5px',
				strokeMiterlimit: 10,
				stroke: '#ccdde2',
				fill: 'none',
			}}
		/>
		<path
			className="cls-2"
			d="M750.67 948.5s2.06-5.71 4.9-15.7q-2.87 8.06-6.38 15.7ZM748 714.8c13.84 34.09 21.69 73.34 22.89 112.4.27-35.56-4.82-74.85-20.91-112a214 214 0 0 0-18.46-33.81.44.44 0 0 0-.15-.2c-22.08-33-55.47-62.3-104.79-83.21l-41.19-12s6.52 14.62-2.57 33.54-31.48 42.33-55.27 36.81-49.94-18.19-46-43.88 5.64-34.82 5.64-34.82L346.5 579c15.08 20.29 35.95 66.92-6.21 142.29l26.19-1S349 884.76 388.63 948.54c.28.51.59 1 .9 1.44l223.75-.93h15.55a3.28 3.28 0 0 0 .15-.51h-54.1a23.21 23.21 0 0 1-16.44-6.84 23.16 23.16 0 0 1-5.75-9.52 22.56 22.56 0 0 1-1-6.92 23.36 23.36 0 0 1 23.28-23.32h133.84c.66 0 1.36 0 2 .08a1.08 1.08 0 0 0 .35 0h.2c-.43.08-.86.12-1.29.23H710a15.42 15.42 0 0 0 1.94-.08 29.64 29.64 0 0 0 3.16-.37c.59-.09 1.17-.2 1.75-.33l.61-.11c.74-.16 1.48-.39 2.22-.59.73-.23 1.43-.5 2.13-.77s1.4-.55 2.1-.9a.36.36 0 0 0 .2-.07c.62-.31 1.24-.63 1.82-.94a30.51 30.51 0 0 0 3.58-2.33l1-.81a26.78 26.78 0 0 0 2.52-2.26 29 29 0 0 0 2.1-2.33c.66-.82 1.32-1.63 1.91-2.53.5-.74 1-1.51 1.4-2.29.27-.47.54-1 .77-1.48.08-.11.16-.19.16-.31a11.84 11.84 0 0 0 .54-1.2 29.07 29.07 0 0 0 1.25-3.27c.31-1 .58-2.06.81-3.11.12-.54.23-1.09.31-1.63.16-1 .27-2 .35-3.07 0-.62.08-1.28.08-1.9a33 33 0 0 0-1.48-9.84 11.12 11.12 0 0 0-.5-1.51 11.89 11.89 0 0 0-.59-1.52 33.8 33.8 0 0 0-7-10.41 14.54 14.54 0 0 0-1.13-1.09 27.08 27.08 0 0 0-3.69-2.92c-.43-.27-.85-.58-1.32-.85a31.77 31.77 0 0 0-11.85-4.43 30.13 30.13 0 0 0-4.94-.43l-20.83-.12-30.47-.15H658c-.9-.08-1.79-.12-2.73-.12h-4.58c.31-29.5-5-51.81-12.21-68.4-.02.04 34.58-68.33 109.52-52.98Zm-36.51 233.59c-.89.08-1.78.11-2.68.11h4.24c-3.34 0-2.45-.03-1.56-.11Z"
		/>
		<path
			className="cls-3"
			d="M494 538.51s-3.38 20.52-6.88 39.1c-10 .11-20 .19-29.89.27-5-28.14.43-50.18 6.88-64.87 8.13 18.54 20.41 24.18 29.89 25.5ZM658.36 612.55A265.92 265.92 0 0 0 593.89 588l-7.36-1.27s-5.48-12.46-5.51-12.5c-2.57-6.53-6.83-15.2-8.15-22.08a83.59 83.59 0 0 1-1.16-24.69c1.44-12.24 7.14-38.13 17.44-46.29 8.59-6.76 12.13-5.34 17-15.91 3.5-7.54 4.92-20.21-1.88-25.77-7.54-6.1-10.76-3-19.78-2.06-34.28 3.4-53.87-21.27-66.47-49.78-2-4.63-4.08-9.52-7.35-13.46-3.75-4.54-8.94-5.56-13.19-9.23-1.81-1.58-3.05-4.16-2.18-6.4a8 8 0 0 1 2.18-2.76 70.32 70.32 0 0 1 28.17-15.85 103 103 0 0 1 51-1.59c17.6 5.83 33.42 16.87 44.07 33.74 14.69 23.31 18.23 47.49 10 71.59-8.43 24.68-17.84 53.67-2 77.42 21.09 31.55 33.8 53.74 29.64 91.44Z"
		/>
		<path
			className="cls-4"
			d="M487.16 577.61c-10 .11-20 .19-29.89.27-75.36.39-147.49-.66-223.09 12.9M585.34 586c2.87.63 5.71 1.29 8.55 2a284 284 0 0 1 32.64 10 242.29 242.29 0 0 1 31.83 14.54.06.06 0 0 1 .08 0 208.7 208.7 0 0 1 44.31 32.18 173.3 173.3 0 0 1 28.57 36.49.44.44 0 0 1 .15.2A240.42 240.42 0 0 1 748 714.8c13.84 34.09 21.69 73.34 22.89 112.4 1.09 36.89-3.77 73.62-15.27 105.6q-2.87 8.06-6.38 15.7c-.15.39-.35.74-.5 1.13M628.79 949.13v-.12a3.28 3.28 0 0 0 .15-.51M602.4 724.25s21.76 10.57 36.06 43.57c7.19 16.59 12.52 38.9 12.21 68.4v.08c-.16 18.81-2.64 40.53-8.4 65.56v.14"
		/>
		<path
			className="cls-4"
			d="M190.59 305.31c3.84-14.53 10-29.51 14.63-35.81 7.28-9.8 16.78-19.14 29-18.3 7.85.51 15.56 4.85 15.91 13.45.33 9.33-9.51 19.69-12.67 28.46-3 8.46-4.37 18.31-2.79 27.21s9.57 15.85 18.19 17.76a12.77 12.77 0 0 0 9.67-1.31c8.44-5.34 6.67-19.56 6.33-27.95-.4-9.41-.39-20.05 7.64-26.49 5.9-4.73 14.85-1.59 19.2 3.61 7.56 9 7.93 22.55 8.31 33.63 1.13 33.38-4.8 65.38-24.24 93.48-10 14.51-20.3 27.53-24.35 45.33-12.6 55.32-14 93.67-21.24 149.85"
		/>
		<path
			className="cls-4"
			d="M386.82 950c-38.64-109.82-20.34-229.8-20.34-229.8l-207.56 6.58a61.87 61.87 0 0 1-63.48-68.38L146.28 473l11-39.55a57.31 57.31 0 0 0-2.28-37.64l-5.18-12.36s-9.39-19.15 11.66-17.35c11.32 1 22.55 5.81 32.05 11.85 7.46 4.75 18.84 13.94 15.32 24.13-4.16 12-20.47 15-30.05 8.25"
		/>
		<path className="cls-4" d="M154.48 366s-17.78-40 31-29.65S245.94 400 208.71 394" />
		<path
			className="cls-4"
			d="M170.56 334.06s-15.6-18.95 7.72-28 108 33.69 53.68 75.79M508 537.61a34.54 34.54 0 0 1-13.91.9c-9.52-1.32-21.8-7-29.89-25.5a75 75 0 0 1-4.08-11.7C456 486 454.2 464.7 456.61 435c.47-5.71 1.17-11.51 2.14-17.3a156.05 156.05 0 0 1 7.73-29.53s0-.08.08-.12c8.82-23.32 24.33-43 50.41-50.57a102.67 102.67 0 0 1 59.74.86c17.6 5.83 33.42 16.87 44.07 33.74 14.69 23.31 18.23 47.49 10 71.59-8.43 24.68-17.84 53.67-2 77.42 21.06 31.6 33.77 53.79 29.69 91.49M449.27 579.05c-7.66-36.73 2.68-63.51 10.8-77.74"
		/>
		<path className="cls-4" d="M458.75 417.66s16.77-17.53 36.2 10.16" />
		<path
			d="M501.45 505.93c7.09 4.59 16.69 1.74 21.91-5.33 5.67-7.67 7.94-17.91 9.17-27.2.08-.67.17-1.33.23-2-8.65 13.59-25.44 16.85-40.22 18.27a27.11 27.11 0 0 0 4.81 12.33 15.43 15.43 0 0 0 4.1 3.93Z"
			style={{
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				stroke: '#000',
				strokeWidth: '5.5px',
				fill: 'none',
			}}
		/>
		<path
			className="cls-4"
			d="m578.46 567.11-.04-.04M512.49 412.27s5.7-9.23 25.18-2.43 19.71 17.91 19.71 17.91M468.21 435.22s9-4.55 16.05 0M516.71 439.41s9-4.55 16 0"
		/>
		<path
			className="cls-4"
			d="M494 538.51s-3.38 20.52-6.88 39.1c-.51 2.64-1 5.24-1.44 7.81-2.29 12.51-4.12 23.63-4.2 27-.58 28 25.89 44.15 51.46 43.88 35.88-.39 56.86-33.77 53.48-67.2-2.21-6.1-4.47-12.2-6.68-18.3-2.84-7.82-6.53-15.43-8.13-23.67a80.32 80.32 0 0 1-.46-24.57c1.44-12.2 3.81-30.39 14.11-38.55 8.59-6.76 17.56-11.2 22.46-21.77 3.5-7.54 3.34-17.25-3.46-22.81-7.54-6.1-19.47-5.36-28.49-4.47-58.18 5.95-67.86-52.94-83.6-61.21-12.9-6.81-22.58 8.47-25.61 14.14l-.12.24a.24.24 0 0 1-.07.15c-.55 1-.82 1.67-.82 1.67"
		/>
		<path className="cls-4" d="M492.51 442s-6.08 19.9-5.61 22.88 7.56.9 7.56.9" />
		<ellipse
			className="cls-4"
			cx={522.9}
			cy={445.71}
			rx={23.83}
			ry={24.41}
			transform="rotate(-44.95 522.924 445.708)"
		/>
		<ellipse
			className="cls-4"
			cx={462.12}
			cy={438.32}
			rx={23.83}
			ry={24.41}
			transform="rotate(-44.95 462.14 438.326)"
		/>
		<path
			className="cls-6"
			d="M461.59 419.81a19 19 0 0 1 19 20.81 17.89 17.89 0 0 1-17.88 16.2 19 19 0 0 1-19-20.81 17.89 17.89 0 0 1 17.88-16.2m0-2.76A20.63 20.63 0 0 0 441 435.74a21.86 21.86 0 0 0 19.09 23.68 22.82 22.82 0 0 0 2.6.16 20.63 20.63 0 0 0 20.62-18.69 21.86 21.86 0 0 0-19.09-23.68 22.82 22.82 0 0 0-2.6-.16Z"
		/>
		<path className="cls-4" d="m498.89 442.79-12.76-1.55" />
		<ellipse
			className="cls-3"
			cx={522.9}
			cy={445.71}
			rx={21.13}
			ry={21.64}
			transform="rotate(-44.95 522.924 445.708)"
		/>
		<ellipse
			className="cls-3"
			cx={462.12}
			cy={438.32}
			rx={21.14}
			ry={21.65}
			transform="rotate(-44.95 462.14 438.326)"
		/>
		<path
			className="cls-3"
			d="M267.14 106.16c-29 1.48-53 24.72-55.31 53.64a58.31 58.31 0 0 0 15.24 44.5 57.4 57.4 0 0 0 14 11.23 25.78 25.78 0 0 1 3.85 2.72 56.19 56.19 0 0 0 50.49.08 18.89 18.89 0 0 1 3.77-2.72 59.7 59.7 0 0 0 14.57-11.7 4.14 4.14 0 0 1 .35-.43 58.6 58.6 0 0 0-46.95-97.32Zm36.3 91.61a1 1 0 0 0-.27.31 46.37 46.37 0 0 1-11.27 9 13.9 13.9 0 0 0-2.88 2.1 43.5 43.5 0 0 1-38.94 0 21.34 21.34 0 0 0-3-2.14 45.15 45.15 0 1 1 56.35-9.25Z"
		/>
		<path
			className="cls-2"
			d="M295.39 218.34a22.2 22.2 0 0 0-7.28 16.51v2.68c0 9.36-6.78 17.75-16 18.69a17.94 17.94 0 0 1-19.77-17.78v-3.59a22.4 22.4 0 0 0-7.38-16.58 56.28 56.28 0 0 0 50.47.07Z"
		/>
		<path
			className="cls-4"
			d="M295.39 218.34a22.2 22.2 0 0 0-7.28 16.51v2.68c0 9.36-6.78 17.75-16 18.69a17.94 17.94 0 0 1-19.77-17.78v-3.59a22.4 22.4 0 0 0-7.38-16.58 56.28 56.28 0 0 0 50.47.07Z"
		/>
		<path
			className="cls-4"
			d="M328.78 164.67a58.31 58.31 0 0 1-14.7 38.83 4 4 0 0 0-.33.41 59.56 59.56 0 0 1-14.6 11.71 20 20 0 0 0-3.76 2.72 56.28 56.28 0 0 1-50.47-.07 24.64 24.64 0 0 0-3.86-2.75 58.67 58.67 0 0 1-29.23-55.71 58.57 58.57 0 0 1 116.95 4.86Z"
		/>
		<path
			className="cls-4"
			d="m253.65 224.28-13.42-44.48-3.36-12.6a1.72 1.72 0 0 1 3.21-.86c3 5.3 7.74 11.95 12.46 11.78 7.83-.28 1.4-24.05-5-21.56s5.87 18.2 18.51 20.16 9.18-19.29 0-20.13-2.85 17.06 13.65 20.13 13.42-15.1 8.95-18.73-3.08 17.33 7.28 20.69c3.28 1.07 5.89-1.22 7.17-4.07.74-1.66.36-10.06-1.16-7.47-3.1 5.31-3.71 11.94-5.23 17.8-3.23 12.4-8.17 24.27-11 36.78M165.45 950c7.08-3.32 660 0 660 0"
		/>
		<path
			className="cls-7"
			d="M270.16 85.23 269.58 50M202.97 118l-24.39-25.43M337.6 119.58l24.93-24.9"
		/>
		<path
			className="cls-6"
			d="m711.34 901.94.85.08c-.46.08-1 .16-1.47.27H710l-67.71-.35h-7.65l-113.76-.58h-1.29a.85.85 0 0 1-.31 0s0 0-.07 0a13.65 13.65 0 0 1-2.14-.15 32.45 32.45 0 0 0 16.63-6.91c.75-.63 1.52-1.23 2.22-1.91l.08-.08a4.52 4.52 0 0 0 .46-.43 32.94 32.94 0 0 0 9.72-21.49v-1.83a33 33 0 0 0-26.39-32.18.06.06 0 0 0-.08 0c-.74-.19-1.59-.31-2.37-.43l1.67-.11a5.56 5.56 0 0 1 .82 0h1.32l96.11.5 17.69.08 15.7.08h8.51a32.92 32.92 0 0 1 29.62 32.68 31 31 0 0 1-.35 4.63A32.93 32.93 0 0 1 657 901.87h-1 57.1c-.83.03-1.37.03-1.76.07Z"
		/>
		<path
			className="cls-8"
			d="M558.43 941.68a23.2 23.2 0 0 0 16.48 6.84h-55.78c-10.68 0-16.48-2.6-20.71-6.84a23.37 23.37 0 0 1-6.84-16.48c0-12.78 10.49-23.28 27.55-23.28h55.78a23.28 23.28 0 0 0-16.48 39.76Z"
		/>
		<path
			className="cls-3"
			d="M742.82 869.65c0 .62 0 1.28-.08 1.9-.08 1.05-.19 2.06-.35 3.07-.08.54-.19 1.09-.31 1.63-.23 1-.5 2.1-.81 3.11a29.07 29.07 0 0 1-1.25 3.27 11.84 11.84 0 0 1-.54 1.2c0 .12-.08.2-.16.31-.23.51-.5 1-.77 1.48-.43.78-.9 1.55-1.4 2.29-.59.9-1.25 1.71-1.91 2.53a29 29 0 0 1-2.1 2.33 26.78 26.78 0 0 1-2.52 2.26l-1 .81a30.51 30.51 0 0 1-3.58 2.33c-.58.31-1.2.63-1.82.94a.36.36 0 0 1-.2.07c-.7.35-1.4.63-2.1.9s-1.4.54-2.13.77c-.74.2-1.48.43-2.22.59a35.29 35.29 0 0 1-5.52.81h-1.79c.43-.11.86-.15 1.29-.23a.21.21 0 0 1 .15 0 2.62 2.62 0 0 1-.58-.08 1.27 1.27 0 0 1 .39 0h1.71H656h1a32.8 32.8 0 0 0 19.19-7.51 29.55 29.55 0 0 0 2.34-2.13 22.64 22.64 0 0 0 1.75-1.91 14.67 14.67 0 0 0 1.16-1.47c.23-.28.43-.55.62-.82a13 13 0 0 0 1-1.56 15 15 0 0 0 1.21-2 19.63 19.63 0 0 0 1-2.06c.15-.31.27-.66.47-1 .27-.7.5-1.4.73-2.1.32-1 .63-2.06.86-3.1a13.58 13.58 0 0 0 .31-1.71 1.71 1.71 0 0 0 .08-.43 1.17 1.17 0 0 0 .08-.35c0-.39.11-.74.15-1.13v-.23a22.43 22.43 0 0 0 .2-3.27 9.56 9.56 0 0 0-.08-1.51v-.35a9.23 9.23 0 0 0-.12-1.48 31.16 31.16 0 0 0-1.83-7.89 25.44 25.44 0 0 0-2-4.39 26.9 26.9 0 0 0-2.56-4 10.73 10.73 0 0 0-.82-1.05c-.12-.12-.23-.28-.39-.43a16.12 16.12 0 0 0-1.71-1.91c-.46-.46-1-.93-1.47-1.43s-1.25-1.09-1.91-1.56-1.09-.81-1.67-1.2a20.51 20.51 0 0 0-2.72-1.67 19.4 19.4 0 0 0-2.76-1.33 33.94 33.94 0 0 0-3.38-1.24 6 6 0 0 0-1.21-.31 25.3 25.3 0 0 0-3.26-.7c-.55-.12-1.09-.16-1.67-.23h-.08a.45.45 0 0 1-.23 0h.58l30.47.15 20.83.12a30.13 30.13 0 0 1 4.94.43 31.77 31.77 0 0 1 11.93 4.43c.47.27.89.58 1.32.85a27.08 27.08 0 0 1 3.69 2.92 14.54 14.54 0 0 1 1.13 1.09 33.8 33.8 0 0 1 7 10.41 11.89 11.89 0 0 1 .59 1.52 11.12 11.12 0 0 1 .5 1.51 33 33 0 0 1 1.56 9.74Z"
		/>
		<path
			className="cls-9"
			d="M742.82 869.65c0 .62 0 1.28-.08 1.9-.08 1.05-.19 2.06-.35 3.07-.08.54-.19 1.09-.31 1.63-.23 1-.5 2.1-.81 3.11a29.07 29.07 0 0 1-1.25 3.27 7 7 0 0 1-.58 1.2c0 .12-.08.2-.16.31-.23.51-.5 1-.77 1.48-.43.78-.9 1.55-1.4 2.29-.59.9-1.25 1.71-1.91 2.53a29.54 29.54 0 0 1-2.14 2.33 26.78 26.78 0 0 1-2.52 2.26l-1 .81a30.51 30.51 0 0 1-3.58 2.33c-.58.31-1.2.63-1.82.94a.36.36 0 0 1-.2.07c-.7.35-1.4.63-2.1.9s-1.4.54-2.13.77h0c-.74.2-1.48.43-2.22.59l-.61.11c-.58.13-1.16.24-1.75.33a29.64 29.64 0 0 1-3.16.37 15.42 15.42 0 0 1-1.94.08s-.12 0-.16 0l-67.6-.33-15.93-.08-106.18-.54a11.5 11.5 0 0 1-1.17-.08c-.89 0-1.75-.08-2.64-.15a33.09 33.09 0 0 0 29.11-30.82v-1.67a33 33 0 0 0-26.43-32.38c-.78-.16-1.56-.27-2.37-.39a22.78 22.78 0 0 1 2.33-.15 5.56 5.56 0 0 1 .82 0h.66l106.18.54 24 .12h8.51l51 .27a33.92 33.92 0 0 1 5 .39h0a33.19 33.19 0 0 1 11.93 4.43c.47.27.89.58 1.32.85a34.07 34.07 0 0 1 3.69 2.92h0c.39.35.74.7 1.13 1.09a33.8 33.8 0 0 1 7 10.41l.59 1.52a13.36 13.36 0 0 1 .5 1.51 33 33 0 0 1 1.5 9.86Z"
		/>
		<path
			className="cls-9"
			d="M688.1 869.06a29.83 29.83 0 0 1-.16 3.27v.23c-.08.51-.15 1-.23 1.48a1.71 1.71 0 0 1-.08.43 13.58 13.58 0 0 1-.31 1.71c-.23 1-.54 2.09-.86 3.1-.23.7-.46 1.4-.73 2.1-.2.35-.32.7-.47 1a19.63 19.63 0 0 1-1 2.06 15 15 0 0 1-1.21 2 13 13 0 0 1-1 1.56c-.19.27-.39.54-.62.82a14.67 14.67 0 0 1-1.16 1.47 22.64 22.64 0 0 1-1.75 1.91v0a31.12 31.12 0 0 1-2.34 2.09 32.8 32.8 0 0 1-19.18 7.58H520.31c-1.24 0-2.52-.07-3.81-.19a24.38 24.38 0 0 0 2.49-.43.3.3 0 0 0 .2 0h.07a33.06 33.06 0 0 0 16.64-9l.08-.08a32.86 32.86 0 0 0 9.48-21.88V869a33 33 0 0 0-29-32.5c.86-.11 1.71-.15 2.57-.19a1.83 1.83 0 0 1 .66 0 .06.06 0 0 1 .08 0 2.11 2.11 0 0 1 .5 0h134.98c.94 0 1.83 0 2.73.12h.31a.45.45 0 0 0 .23 0h.16l1.59.23a25.3 25.3 0 0 1 3.26.7c.39.08.82.19 1.21.31a33.94 33.94 0 0 1 3.38 1.24c.93.39 1.87.86 2.76 1.33a25.8 25.8 0 0 1 2.72 1.67c.58.39 1.13.77 1.67 1.2s1.28 1 1.91 1.56 1 1 1.47 1.43c.59.63 1.17 1.25 1.71 1.91.16.15.27.31.39.43l.82 1.05a37.73 37.73 0 0 1 2.56 4 39.64 39.64 0 0 1 2 4.39 34.51 34.51 0 0 1 1.83 7.89c0 .51.08 1 .12 1.48v.35c.02.45.02.96.02 1.46Z"
		/>
		<path
			className="cls-9"
			d="M713.05 948.5H519.11a38.36 38.36 0 0 1-12.75-1.82c-.82-.31-1.59-.62-2.33-1h0c-.74-.39-1.4-.78-2.06-1.17s-1.24-.89-1.83-1.36h0c-.58-.51-1.12-1-1.63-1.52h0a23.16 23.16 0 0 1-5.75-9.52 22.56 22.56 0 0 1-1.05-6.92 20.38 20.38 0 0 1 .16-2.72 19.52 19.52 0 0 1 .81-3.73 19 19 0 0 1 2.49-5.4 16.44 16.44 0 0 1 2.25-3 22.19 22.19 0 0 1 1.68-1.68c4.74-4.23 11.62-6.84 20.13-6.84h193.82-1.71a1.27 1.27 0 0 0-.39 0h-.12a.06.06 0 0 0 .08 0 .66.66 0 0 0 .27 0h.2c-.43.08-.86.12-1.29.23H710h0-.16a23.42 23.42 0 0 0-18.85 22.89 23.48 23.48 0 0 0 20.56 23.17c-.95.36-1.84.39 1.5.39Z"
		/>
		<path
			className="cls-9"
			d="M711.49 948.39c-.89.08-1.78.11-2.68.11H574.88a23.21 23.21 0 0 1-16.44-6.84h0a23.16 23.16 0 0 1-5.75-9.52 22.56 22.56 0 0 1-1-6.92 23.36 23.36 0 0 1 23.28-23.32h133.84c.66 0 1.36 0 2 .08a1.08 1.08 0 0 0 .35 0h.2c-.43.08-.86.12-1.29.23H710h0-.16a23.42 23.42 0 0 0-18.85 22.89 23.48 23.48 0 0 0 20.56 23.17Z"
		/>
		<path
			className="cls-9"
			d="M551.6 925.22a23.28 23.28 0 0 0 23.28 23.28h-55.77c-10.65 0-16.48-2.6-20.72-6.84a23.17 23.17 0 0 1-6.8-16.44c0-12.82 10.46-23.32 27.52-23.32h55.77a23.36 23.36 0 0 0-23.28 23.32ZM713.05 948.5h-4.24c.9 0 1.79 0 2.68-.11-.89.08-1.78.11 1.56.11ZM711.18 902c-.77 0-1.59-.12-2.37-.12h4.24c-2.96.02-2.61.12-1.87.12Z"
		/>
		<path
			d="M130 860.78 60.58 682.94a23.34 23.34 0 0 1 22.64-29H359a27.57 27.57 0 0 1 26.81 21.12l76 177.82a23.34 23.34 0 0 1-22.7 28.79H156.73A27.55 27.55 0 0 1 130 860.78Z"
			style={{
				opacity: 0.54,
				fill: '#fff',
			}}
		/>
		<path
			className="cls-3"
			d="M130 860.78 60.58 682.94a23.34 23.34 0 0 1 22.64-29H359a27.57 27.57 0 0 1 26.81 21.12l76 177.82a23.34 23.34 0 0 1-22.7 28.79H156.73A27.55 27.55 0 0 1 130 860.78Z"
		/>
		<path
			className="cls-9"
			d="M130 860.78 60.58 682.94a23.34 23.34 0 0 1 22.64-29H359a27.57 27.57 0 0 1 26.81 21.12l76 177.82a23.34 23.34 0 0 1-22.7 28.79H156.73A27.55 27.55 0 0 1 130 860.78Z"
		/>
		<path
			className="cls-3"
			d="m140.14 853.58-64-164.07A21.53 21.53 0 0 1 97 662.75h254.38a25.44 25.44 0 0 1 24.73 19.49l70.11 164a21.54 21.54 0 0 1-20.94 26.57H164.82a25.44 25.44 0 0 1-24.68-19.23Z"
		/>
		<path
			className="cls-6"
			d="M333 692.92c-3.37-5.07-8.88-7.32-12.32-5s-3.49 8.25-.12 13.32a16.61 16.61 0 0 0 1.78 2.17l-25 52.77c-4-2.66-8.37-3.32-11.51-1.22a8.57 8.57 0 0 0-3.51 6.35l-17.08-5.77-14.93-5-4.88-1.6a22 22 0 0 0-2.53-5.19c-4.74-7.12-12.48-10.28-17.31-7.07s-4.88 11.42-.27 18.49L196.91 794c-6.2-5.09-13.51-6.66-18.57-3.3-6.88 4.59-7 16.54-.23 26.7.27.4.58.74.86 1.12L160 844.44l-.17.25a6.61 6.61 0 1 0 11 7.3l16.87-25.39c5.32 3.09 11 3.68 15.13.92 6.58-4.38 6.89-15.46 1-25.3l27.25-41.13c4 2.73 8.46 3.43 11.64 1.32a8.25 8.25 0 0 0 3.37-5.3l2 .57 15.19 4.15 19.44 5.24a21.29 21.29 0 0 0 2.9 6.54c4.73 7.11 12.48 10.28 17.31 7.07s4.89-11.58.16-18.69a22.56 22.56 0 0 0-2.34-2.91l23.79-53.79c2.87 2 6 2.45 8.31.94 3.49-2.23 3.54-8.23.15-13.31Z"
		/>
		<path
			className="cls-8"
			d="M152 695.83h-36.85a8.7 8.7 0 0 1-8-5.2 3.88 3.88 0 0 1-.33-1.51 3.82 3.82 0 0 1 3.81-3.78h39.19c-1.82 3.11-.82 7.43 2.18 10.49Z"
		/>
		<path
			className="cls-3"
			d="M175.86 692.05a3.8 3.8 0 0 1-3.79 3.78H152c-3.06-3.06-4-7.38-2.15-10.49h17.7a8.7 8.7 0 0 1 8 5.17 3.9 3.9 0 0 1 .31 1.54Z"
		/>
		<path
			className="cls-6"
			d="M166.64 695.83a5.41 5.41 0 0 1-.36.58c-2.64 3.59-8.34 3.93-12.77.69a12 12 0 0 1-1.49-1.27c-3.06-3.06-4-7.38-2.15-10.49a3.4 3.4 0 0 1 .36-.58c2.61-3.61 8.31-3.92 12.74-.71a11.91 11.91 0 0 1 1.51 1.29c3.04 3.07 3.98 7.35 2.16 10.49Z"
		/>
		<path
			className="cls-3"
			d="M185.13 715.19a3.81 3.81 0 0 1-3.81 3.78h-38.69c1.87-3.14.94-7.42-2.13-10.49h36.34a8.7 8.7 0 0 1 8 5.2 3.88 3.88 0 0 1 .29 1.51Z"
		/>
		<path
			className="cls-8"
			d="M142.63 719h-18.2a8.7 8.7 0 0 1-8-5.17 4 4 0 0 1-.33-1.53 3.77 3.77 0 0 1 3.78-3.79h20.62c3.07 3.04 4 7.32 2.13 10.49Z"
		/>
		<path
			className="cls-6"
			d="M142.63 719a2.45 2.45 0 0 1-.33.58c-2.61 3.62-8.32 3.93-12.75.72A13.71 13.71 0 0 1 128 719c-3-3.06-4-7.35-2.15-10.49a3 3 0 0 1 .36-.57c2.63-3.6 8.33-3.93 12.77-.7a12.86 12.86 0 0 1 1.48 1.27c3.11 3.04 4.04 7.32 2.17 10.49Z"
		/>
		<path
			className="cls-3"
			d="M155.52 742h-23.16a8.73 8.73 0 0 1-8-5.18 3.88 3.88 0 0 1-.33-1.51 3.82 3.82 0 0 1 3.81-3.81h25.48c-1.78 3.09-.84 7.38 2.2 10.5Z"
		/>
		<path
			className="cls-8"
			d="M193.06 738.19a3.78 3.78 0 0 1-3.79 3.76h-33.75c-3-3.07-4-7.36-2.16-10.5h31.41a8.67 8.67 0 0 1 7.95 5.2 3.9 3.9 0 0 1 .34 1.54Z"
		/>
		<path
			className="cls-6"
			d="M170.13 742a3 3 0 0 1-.36.57c-2.61 3.62-8.31 3.93-12.74.72a14.81 14.81 0 0 1-1.51-1.29c-3-3.07-4-7.36-2.16-10.5a4.48 4.48 0 0 1 .36-.57c2.64-3.59 8.34-3.91 12.77-.7a14 14 0 0 1 1.49 1.27c3.02 3.02 4.02 7.33 2.15 10.5Z"
		/>
		<path
			className="cls-3"
			d="M305.27 850.34H282.1a8.73 8.73 0 0 1-8-5.18 3.72 3.72 0 0 1-.34-1.51 3.82 3.82 0 0 1 3.81-3.81h25.49c-1.77 3.16-.83 7.43 2.21 10.5Z"
		/>
		<path
			className="cls-8"
			d="M397.22 846.58a3.77 3.77 0 0 1-3.78 3.76h-88.17c-3-3.07-4-7.36-2.16-10.5h85.82a8.67 8.67 0 0 1 7.95 5.2 3.9 3.9 0 0 1 .34 1.54Z"
		/>
		<path
			className="cls-6"
			d="M319.88 850.34a3 3 0 0 1-.36.57c-2.61 3.62-8.31 3.93-12.74.72a13.8 13.8 0 0 1-1.51-1.29c-3-3.07-4-7.36-2.16-10.5a4.48 4.48 0 0 1 .36-.57c2.64-3.59 8.34-3.91 12.77-.7a14 14 0 0 1 1.49 1.27c3.06 3.07 4 7.38 2.15 10.5Z"
		/>
	</svg>
)

export default NewIdea

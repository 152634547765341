import { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { TableTypes, V3ClientTypes } from '@cango-app/types'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { useSelector } from 'react-redux'

import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { PresentationView } from 'src/components/display-view/presentation-view'

import { TableContext } from '../../../../providers/table-provider'
import { Box, Button, Modal } from '../../../../components'
import { TaskContext } from '../../../../providers/task-provider'
import { usePrevious } from '../../../../hooks/usePrevious'
import { DisplayView } from '../../../../components/display-view'
import { getFilters, resolveAnyRowCalculations } from '../../../tables/utils'

type TableContentProps = {
	action: V3ClientTypes.Project.TaskAction
}

const TableContent: ComponentType<TableContentProps> = ({ action }) => {
	const { task } = useContext(TaskContext)
	const { table, mappedColumns, apiRef } = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const rows = useMemo(() => {
		if (!table) return []
		return table.records.map((_record) => {
			const resolvedRow = resolveAnyRowCalculations({
				fields: table.fields,
				row: _record,
				contacts: mappedContacts,
			})
			// this logic will be improved in ticket https://cango.atlassian.net/jira/software/projects/CANGO/boards/1?selectedIssue=CANGO-1106
			table.fields.forEach(({ type, _id, valueOptions }) => {
				if (
					[
						TableTypes.FieldType.TABLE_SELECT,
						TableTypes.FieldType.SINGLE_SELECT,
						TableTypes.FieldType.REFERENCE,
						TableTypes.FieldType.CONTACT,
					].includes(type)
				) {
					resolvedRow[_id] =
						valueOptions.find((valueOption) => valueOption._id === resolvedRow[_id])?.label ?? ''
				}
			})
			return resolvedRow
		})
	}, [table, mappedContacts])

	const { view, initialState } = useMemo(() => {
		const view = table?.views.find((_view) => _view._id === action.view)
		const initialState: GridInitialStatePremium = {
			filter: {
				filterModel: {
					items: getFilters(table, task?.chain?.database_chain_logic?.filters?.items ?? []),
				},
			},
		}
		return {
			view,
			initialState,
		}
	}, [table?.views, action.view])

	if (!view) {
		return null
	}

	return (
		<Box height={500} width="100%">
			{view.displayMode === TableTypes.ViewStyleMode.presentation ? (
				<PresentationView rows={rows} view={view} columns={[...mappedColumns.values()]} />
			) : (
				<DisplayView
					{...view}
					apiRef={apiRef}
					initialState={initialState}
					datagridProps={{ apiRef, disableColumnFilter: false }}
					filters={task?.chain?.database_chain_logic?.filters ?? { items: [] }}
				/>
			)}
		</Box>
	)
}

export const SetResourcesModal: ComponentType<TableContentProps> = (props) => {
	const { task } = useContext(TaskContext)
	const [isModelOpen, setIsModelOpen] = useState(false)
	const previousTask = usePrevious(task?._id)
	const { isLoadingTable, isUpdatingTable } = useContext(TableContext)

	const handleCloseModal = () => {
		if (isLoadingTable || isUpdatingTable) {
			return
		}
		setIsModelOpen(false)
	}

	useEffect(() => {
		if (!previousTask && task?._id) {
			setIsModelOpen(true)
		}
	}, [task?._id, previousTask])

	return (
		<>
			<Modal
				open={true}
				onClose={handleCloseModal}
				containerStyle={{ maxWidth: '90vw', minWidth: 700, minHeight: 300, p: 4 }}
				// This is to maintain the API Ref active in other components
				sx={{ display: !isModelOpen ? 'none' : undefined }}
			>
				<TableContent {...props} />
			</Modal>
			<Button variant="outlined" onClick={() => setIsModelOpen(true)}>
				Update database fields
			</Button>
		</>
	)
}

export const SetResources = TableContent

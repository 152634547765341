import { V3ClientTypes } from '@cango-app/types'
import { ComponentType } from 'react'
import { v4 } from 'uuid'

import { Box, Text } from 'src/components'
import { IBoxProps } from 'src/components/box'
import { TabPanel } from 'src/components/tab-panel'
import { getTrainingTypeLabel } from 'src/helpers/labels'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'

type TaskTrainingProps = {
	task?: Pick<V3ClientTypes.Project.Task, 'training'>
	activeValue: number
	index: number
	containerProps?: IBoxProps
}

export const TaskTraining: ComponentType<TaskTrainingProps> = ({
	task,
	activeValue,
	index,
	containerProps,
}) => {
	if (!task?.training.length) {
		return null
	}

	return (
		<TabPanel index={index} value={activeValue} containerProps={containerProps}>
			{task.training.map((training, index) => (
				<Box
					key={`${training.type}--${index}`}
					sx={{
						backgroundColor: 'rgba(164, 198, 188, 0.1)',
						p: 2,
						borderRadius: '8px',
						mb: 2,
					}}
				>
					<Text mb={1}>{getTrainingTypeLabel(training.type)}</Text>
					{training.link && <VideoEmbed urls={[{ _id: v4(), link: training.link }]} />}
				</Box>
			))}
		</TabPanel>
	)
}

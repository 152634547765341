import { useState } from 'react'

export const useResizableWidth = (initialWidth: number) => {
	const [columnWidth, setColumnWidth] = useState(initialWidth)
	const handleMouseMove = (e: MouseEvent) => {
		setColumnWidth(e.clientX)
	}

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
	}

	const handleMouseDown = () => {
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	return {
		columnWidth,
		handleMouseDown,
	}
}

import { unix } from 'dayjs'

import { Text } from 'src/components'

type Props = {
	completedAtTime: number | undefined
}

export const CompletationInfo = ({ completedAtTime }: Props) => {
	return (
		<Text fontSize={14}>
			{completedAtTime ? (
				<>
					Completed: <b>{unix(completedAtTime).format('MMM DD, YYYY hh:mma')}</b>
				</>
			) : (
				<b>Incomplete</b>
			)}
		</Text>
	)
}

import React, { ComponentType } from 'react'

import { CsvDocumentIcon } from 'src/assets/icons'
import { Box, Button, Text } from 'src/components'

import { ImportBlueprintRules } from './blueprint-import-rules'

export const ImportBlueprintFAQ: ComponentType = () => {
	return (
		<Box my={4}>
			<Box mb={6}>
				<Text variant="h4" mb={2}>
					1. What type of file can be imported?
				</Text>
				<Text>
					Cango can only import .csv files to be Blueprints, and they must be in the correct format
				</Text>
			</Box>

			<Box mb={6}>
				<Text variant="h4" mb={2}>
					2. Why is importing a .csv so complicated?
				</Text>
				<Text>
					Cango currently has well over 14,000 permutations of a task, and therefore importing a
					.csv cannot just be a bundle of information all thrown together.
				</Text>
				<br />
				<Text>
					Cango requires strict rules when it comes to columns, rows, and most importantly what
					information can be found in each cell depending on the various rules that make up any
					variety of task.
				</Text>
				<br />
				<Text>
					{`For example, one row could be a regular task with a "when" dependency on another regular task. But a task's when dependency cannot be an "as required" task,
          nor can a subtask have a parent task that is in another section, etc etc.`}
				</Text>
				<br />
				<Text>
					{`When the number of permutations of a task are so numerous, rules need to be put into place to make sure your blueprint works without any issues.`}
				</Text>
			</Box>

			<Box mb={6}>
				<Text variant="h4" mb={2}>
					3. What rules do I need to know to create my own .csv file for Cango?
				</Text>
				<Text>
					<b>Download</b> this boilerplate .csv with all the correct columns and input your
					information with the following rules.
				</Text>
				<Box display="flex" my={4}>
					<Button variant="contained">
						<a
							style={{
								display: 'flex',
								alignItems: 'center',
								textDecoration: 'none',
								color: 'white',
							}}
							href="/documents/cango_boilerplate_blueprint.csv"
							download>
							<Box width={50}>
								<CsvDocumentIcon />
							</Box>
							<Text ml={1}>cango_boilerplate_blueprint.csv</Text>
						</a>
					</Button>
				</Box>

				<details>
					<summary>Blueprint Import Rules</summary>
					<ImportBlueprintRules />
				</details>
			</Box>
		</Box>
	)
}

/**
 * Blueprint Builder-Manager
 *
 * @returns ComponentType
 */

import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'

import { RouteId } from 'src/constants/routes'
import { actions as authActions } from 'src/store/modules/auth'
import type { RootState } from 'src/store/types'
import { LoadingDocument } from 'src/components'
import { SwitchOrgState, selectors as configSelectors } from 'src/store/modules/config'
import { ChainProvider } from 'src/modules/chains/chain-provider'

import { ChainFlow } from '../../chains/chain-flow'

export const BlueprintContainer: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [searchParams] = useSearchParams()
	const { blueprintId: id } = useParams<{
		blueprintId: string
	}>()
	const orgId = searchParams.get('orgId')
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const [switchOrgState, setSwitchOrgState] = useState(SwitchOrgState.NotSwitching)
	const [errorFetchingBlueprint, setErrorFetchingBlueprint] = useState('')
	const [blueprintId, setBlueprintId] = useState<string | null>(null)

	const switchOrgAndFetchBlueprint = async () => {
		if (!orgId || !id) {
			setSwitchOrgState(SwitchOrgState.NoOrgId)
			return
		}
		setSwitchOrgState(SwitchOrgState.Switching)
		const response = await dispatch(authActions.switchOrganisation({ organisationId: orgId }))
		if (response.meta.requestStatus === 'rejected') {
			setSwitchOrgState(SwitchOrgState.Failed)
			setErrorFetchingBlueprint(`Failed to switch organisation to ${orgId}`)
			return
		}
		setSwitchOrgState(SwitchOrgState.NotSwitching)
		setBlueprintId(id)
	}

	const loadingText = useMemo(() => {
		switch (switchOrgState) {
			case SwitchOrgState.Switching:
				return `Switching organisation to ${orgId}`
			case SwitchOrgState.Failed:
				return 'Failed to switch organisation'
			case SwitchOrgState.NoOrgId:
				return 'No organisation ID provided'
		}

		if (errorFetchingBlueprint) {
			return ''
		}

		return ''
	}, [switchOrgState, errorFetchingBlueprint])

	useEffect(() => {
		if (orgId !== organisationId) {
			switchOrgAndFetchBlueprint()
		}
	}, [orgId, organisationId])

	useEffect(() => {
		if (orgId === organisationId && id) {
			setBlueprintId(id)
		}
	}, [])

	if (!blueprintId) {
		return (
			<LoadingDocument
				errorText={errorFetchingBlueprint}
				docType="Blueprint"
				returnToRoute={RouteId.Blueprints}
				customText={loadingText}
			/>
		)
	}

	return (
		<ChainProvider chainId={blueprintId}>
			<ChainFlow />
		</ChainProvider>
	)
}

import { ComponentType } from 'react'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import { useSelector } from 'react-redux'

import { InboxNavIcon } from 'src/assets/icons'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import { selectors as remoteConfigSelectors } from 'src/store/modules/remote-config'

type InboxNavProps = {
	onClick: () => void
}

export const InboxNav: ComponentType<InboxNavProps> = ({ onClick }) => {
	const isInboxEnabled = useSelector(remoteConfigSelectors.isInboxEnabled)
	const unreadTasks = useSelector(notesSelectors.getTasksWithUnreadNotes)

	if (!isInboxEnabled) {
		return null
	}

	return (
		<IconButton onClick={onClick}>
			<Badge color="error" badgeContent={unreadTasks.length}>
				<InboxNavIcon width={24} fill="#fff" />
			</Badge>
		</IconButton>
	)
}

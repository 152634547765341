import React, { ComponentType, useContext, useMemo } from 'react'
import { V3ClientTypes } from '@cango-app/types'

import { AttachedFile, Box } from 'src/components'
import { DriveFilesContext } from 'src/providers'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

import { TaskFileHistory } from './file-history'

type TaskFilesProps = {
	action: V3ClientTypes.Project.TaskAction
	hideHistory?: boolean
}

export const TaskFiles: ComponentType<TaskFilesProps> = ({ action, hideHistory = false }) => {
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const { allFiles } = useParentFolderFiles(parentFolderId as string, parentFolderName as string)

	const historicalFiles =
		action.file_history?.filter((_history) => !action.file_ids.includes(_history)) ?? []

	const allFileIds = action.file_ids ?? []

	const mappedFiles = useMemo(() => {
		return new Map(
			allFileIds.map((_fileId) => [_fileId, allFiles.find((file) => file.id === _fileId)]),
		)
	}, [allFiles, allFileIds])

	return (
		<>
			{!!allFileIds.length && (
				<Box>
					<Box display="flex" flexWrap="wrap">
						{allFileIds.map((fileId) => (
							<AttachedFile
								key={fileId}
								fileId={fileId}
								containerStyles={{ mb: 0.5, mr: 1 }}
								_file={mappedFiles.get(fileId)}
								parentFolderId={parentFolderId}
								parentFolderName={parentFolderName}
							/>
						))}
					</Box>
				</Box>
			)}
			{!!historicalFiles.length && !hideHistory && (
				<TaskFileHistory file_history={historicalFiles} />
			)}
		</>
	)
}

import { FirebaseDirectory } from '@cango-app/sdk'
import { useState, useCallback } from 'react'
import { onValue, ref, off, DatabaseReference, set, onDisconnect, remove } from 'firebase/database'
import { useSelector } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts'

import { firebaseDatabase } from 'src/helpers/firebase'
import { selectors as configSelectors } from 'src/store/modules/config'
import { selectors as userSelectors } from 'src/store/modules/user'

interface ListenerProps {
	reference: string
	taskId: string
	cleanReference?: string
	errorHandler?: (error: Error) => void
}

interface NewNoteListener extends ListenerProps {
	directory: FirebaseDirectory.LatestNote
	handler: (data: { taskId: string; data: number }) => void
}

interface ParticipantsListener extends ListenerProps {
	directory: FirebaseDirectory.NoteParticiants
	handler: (data: { taskId: string; data: { [userId: string]: boolean } }) => void
}

export type AnyListener = NewNoteListener | ParticipantsListener

export const useFirebaseRealtimeChatMeta = () => {
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const [unsubscribeFunctions, setUnsubscribeFunctions] = useState<(() => void)[]>([])

	const logUserEntry = useCallback(
		(taskId: string) => {
			const presenceRef = ref(
				firebaseDatabase,
				`${import.meta.env.VITE_ENVIRONMENT}/${organisationId}/${
					FirebaseDirectory.NoteParticiants
				}/${taskId}/${userId}`,
			)
			set(presenceRef, true)
			onDisconnect(presenceRef).set(false)
			return () => {
				remove(presenceRef)
				onDisconnect(presenceRef).cancel()
			}
		},
		[organisationId, userId],
	)

	const addListeners = useCallback(
		(newListeners: AnyListener[]) => {
			const newUnsubscribeFunctions: (() => void)[] = []

			newListeners.forEach((listener) => {
				const firebaseRef = ref(
					firebaseDatabase,
					`${import.meta.env.VITE_ENVIRONMENT}/${organisationId}/${listener.directory}/${
						listener.taskId
					}`,
				)
				const onValueCallback = onValue(
					firebaseRef,
					(snapshot) => {
						const typedData = snapshot.val()
						listener.handler({ taskId: listener.taskId, data: typedData })
					},
					(error) => {
						if (listener.errorHandler) {
							listener.errorHandler(error)
						}
					},
				)
				newUnsubscribeFunctions.push(() => off(firebaseRef, 'value', onValueCallback))
			})

			setUnsubscribeFunctions((prevUnsubs) => [...prevUnsubs, ...newUnsubscribeFunctions])
		},
		[organisationId],
	)

	const switchReferences = useCallback(
		(updatedListeners: AnyListener[]) => {
			if (unsubscribeFunctions.length) {
				unsubscribeFunctions.forEach((unsub) => unsub())
				setUnsubscribeFunctions([])
			}

			addListeners(updatedListeners)
		},
		[unsubscribeFunctions, addListeners],
	)

	const logUserExit = useCallback((userPresenceRef: DatabaseReference | null) => {
		if (userPresenceRef) {
			remove(userPresenceRef)
			onDisconnect(userPresenceRef).cancel()
		}
	}, [])

	useEffectOnce(() => {
		return () => {
			unsubscribeFunctions.forEach((unsub) => unsub())
		}
	})

	return {
		logUserEntry,
		switchReferences,
		logUserExit,
	}
}

import React, { ComponentType, useMemo, useState } from 'react'
import { V3ClientTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'

import { ChainList } from 'src/components/section-tasks-v3/chains-list'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { Button, Modal, Select, TextField, Box, Grid, Text } from 'src/components'

type SectionTitleBarProps = {
	sectionName: string
	rowCount?: number
	chain: V3ClientTypes.Project.Task['chain']
}

const NewChainModal: ComponentType<{
	selectedChainId: string | undefined
	onClose: () => void
	currentChainId: string | undefined
}> = ({ selectedChainId, onClose, currentChainId }) => {
	const dispatch = useDispatch()
	const project = useSelector(projectSelectors.getSelectedProject)
	const projectWideChains = useSelector(projectSelectors.getProjectWideChains)
	const [instanceName, setInstanceName] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const selectedChain = useMemo(() => {
		return projectWideChains.find((chain) => chain._id === selectedChainId)
	}, [selectedChainId])

	const handleCreateChain = async () => {
		if (!project || !selectedChainId) return
		setIsLoading(true)
		await dispatch(
			projectActions.createOnDemandChain({
				projectId: project._id,
				newChainId: selectedChainId,
				chainId: currentChainId,
				instanceName,
			}),
		)
		setIsLoading(false)
		onClose()
	}

	return (
		<Modal open={!!selectedChain} onClose={onClose}>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Text mb={2}>{selectedChain?.label}</Text>
				<TextField
					value={instanceName}
					onChange={(event) => setInstanceName(event.target.value)}
					label="New chain name"
					sx={{ marginBottom: 2 }}
					containerProps={{ width: 300 }}
					fullWidth
					helperText="This chain will inherit all the parent chains from where it is selected"
				/>
				<Button
					disabled={!instanceName}
					sx={{ mb: 2 }}
					onClick={handleCreateChain}
					isLoading={isLoading}
				>
					Create chain
				</Button>
			</Box>
		</Modal>
	)
}

export const SectionTitleBar: ComponentType<SectionTitleBarProps> = ({
	sectionName,
	rowCount,
	chain,
}) => {
	const projectWideChains = useSelector(projectSelectors.getProjectWideChains)
	const [selectedAsRequiredChain, setSelectedAsRequiredChain] = useState<string | undefined>()

	return (
		<>
			<NewChainModal
				selectedChainId={selectedAsRequiredChain}
				onClose={() => setSelectedAsRequiredChain(undefined)}
				currentChainId={chain?._id}
			/>
			<Grid container mb={1}>
				<Grid xs={6}>
					<Box display="flex" alignItems="flex-start" minHeight="50px" flexDirection="column">
						<Text variant="h5" fontWeight={500}>
							{sectionName}
						</Text>
						{!!chain && <ChainList chain={chain} />}
					</Box>

					{!!rowCount && (
						<Text fontSize={14} variant="overline" lineHeight={1}>{`${rowCount} task${
							rowCount !== 0 ? 's' : ''
						}`}</Text>
					)}
				</Grid>
				<Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center">
					{!!projectWideChains.length && (
						<Select
							options={projectWideChains ?? []}
							containerProps={{ width: 200 }}
							label="On-demand chains"
							value=""
							onChange={(event) => setSelectedAsRequiredChain(event.target.value as string)}
						/>
					)}
				</Grid>
			</Grid>
		</>
	)
}

import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import {
	ButtonGroup,
	Button,
	Popper,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
} from '@mui/material'
import { useState, useRef } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { TemplateTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { colors } from 'src/theme/colors'
import { ChevronDown } from 'src/assets/icons'

import { useRichEditor } from './useRichEditor'

export const BubbleEditorMenu = () => {
	const [isOpen, setIsOpen] = useState(false)
	const anchorRef = useRef<HTMLDivElement>(null)
	const { editor } = useRichEditor()

	const { control } = useFormContext<TemplateTypes.Templates>()
	const { append } = useFieldArray({ control, name: 'variables' })

	if (!editor) return null

	const convertTextToVariable = (type: TemplateTypes.VariableType) => {
		editor
			.chain()
			.command(({ tr, state }) => {
				const { $from, $to } = tr.selection
				const content = state.doc.textBetween($from.pos, $to.pos)
				append({
					_id: v4(),
					name: content,
					type,
				})
				tr.insertText('{{', $from.pos) && tr.insertText('}}', $to.pos + 2)
				return true
			})
			.run()
	}
	return (
		<>
			<BubbleMenu editor={editor}>
				<ButtonGroup ref={anchorRef} variant="contained" size="small">
					<Button
						aria-expanded={isOpen ? 'true' : undefined}
						aria-controls={isOpen ? 'adding-variable-menu' : undefined}
						aria-haspopup="menu"
						onClick={() => convertTextToVariable(TemplateTypes.VariableType.text)}
					>
						Convert to text variable
					</Button>
					<Button onClick={() => setIsOpen(true)}>
						<ChevronDown stroke={colors.neutral[0]} fill={colors.neutral[0]} />
					</Button>
				</ButtonGroup>
			</BubbleMenu>
			<Popper
				sx={{ zIndex: 1 }}
				open={isOpen}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={() => setIsOpen(false)}>
								<MenuList id="adding-variable-menu" autoFocusItem onClick={() => setIsOpen(false)}>
									<MenuItem onClick={() => convertTextToVariable(TemplateTypes.VariableType.text)}>
										Convert to text variable
									</MenuItem>
									<MenuItem onClick={() => convertTextToVariable(TemplateTypes.VariableType.view)}>
										Convert to view variable
									</MenuItem>
									<MenuItem onClick={() => convertTextToVariable(TemplateTypes.VariableType.chain)}>
										Convert to single select variable
									</MenuItem>
									<MenuItem
										onClick={() => convertTextToVariable(TemplateTypes.VariableType.contacts)}
									>
										Convert to contacts variable
									</MenuItem>
									<MenuItem
										onClick={() => {
											editor
												.chain()
												.command(({ tr }) => {
													const { $from } = tr.selection
													tr.deleteRange($from.pos - 2, $from.pos)
													return true
												})
												.command(({ tr }) => {
													const { $to } = tr.selection
													tr.deleteRange($to.pos, $to.pos + 2)
													return true
												})
												.run()
										}}
									>
										Remove variable
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	)
}

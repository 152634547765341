import { ComponentType, useState } from 'react'

import { Box, Button } from 'src/components'

type ArchiveButtonsProps = {
	onArchiveProject: () => Promise<void>
	onCompleteTask: () => Promise<void>
}

export const ArchiveButtons: ComponentType<ArchiveButtonsProps> = ({
	onArchiveProject,
	onCompleteTask,
}) => {
	const [isCompleting, setIsCompleting] = useState(false)
	const [isArchiving, setIsArchiving] = useState(false)

	const handleCompleteTask = async () => {
		setIsCompleting(true)
		await onCompleteTask()
		setIsCompleting(false)
	}

	const handleArchiveProject = async () => {
		setIsArchiving(true)
		await onArchiveProject()
		setIsArchiving(false)
	}

	return (
		<Box display="flex">
			<Button
				variant="contained"
				onClick={handleCompleteTask}
				isLoading={isCompleting}
				sx={{ mr: 2 }}>
				Continue with project
			</Button>
			<Button variant="text" onClick={handleArchiveProject} isLoading={isArchiving} color="warning">
				Archive project
			</Button>
		</Box>
	)
}

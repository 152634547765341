import { Box, Divider } from 'src/components'
import { useResizableWidth } from 'src/hooks/useResizableWidth'
import { TemplatesProvider } from 'src/providers/templates-provider'

import { TemplatesMenu } from './templates-menu'
import { TemplatesEditorContainer } from './templates-editor-container'

export const TemplatesContainer = () => {
	const { columnWidth, handleMouseDown } = useResizableWidth(350)

	return (
		<TemplatesProvider>
			<Box flex={1} display="flex">
				<Box width={columnWidth}>
					<TemplatesMenu columnWidth={columnWidth} />
				</Box>
				<Divider
					orientation="vertical"
					flexItem
					sx={{ borderRightWidth: 4, mr: 2, cursor: 'col-resize' }}
					onMouseDown={handleMouseDown}
				/>
				<TemplatesEditorContainer />
			</Box>
		</TemplatesProvider>
	)
}

import { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import {
	selectors as configSelectors,
	actions as configActions,
	UniversalDrawerType,
} from 'src/store/modules/config'
import { selectors as authSelectors, actions as authActions } from 'src/store/modules/auth'
import { colors } from 'src/theme/colors'
import { CangoLogo, CangoLogoText, LogoutIcon, MyTasksIcon, ProjectsIcon } from 'src/assets/icons'
import { Divider, Drawer, Text } from 'src/components'

import { Box } from '../../components/box'

export const MobileNavigation: ComponentType = () => {
	const dispatch = useDispatch()
	const isMenuOpen = useSelector(configSelectors.getIsMobileMenuOpen)
	const selectedOrganisationName = useSelector(authSelectors.getOrganisationName)

	const handleCloseMenu = () => {
		dispatch(configActions.toggleMobileMenu(false))
	}

	const handleMenuItemSelect = (item: 'my-tasks' | 'new-project' | 'logout') => {
		dispatch(configActions.toggleMobileMenu(false))

		if (item === 'new-project') {
			dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Project))
		} else if (item === 'logout') {
			dispatch(authActions.logoutUser())
		}
	}

	return (
		<Drawer
			anchor="right"
			open={isMenuOpen}
			onClose={handleCloseMenu}
			PaperProps={{ sx: { bgcolor: colors.feldgrau['100'] } }}
		>
			<Box role="presentation" width="250px" bgcolor={colors.feldgrau['100']}>
				<Box display="flex" alignItems="center" ml={2} my={1}>
					<Box mr={1}>
						<CangoLogo />
					</Box>
					<Box>
						<CangoLogoText width={100} />
					</Box>
				</Box>
				<Text fontSize={14} lineHeight={1} color="#fff" ml={2}>
					{selectedOrganisationName}
				</Text>
				<Divider color={colors.neutral['40']} sx={{ mt: 2 }} />
				<List>
					<ListItemButton onClick={() => handleMenuItemSelect('my-tasks')}>
						<ListItemIcon>
							<MyTasksIcon width={16} />
						</ListItemIcon>
						<ListItemText primary="My tasks" primaryTypographyProps={{ color: '#fff' }} />
					</ListItemButton>
					<ListItemButton onClick={() => handleMenuItemSelect('new-project')}>
						<ListItemIcon>
							<ProjectsIcon width={18} />
						</ListItemIcon>
						<ListItemText primary="New project" primaryTypographyProps={{ color: '#fff' }} />
					</ListItemButton>
					<ListItemButton onClick={() => handleMenuItemSelect('logout')}>
						<ListItemIcon>
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" primaryTypographyProps={{ color: '#fff' }} />
					</ListItemButton>
				</List>
			</Box>
		</Drawer>
	)
}

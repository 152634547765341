import { ComponentType } from 'react'

import { Text } from 'src/components'

type NoteProps = {
	note?: string
}

export const Note: ComponentType<NoteProps> = (props) => {
	if (!props.note) {
		return null
	}
	return <Text>{props.note}</Text>
}

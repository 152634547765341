import React, { ComponentType, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { useSnackbar } from 'notistack'

import theme from 'src/theme'

import Routing from './routing'
import { selectors as userSelectors, actions as userActions } from './store/modules/user'
import { selectors as authSelectors } from './store/modules/auth'
import {
	actions as remoteConfigActions,
	selectors as remoteConfigSelectors,
} from './store/modules/remote-config'
import { AsyncDispatchType } from './store/types'
import './assets/css/App.scss'
import './assets/css/Mobile.css'
import { setSnackbarEnqueue, showSnackbar } from './helpers/snackbarManager'
import { Box, FullScreenLoader, Text } from './components'
import { StagingAlert } from './routing/navigation/staging-alert'

// axios defaults
axios.defaults.baseURL = import.meta.env.VITE_API
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

const ACCEPTED_COUNTRY_CODES = (import.meta.env.VITE_ACCEPTED_COUNTRIES as string | undefined)
	? import.meta.env.VITE_ACCEPTED_COUNTRIES.split(', ')
	: []

enum AppAvailableStatus {
	PENDING = 'PENDING',
	AVAILABLE = 'AVAILABLE',
	UNAVAILABLE = 'UNAVAILABLE',
}

export const App: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { enqueueSnackbar } = useSnackbar()
	const userJwt = useSelector(authSelectors.getJwt)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const userSession = useSelector(authSelectors.getSession)
	const isLoadingConfig = useSelector(remoteConfigSelectors.isRemoteConfigLoading)
	const [appAvailability, setAppAvailability] = React.useState<AppAvailableStatus>(
		AppAvailableStatus.PENDING,
	)

	const handleInitApp = async () => {
		if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
			setAppAvailability(AppAvailableStatus.AVAILABLE)
			dispatch(remoteConfigActions.fetchConfig())
			return
		}

		try {
			const response = await axios.get(`https://ipinfo.io`, {
				params: {
					token: import.meta.env.VITE_IP_INFO_KEY,
				},
			})

			const countryCode = response.data.country

			if (!ACCEPTED_COUNTRY_CODES.includes(countryCode)) {
				setAppAvailability(AppAvailableStatus.UNAVAILABLE)
				return
			}

			dispatch(remoteConfigActions.fetchConfig())
			setAppAvailability(AppAvailableStatus.AVAILABLE)
		} catch (error) {
			showSnackbar('Cannot determine location', { variant: 'error' })
			Sentry.captureException(error, {
				extra: {
					message: 'Cannot determine user ip location',
				},
			})
			setAppAvailability(AppAvailableStatus.AVAILABLE)
		}
	}

	useEffect(() => {
		if (userJwt && !userId && userSession && appAvailability === AppAvailableStatus.AVAILABLE) {
			Sentry.setUser({
				id: userSession._id,
				username: userSession.name,
				email: userSession.email,
				segment: userSession.organisationId,
			})
			dispatch(userActions.setUser(userSession))
		}
	}, [userJwt, userId, userSession, appAvailability])

	useEffect(() => {
		if (enqueueSnackbar && appAvailability === AppAvailableStatus.AVAILABLE) {
			setSnackbarEnqueue(enqueueSnackbar)
		}
	}, [enqueueSnackbar, appAvailability])

	useEffect(() => {
		handleInitApp()
	}, [])

	return (
		<>
			<StagingAlert />
			<ThemeProvider theme={theme}>
				<FullScreenLoader
					isOpen={
						appAvailability !== AppAvailableStatus.UNAVAILABLE &&
						(isLoadingConfig || appAvailability === AppAvailableStatus.PENDING)
					}
				/>
				{appAvailability === AppAvailableStatus.UNAVAILABLE && (
					<Box
						flex={1}
						textAlign="center"
						display="flex"
						alignItems="center"
						flexDirection="column"
						justifyContent="center"
					>
						<Text>{"We're sorry!"}</Text>
						<Text>Cango is not yet available in your country</Text>
					</Box>
				)}
				{!isLoadingConfig && appAvailability === AppAvailableStatus.AVAILABLE && (
					<BrowserRouter>
						<Routing />
					</BrowserRouter>
				)}
			</ThemeProvider>
		</>
	)
}

import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk'
import PulseLoader from 'react-spinners/PulseLoader'
import Timeline from '@mui/lab/Timeline'
import timelineOppositeContentClasses from '@mui/lab/TimelineOppositeContent/timelineOppositeContentClasses'
import { V3ClientTypes } from '@cango-app/types'
import _difference from 'lodash/difference'
import _isArray from 'lodash/isArray'
import Stack from '@mui/material/Stack'

import { Box, Button, Select, Text } from 'src/components'
import { TaskContext } from 'src/providers/task-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { colors } from 'src/theme/colors'

import { useListOptions } from '../../../../../../hooks/use-list-options'
import { ChainList } from '../../../../../../components/section-tasks-v3/chains-list'

import { SubmitButtons } from './submit-buttons'
import { TimelineItem } from './timeline-item'

const initialState: V3ProjectSdk.TaskResetUpdates = {
	optionsAdded: [],
	optionsRemoved: [],
	tasksToDelete: [],
}

const getAllRemovedTasks = (
	removedOptions: string[],
	descendants: V3ProjectSdk.HierarchyItem[],
): string[] => {
	const removedTaskIdSet = new Set<string>()
	descendants.forEach((_desc) => {
		if (_desc.chain?._id && removedOptions.includes(_desc.chain._id)) {
			removedTaskIdSet.add(_desc._id)
			return
		}
		if (_desc.chain?.parent_chain_tasks.length) {
			_desc.chain.parent_chain_tasks.forEach((_parent) => {
				if (_parent.chain?._id && removedOptions.includes(_parent.chain._id)) {
					removedTaskIdSet.add(_desc._id)
				}
			})
		}
	})

	return [...removedTaskIdSet]
}

const TaskHierarchyComponent: ComponentType<{
	_task?: V3ClientTypes.Project.Task
	onResetTaskComplete: (
		data: V3ProjectSdk.ResetTaskResponse & { updates: V3ProjectSdk.ResetTaskRequest },
	) => void
}> = ({ _task, onResetTaskComplete }) => {
	const { task: taskContext, onCloseDrawer, updateTask } = useContext(TaskContext)
	const task = _task ?? taskContext
	const [isLoading, setIsLoading] = useState(false)
	const [isResettingSelf, setIsResettingSelf] = useState(false)
	const [hierarchy, setHierarchy] = useState<V3ProjectSdk.GetTaskHierarchyResponse>({
		ancestors: [],
		descendants: [],
	})
	const [updates, setUpdates] = useState<V3ProjectSdk.TaskResetUpdates>(initialState)
	const listOptions = useListOptions(task?.step?.options ?? [])

	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [selectedChildren, setSelectedChildren] = useState<string[]>(
		task?.isMultiUse
			? (task.instances.map((_instance) => _instance._id) ?? [])
			: (task?.lifecycle.completed_options.map((_option) => _option._id) ?? []),
	)

	const selectOptions = useMemo(() => {
		if (!task) return []

		if (task?.isMultiUse) {
			return (
				task?.instances.map((_instance) => ({
					_id: _instance._id,
					label: _instance.name,
				})) ?? []
			)
		}

		return listOptions
	}, [task?.isMultiUse, listOptions])

	const mappedSelectOptions = useMemo(() => {
		return new Map(selectOptions.map((_option) => [_option._id, _option]))
	}, [selectOptions])

	const fetchHierarchy = useCallback(async () => {
		if (!task) return
		try {
			setIsLoading(true)
			const response = await V3ProjectSdk.getTaskHierarchy(
				import.meta.env.VITE_API as string,
				authHeaders,
				task._id,
			)
			setHierarchy(response)
		} catch (error) {
			showSnackbar('Failed to fetch hierarchy', { variant: 'error' })
		} finally {
			setIsLoading(false)
		}
	}, [task?._id])

	const handleResetTask = useCallback(
		async (updates: V3ProjectSdk.TaskResetUpdates) => {
			if (!task) return

			try {
				const response = await V3ProjectSdk.resetTask(
					import.meta.env.VITE_API as string,
					authHeaders,
					task.project_id,
					task._id,
					updates,
				)

				onResetTaskComplete({ ...response, updates })

				if (onCloseDrawer) {
					onCloseDrawer()
					return
				}
				fetchHierarchy()
				setUpdates(initialState)
			} catch (error) {
				showSnackbar('Failed to reset task', { variant: 'error' })
			}
		},
		[task?._id],
	)

	const handleClearAllDescendants = useCallback(
		(includeSelf?: boolean) => {
			setUpdates({
				optionsAdded: [],
				optionsRemoved: task?.isMultiUse
					? task.instances.map((_instance) => _instance._id)
					: (task?.lifecycle.completed_options.map((_option) => _option._id) ?? []),
				tasksToDelete: [
					...(includeSelf && task ? [task._id] : []),
					...hierarchy.descendants.map((item) => item._id),
				],
			})
			setSelectedChildren([])
		},
		[hierarchy.descendants],
	)

	const handleResetSelf = useCallback(async () => {
		if (!task || !task.lifecycle.complete) return
		setIsResettingSelf(true)
		await updateTask(
			'lifecycle',
			{
				...task.lifecycle,
				complete: false,
				completed_at: [],
			},
			{ updateDb: true },
		)

		setIsResettingSelf(false)

		if (onCloseDrawer) {
			onCloseDrawer()
		}
	}, [task])

	const handleCancelDeletion = useCallback(() => {
		setUpdates(initialState)
		setSelectedChildren(
			task?.isMultiUse
				? (task.instances.map((_instance) => _instance._id) ?? [])
				: (task?.lifecycle.completed_options.map((_option) => _option._id) ?? []),
		)
	}, [task])

	const handleChangeSelection = (newChildren: string[]) => {
		if (task?.isMultiUse) {
			const chainInstanceIds = task?.instances.map((_instance) => _instance._id) ?? []
			const removedOptions = _difference(chainInstanceIds, newChildren)

			const directChildrenOfRemovedInstances = getAllRemovedTasks(
				removedOptions,
				hierarchy.descendants,
			)

			setUpdates({
				optionsAdded: [],
				optionsRemoved: removedOptions,
				tasksToDelete: directChildrenOfRemovedInstances,
			})
			setSelectedChildren(newChildren)
			return
		}

		const removedOptions = _difference(
			task?.lifecycle.completed_options.map((_option) => _option._id) ?? [],
			newChildren,
		)

		const addedOptions = _difference(
			newChildren.reduce((_acc: { _id: string; label: string }[], _newOption) => {
				const _option = mappedSelectOptions.get(_newOption)
				if (
					!task?.lifecycle.completed_options.some(
						(_prevOption) => _prevOption._id === _newOption,
					) &&
					_option
				) {
					_acc.push(_option)
				}
				return _acc
			}, []),
			task?.lifecycle.completed_options ?? [],
		)

		const matchingDescendantsWithParent = hierarchy.descendants.filter((_desc) => {
			if (
				_desc.chain?.option_id &&
				removedOptions.some((_removed) => _removed === _desc.chain?.option_id)
			) {
				return true
			}
			return _desc.chain?.parent_chain_tasks.some((_parent) =>
				removedOptions.some((_removed) => _removed === _parent.chain?.option_id),
			)
		})

		const directChildrenOfRemovedOptions = matchingDescendantsWithParent.map(
			(descendant) => descendant._id,
		)

		setUpdates({
			optionsAdded: addedOptions,
			optionsRemoved: removedOptions,
			tasksToDelete: directChildrenOfRemovedOptions,
		})
		setSelectedChildren(newChildren)
	}

	useEffect(() => {
		fetchHierarchy()
	}, [])

	return (
		<Box>
			{isLoading && (
				<Box display="flex" justifyContent="center">
					<PulseLoader size={8} />
				</Box>
			)}

			{!isLoading && (
				<Timeline
					sx={{
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 0.4,
						},
					}}
				>
					{hierarchy.ancestors.map((item) => (
						<TimelineItem key={item._id} hierarchyItem={item} />
					))}
					{(!!hierarchy.ancestors.length || !!hierarchy.descendants.length) && !!task && (
						<Box position="relative">
							<Box
								sx={{
									backgroundColor: updates.tasksToDelete.includes(task._id)
										? colors.error.light[20]
										: 'rgba(164, 198, 188, 0.1)',
									borderRadius: '8px',
									position: 'absolute',
									width: '100%',
									height: '100%',
									mt: 0.5,
								}}
							/>
							<TimelineItem
								hierarchyItem={{ ...task, depth: 0, chain: task.chain }}
								hideConnector={!hierarchy.descendants.length}
								customContent={
									<>
										{!task.isMultiUse &&
											!!updates.tasksToDelete.length &&
											!updates.tasksToDelete.includes(task._id) && (
												<Text fontSize={12}>
													This task will <span style={{ textDecoration: 'underline' }}>not</span> be
													deleted.
													{!selectOptions.length
														? ' It will be made incomplete.'
														: ' Any new selections will be created. If all selections are cleared the task will be marked as incomplete.'}
												</Text>
											)}
										{!task.isMultiUse &&
											!selectOptions.length &&
											!updates.tasksToDelete.length &&
											!!hierarchy.descendants.length &&
											task.lifecycle.complete && (
												<Stack direction="row">
													<Button
														variant="outlined"
														color="warning"
														onClick={() => handleClearAllDescendants(false)}
														size="small"
														sx={{ minWidth: 100, mr: 1 }}
													>
														Reset task
													</Button>
													<Button
														variant="outlined"
														color="error"
														onClick={() => handleClearAllDescendants(true)}
														size="small"
														sx={{ minWidth: 100 }}
													>
														Delete task
													</Button>
												</Stack>
											)}
										{!hierarchy.descendants.length &&
											// !selectOptions.length &&
											task.lifecycle.complete && (
												<Button
													variant="outlined"
													color="warning"
													onClick={handleResetSelf}
													isLoading={isResettingSelf}
													size="small"
												>
													Reset task
												</Button>
											)}
										{!!selectOptions.length && (
											<Box>
												<Select
													options={selectOptions}
													multiple={task.isMenu || task.isMultiUse}
													onChange={(e) =>
														handleChangeSelection(
															_isArray(e.target.value)
																? (e.target.value as string[])
																: [e.target.value as string],
														)
													}
													value={selectedChildren}
													size="small"
													disabled={!task.lifecycle.complete && !task.isMultiUse}
													fullWidth
													containerProps={{ maxWidth: 250 }}
												/>
												<Stack direction="row">
													<Button
														variant="text"
														size="small"
														color="warning"
														onClick={() => handleClearAllDescendants(false)}
													>
														Clear all selections
													</Button>
													<Button
														variant="outlined"
														color="error"
														onClick={() => handleClearAllDescendants(true)}
														size="small"
														sx={{ minWidth: 100 }}
													>
														Delete task
													</Button>
												</Stack>
											</Box>
										)}
									</>
								}
							/>
						</Box>
					)}

					{!hierarchy.descendants.length && !hierarchy.ancestors.length && !!task && (
						<TimelineItem
							hierarchyItem={{ ...task, depth: 0, chain: task.chain }}
							hideConnector={!hierarchy.descendants.length}
							customContent={
								<>
									{!task.isMultiUse && !!updates.tasksToDelete.length && (
										<Text fontSize={12}>
											This task will <span style={{ textDecoration: 'underline' }}>not</span> be
											deleted.
											{!selectOptions.length
												? ' It will be made incomplete.'
												: ' Any new selections will be created. If all selections are cleared the task will be marked as incomplete.'}
										</Text>
									)}
									{!hierarchy.descendants.length &&
										// !selectOptions.length &&
										task.lifecycle.complete && (
											<Button
												variant="outlined"
												color="warning"
												onClick={handleResetSelf}
												isLoading={isResettingSelf}
												size="small"
											>
												Reset task
											</Button>
										)}
								</>
							}
						/>
					)}

					{hierarchy.descendants.map((item, index) => {
						return (
							<Box position="relative" key={item._id}>
								<Box
									sx={{
										backgroundColor: updates.tasksToDelete.includes(item._id)
											? colors.error.light[20]
											: undefined,
										borderRadius: '8px',
										position: 'absolute',
										width: '100%',
										height: '100%',
										mt: 0.5,
									}}
								/>
								<TimelineItem
									key={item._id}
									hierarchyItem={item}
									hideConnector={hierarchy.descendants.length - 1 === index}
									customContent={
										<>
											<ChainList chain={item.chain} />
											{updates.tasksToDelete.includes(item._id) && (
												<Text color="error" fontSize={12} fontWeight="bold">
													Will be deleted
												</Text>
											)}
										</>
									}
								/>
							</Box>
						)
					})}
				</Timeline>
			)}
			{!isLoading && (
				<SubmitButtons
					onResetTask={handleResetTask}
					updates={updates}
					onCancelClick={handleCancelDeletion}
				/>
			)}
		</Box>
	)
}
export default TaskHierarchyComponent

import { ChainsSdk } from '@cango-app/sdk'
import { ComponentType, useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import { Box, Checkbox, Select } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { StepFormType } from './step-form-container'

export const ChainReferenceForm: ComponentType<{
	onDeselectChainReference: () => void
}> = ({ onDeselectChainReference }) => {
	const { control, setValue, watch } = useFormContext<StepFormType>()
	const [chains, setChains] = useState<{ _id: string; label: string }[]>([])
	const [isLoadingChains, setIsLoadingChains] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const currenctChainId = watch('chain_id')

	const fetchChains = useCallback(async () => {
		setIsLoadingChains(true)
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'false',
			})
			const chainOptions = response.reduce((_acc: { _id: string; label: string }[], chain) => {
				if (chain._id === currenctChainId) return _acc
				return [..._acc, { _id: chain._id, label: chain.name }]
			}, [])
			setChains(chainOptions)
		} catch (error) {
			showSnackbar('Error fetching chains', { variant: 'error' })
			Sentry.captureException(error)
		} finally {
			setIsLoadingChains(false)
		}
	}, [])

	const handleUncheckChainsReference = () => {
		setValue('chain_reference', '', { shouldDirty: true })
		onDeselectChainReference()
	}

	useEffect(() => {
		fetchChains()
	}, [])

	return (
		<Box>
			<Checkbox
				label={'This references another chain'}
				checked
				onChange={handleUncheckChainsReference}
			/>
			<Controller
				control={control}
				name="chain_reference"
				render={({ field: { value, onChange } }) => (
					<Select
						label="Which chain should start here?"
						options={chains}
						value={value}
						onChange={onChange}
						isLoading={isLoadingChains}
					/>
				)}
			/>
		</Box>
	)
}

import { useContext } from 'react'
import { PulseLoader } from 'react-spinners'

import { Box, Text } from 'src/components'
import { TemplatesContext } from 'src/providers/templates-provider'

import { TemplatesEditor } from './templates-editor'

export const TemplatesEditorContainer = () => {
	const { selectedTemplate, templates, isLoadingTemplates } = useContext(TemplatesContext)

	if (isLoadingTemplates && !selectedTemplate) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" flex={1}>
				<PulseLoader size={6} />
			</Box>
		)
	}

	if (!selectedTemplate) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" mt={10} flex={1}>
				<Text variant="h4" color={'grey'}>
					{templates.length > 0 ? 'Select a template' : 'Create a template to get started'}
				</Text>
			</Box>
		)
	}
	return <TemplatesEditor key={selectedTemplate._id} />
}

import React, { ComponentType, useCallback } from 'react'
import capitalize from 'lodash/capitalize'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
	DataGridPremium,
	GridCellParams,
	GridColDef,
	GridRenderCellParams,
} from '@mui/x-data-grid-premium'
import { useEffectOnce } from 'usehooks-ts'
import { useDispatch, useSelector } from 'react-redux'

import { dataGridStandard } from 'src/helpers/ui'
import { relativeTimeLabel } from 'src/helpers/labels'
import { FileDownloader } from 'src/components/utils/FileDownloader'
import {
	ProjectFilesState,
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { Box, DriveUploadButton } from 'src/components'
import { AnalyticsEvent, analytics } from 'src/biz'

dayjs.extend(relativeTime)

enum FileTableColumns {
	type = 'type',
	name = 'name',
	updated = 'updated',
	download = ' ',
}

type FileTableProps = {
	projectId: string
	driveFolderId: string
}

type FileRowType = {
	id: string
	name: string
	type: string
	updated?: string
	webViewLink: string
	downloadLink?: string
}

export const FileTable: ComponentType<FileTableProps> = ({ projectId, driveFolderId }) => {
	const dispatch = useDispatch()
	const files = useSelector(projectSelectors.getProjectFiles)
	const filesState = useSelector(projectSelectors.getProjectFilesState)

	const rows = files.map(
		(file): FileRowType => ({
			id: file.id,
			name: file.name,
			type: file.iconLink,
			updated: file.modifiedTime,
			webViewLink: file.webViewLink,
			downloadLink: file.webContentLink,
		}),
	)

	const handleCellClick = (file: GridCellParams<FileRowType>['row'], column: FileTableColumns) => {
		if (column === FileTableColumns.download) return
		analytics.track({
			eventName: AnalyticsEvent.fileRowClicked,
			properties: {
				fileType: file.type,
				column: column,
			},
		})
		window.open(file.webViewLink, '_blank')
	}

	const fetchFiles = useCallback(() => {
		dispatch(projectActions.fetchProjectFiles({ parentFolderId: driveFolderId }))
	}, [dispatch, projectId])

	const columns: GridColDef[] = Object.values(FileTableColumns).map((columnId) => ({
		field: columnId,
		headerName: capitalize(columnId),
		flex: 1,
		...(columnId === FileTableColumns.type
			? {
					renderCell: (params) =>
						params.row.type ? <img src={params.row.type} width="15px" height="15px" /> : null,
					maxWidth: 100,
				}
			: {}),
		...(columnId === FileTableColumns.download
			? {
					renderCell: (params: GridRenderCellParams<FileRowType>) =>
						params.row.downloadLink ? (
							<FileDownloader downloadLink={params.row.downloadLink} fileType={params.row.type} />
						) : null,
					maxWidth: 100,
				}
			: {}),

		...(columnId === FileTableColumns.updated
			? {
					type: 'date',
					valueGetter: (value) => value && dayjs(value).toDate(),
					valueFormatter: (value): string | undefined => {
						if (!value) return
						const today = dayjs()
						const yesterday = today.clone().subtract(1, 'day')

						if (dayjs(value).isAfter(yesterday)) {
							return relativeTimeLabel(dayjs(value).unix()) || ''
						}
						return dayjs(value).format('MMM D YYYY HH:mm')
					},
				}
			: {}),
	}))

	const getNoRowsLabel = () => {
		if (filesState === ProjectFilesState.Loading) {
			return 'Loading...'
		}
		return 'No files found for this project'
	}

	useEffectOnce(() => {
		fetchFiles()

		return () => {
			dispatch(projectActions.resetFiles())
		}
	})

	return (
		<Box px={4} pt={2}>
			<Box display="flex" justifyContent="flex-end" mb={3}>
				<DriveUploadButton
					label="Upload"
					selectedFilesIds={[]}
					onlyUpload
					onFileIdsChange={fetchFiles}
				/>
			</Box>
			<DataGridPremium
				loading={filesState === ProjectFilesState.Loading}
				autoHeight
				rows={rows}
				columns={columns}
				getRowId={(row) => row.id}
				getRowHeight={() => 'auto'}
				onCellClick={(cell: GridCellParams<FileRowType>) =>
					handleCellClick(cell.row, cell.field as FileTableColumns)
				}
				hideFooter
				disableColumnMenu
				sx={{ ...dataGridStandard, width: '100%' }}
				sortingOrder={['desc', 'asc']}
				localeText={{
					noRowsLabel: getNoRowsLabel(),
				}}
			/>
		</Box>
	)
}

import React, { ComponentType, useEffect } from 'react'
import { Routes, useLocation, Route, Navigate } from 'react-router-dom'
import { CommsSdk } from '@cango-app/sdk'

import { RouteId } from 'src/constants/routes'
import { ErrorBoundary } from 'src/components'
import { analytics } from 'src/biz'
import { TablesContainer } from 'src/modules/tables'
import MyTasksV3 from 'src/modules/my-tasks-v3'
import BlueprintDetailV3 from 'src/modules/blueprints-v3/blueprint-detail'
import BlueprintMenuV3 from 'src/modules/blueprints-v3/blueprint-menu'
import Contacts from 'src/modules/contacts'
import Roles from 'src/modules/roles'
import Inbox from 'src/modules/inbox'
import Users from 'src/modules/users'
import Help from 'src/modules/help'
import Chains from 'src/modules/chains'
import ProjectMenuV3 from 'src/modules/projects-v3/project-menu'
import ProjectDetailV3 from 'src/modules/projects-v3/project-detail'
import PrivacyPolicy from 'src/modules/legal'
import Login from 'src/modules/authentication'
import { CreateOrganisation } from 'src/modules/organisation'
import { TemplatesContainer } from 'src/modules/templates-editor'
import { PermissionsContainer } from 'src/modules/permissions/permissions-container'

import { Dashboard } from './dashboard'
import { AuthRoute } from './auth-route'
import { PermissionRoute } from './permission-route'

const RouterContainer: ComponentType = () => {
	const location = useLocation()

	useEffect(() => {
		const pagePath = location.pathname + location.search
		analytics.pageView(pagePath)
	}, [location])

	return (
		<ErrorBoundary>
			<Routes>
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route
					path="/login"
					element={
						<AuthRoute>
							<Login />
						</AuthRoute>
					}
				/>
				<Route
					path={RouteId.CreateOrganisation}
					element={
						<AuthRoute>
							<CreateOrganisation />
						</AuthRoute>
					}
				/>
				<Route path="/" element={<Dashboard />}>
					<Route
						path={`${RouteId.MyTasks}/:selectedProjectId?/:selectedSectionId?/:selectedTaskId?`}
						element={<MyTasksV3 />}
						index
					/>
					<Route
						path={RouteId.Blueprints}
						element={
							<PermissionRoute routeName="blueprint">
								<BlueprintMenuV3 />
							</PermissionRoute>
						}
					/>
					<Route
						path={`${RouteId.Blueprint}/:blueprintId`}
						element={
							<PermissionRoute routeName="blueprint">
								<BlueprintDetailV3 />
							</PermissionRoute>
						}
					/>
					<Route
						path={RouteId.Projects}
						element={
							<PermissionRoute routeName="projects">
								<ProjectMenuV3 />
							</PermissionRoute>
						}
					/>
					<Route
						path={`${RouteId.Project}/:projectId/:selectedTaskId?`}
						element={
							<PermissionRoute routeName="projects">
								<ProjectDetailV3 />
							</PermissionRoute>
						}
					/>
					<Route path={`${RouteId.Inbox}/:navState/:selectedTaskId?`} element={<Inbox />} />
					<Route
						path={RouteId.Inbox}
						element={<Navigate to={`/${RouteId.Inbox}/${CommsSdk.InboxNavState.MyMessages}`} />}
					/>
					<Route
						path={RouteId.Contacts}
						element={
							<PermissionRoute routeName="contacts">
								<Contacts />
							</PermissionRoute>
						}
					/>
					<Route
						path={RouteId.Permissions}
						element={
							<PermissionRoute routeName="permissions">
								<PermissionsContainer />
							</PermissionRoute>
						}
					/>
					<Route path={RouteId.Roles} element={<Roles />} />
					<Route
						path={RouteId.Users}
						element={
							<PermissionRoute routeName="users">
								<Users />
							</PermissionRoute>
						}
					/>
					<Route path={RouteId.Help} element={<Help />} />
					<Route
						path={`${RouteId.Tables}/:tableId?`}
						element={
							<PermissionRoute routeName="databases">
								<TablesContainer />
							</PermissionRoute>
						}
					/>
					<Route
						path={`${RouteId.Chains}/:chainId?`}
						element={
							<PermissionRoute routeName="chains">
								<Chains />
							</PermissionRoute>
						}
					/>
					<Route path={RouteId.Templates} element={<TemplatesContainer />} />
					<Route path="/" element={<Navigate to={`/${RouteId.MyTasks}`} />} />
					<Route path="*" element={<Navigate to={`/${RouteId.MyTasks}`} />} />
				</Route>
				<Route path="*" element={<Navigate to={`/${RouteId.MyTasks}`} />} />
			</Routes>
		</ErrorBoundary>
	)
}

export default RouterContainer

import { Stack } from '@mui/material'
import { PulseLoader } from 'react-spinners'
import AddIcon from '@mui/icons-material/Add'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { PermissionsSDK } from '@cango-app/sdk'
import sortBy from 'lodash/sortBy'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { usePermissions } from 'src/hooks/usePermissions'
import { Box, Button, Text } from 'src/components'

import { PermissionsManager } from './permissions-manager'

export const PermissionsContainer = () => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const { setPermissions, isLoadingPermissions, permissions } = usePermissions()

	const updatePermission = useCallback(
		async ({ _id, routes }: { _id: string; routes: string[] }) => {
			try {
				const response = await PermissionsSDK.updatePermission(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						_id,
						routes,
					},
				)
				setPermissions((currentPermissionList) => {
					if (!currentPermissionList) return currentPermissionList
					return currentPermissionList.map((currentPermission) => {
						if (currentPermission._id === response._id) return response
						return currentPermission
					})
				})
				showSnackbar('Permission successfully updated!', { variant: 'success' })
			} catch (err) {
				showSnackbar('Error updating permission', { variant: 'error' })
			}
		},
		[authHeaders],
	)

	const createPermission = useCallback(async () => {
		try {
			const response = await PermissionsSDK.createPermission(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					routes: [],
				},
			)
			setPermissions((currentPermissions) => {
				if (!currentPermissions) return currentPermissions
				return sortBy([...currentPermissions, response], 'name')
			})
		} catch (err) {
			showSnackbar('Error updating permission', { variant: 'error' })
		}
	}, [authHeaders, permissions])

	const deletePermission = useCallback(async (permissionId: string) => {
		try {
			await PermissionsSDK.deletePermission(
				import.meta.env.VITE_API as string,
				authHeaders,
				permissionId,
			)
			setPermissions((currentPermissionList) => {
				return currentPermissionList?.filter(({ _id }) => permissionId !== _id)
			})
		} catch (error) {
			showSnackbar('Error deleting permission', { variant: 'error' })
		}
	}, [])

	if (isLoadingPermissions || !permissions) {
		return (
			<Stack justifyContent="center" alignItems="center" height="100%">
				<PulseLoader size={8} />
			</Stack>
		)
	}

	if (!permissions.length) {
		return (
			<Stack justifyContent="center" alignItems="center" height="100%">
				<Text variant="h5">No Permissions</Text>
				<Button onClick={createPermission}>Create</Button>
			</Stack>
		)
	}
	return (
		<Stack flex={1} pt={4} px={4} direction="column" spacing={2}>
			<Text variant="h4">Permissions</Text>
			<Box alignSelf="flex-end">
				<Button onClick={createPermission} startIcon={<AddIcon />}>
					Add
				</Button>
			</Box>
			<PermissionsManager
				permissions={permissions}
				updatePermission={updatePermission}
				deletePermission={deletePermission}
			/>
		</Stack>
	)
}

import React, { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'

import { Box, Grid, Text } from 'src/components'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { actions as configActions } from 'src/store/modules/config'
import { AsyncDispatchType, RootState } from 'src/store/types'
import { colors } from 'src/theme/colors'
import { BackArrowOutlined, HamburgerIcon } from 'src/assets/icons'
import { useMyTasks } from 'src/hooks/useMyTasks'

import { ProjectsList } from './project-list/project-list'
import SectionContainer from './section'

export const MyTasksMobileContainer: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const navigate = useNavigate()
	const { selectedSectionId } = useMyTasks()
	const selectedSection = useSelector((state: RootState) =>
		projectSelectors.getProjectSection(state, selectedSectionId),
	)

	const handleBackClick = () => {
		navigate(-1)
	}

	const handleMenuPress = () => {
		dispatch(configActions.toggleMobileMenu(true))
	}

	return (
		<Box flex={1} overflow="hidden">
			<AppBar position="sticky" sx={{ top: 0, py: 1, bgcolor: colors.feldgrau['100'] }}>
				<Grid container>
					<Grid xs={2} display="flex" alignItems="center">
						{!!selectedSection && (
							<IconButton onClick={handleBackClick} sx={{ ml: 1 }}>
								<BackArrowOutlined fill="#fff" />
							</IconButton>
						)}
					</Grid>
					<Grid xs={8} display="flex" justifyContent="center" alignItems="center">
						<Text textAlign="center">{selectedSection ? selectedSection.name : 'Projects'}</Text>
					</Grid>
					<Grid xs={2} display="flex" alignItems="center" justifyContent="flex-end">
						<IconButton sx={{ mr: 1 }} onClick={handleMenuPress}>
							<HamburgerIcon />
						</IconButton>
					</Grid>
				</Grid>
			</AppBar>
			{selectedSectionId ? (
				<Box p={2} flex={1} display="flex">
					<Box display="flex" flexGrow={1}>
						<SectionContainer />
					</Box>
				</Box>
			) : (
				<Box p={2} flex={1} display="flex">
					<Box display="flex" flexGrow={1}>
						<ProjectsList />
					</Box>
				</Box>
			)}
		</Box>
	)
}
